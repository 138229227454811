export const wrapperStyle = {
  display: 'flex',
  flexDirection: 'row' as 'row',
  height: '100%',
};
export const chartTextStyle = {
  fontSize: '16px',
  display: 'inline-block',
  justifySelf: 'center',
  textAlign: 'center',
} as const;
export const chartWrapperStyle = {
  fontSize: '24px',
  display: 'inline-block',
  alignSelf: 'center',
  justifySelf: 'center',
};
export const numberWrapperStyle = {
  fontSize: '24px',
  display: 'flex',
  alignSelf: 'center',
  justifySelf: 'center',
  backgroundColor: '#F4F4F4',
  borderRadius: '50%',
  width: '60%',
  maxWidth: '192px',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  aspectRatio: '1',
} as const;
