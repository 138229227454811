import * as inspections from 'serviceWorker/db/inspections';
import * as inspectionsService from 'serviceWorker/db/inspectionsService';
import { awaitEntities } from '../factories/awaitEntities';
import { InspectionEntity } from 'serviceWorker/repository/inspection/entity';
import { waitForInspections } from './waitForInspections';

export async function getInspections(): Promise<InspectionEntity[]> {
  await awaitEntities(inspectionsService, waitForInspections);
  const result = await inspections.get();
  if (!result) {
    throw new Error(`Cannot find inspections`);
  }
  return result;
}
