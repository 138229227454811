import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { makeDefaultSync } from './apiFactories';
import { GetChartEventDetail } from './types';
import { InspectionChart, IssueChart } from 'shared/types/analytics';

const getOne = (data: {
  chart: IssueChart | InspectionChart;
  filters: any;
  locale: string;
  additionalParams?: any;
  forceUpdate?: boolean;
}): void => {
  self.dispatchEvent(
    new CustomEvent<GetChartEventDetail<IssueChart | InspectionChart>>(
      chooseChartMessageType(data.chart),
      {
        detail: {
          chart: data.chart,
          filters: data.filters,
          locale: data.locale,
          additionalParams: data.additionalParams,
          forceUpdate: data.forceUpdate,
        },
      }
    )
  );
};

const sync = makeDefaultSync(RepositoryMessagesTypes.getFinancialData);

export default { getOne, sync };

function chooseChartMessageType(
  chart: IssueChart | InspectionChart
):
  | RepositoryMessagesTypes.getChart
  | RepositoryMessagesTypes.getInspectionChart {
  return isIssueChart(chart)
    ? RepositoryMessagesTypes.getChart
    : RepositoryMessagesTypes.getInspectionChart;
}

function isIssueChart(
  chart: IssueChart | InspectionChart
): chart is IssueChart {
  return Object.values<IssueChart | InspectionChart>(IssueChart).includes(
    chart
  );
}
