import { useMediaQuery, useTheme } from '@mui/material';

export const MobileTreshold = 550;

const useShouldShowVertical = (): boolean => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(MobileTreshold));
};

export default useShouldShowVertical;
