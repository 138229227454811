import { BroadcastChannel } from 'broadcast-channel';
import { useChartFilters } from 'hooks/useChartFilters';
import { useMountedRef } from 'hooks/useMountRef';
import { useEffect, useMemo, useState } from 'react';
import { ChannelNames } from 'shared/domain/channelNames';
import { AnyFilters, FilterValues } from 'shared/types/analytics';

export function useFiltersPersistence<T extends AnyFilters>(
  filtersName: string,
  filters: T | undefined,
  projectId: string,
  defaultValues: () => FilterValues
): { savedFilters: FilterValues | undefined } {
  const mountedRef = useMountedRef();
  const { getFilters, saveFilters } = useChartFilters(filtersName);
  const [savedFilters, setSavedFilters] = useState<
    FilterValues | undefined
  >(undefined);

  useEffect(() => {
    const filtersBroadcast = new BroadcastChannel(
      ChannelNames.chartFiltersChannel
    );
    getFilters(filtersBroadcast)
      .then((res) => {
        if (!mountedRef.current) {
          return;
        }
        setSavedFilters(res?.filters || defaultValues());
      })
      .catch(() => {
        if (!mountedRef.current) {
          return;
        }
        setSavedFilters(defaultValues());
      });

    return (): void => {
      filtersBroadcast.close();
    };
  }, [getFilters]);

  useEffect(() => {
    if (filters && projectId) {
      saveFilters(filters);
    }
  }, [filters, projectId, saveFilters]);

  return useMemo(
    () => ({
      savedFilters,
    }),
    [savedFilters]
  );
}
