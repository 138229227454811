import { Box } from '@mui/material';
import { useSharedResourceDownload } from 'components/common/withSharedResourceDownload/withSharedResourceDownload';
import { Spinner } from 'components/core';
import InsufficientPermissionsPage from 'components/core/InsufficientPermissionsPage/InsufficientPermissionsPage';
import { useRedirect } from 'hooks/useRedirect';
import qs from 'query-string';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getErrorToasterAction } from 'redux/actions/toasterActions';
import {
  BAD_REQUEST,
  FORBIDDEN,
  NOT_FOUND,
  OK,
  REQUIRED_RESOURCE_NEEDS_AUTHORIZATION,
  TOKEN_EXPIRED,
  UNPROCESSABLE_ENTITY,
} from 'shared/contants';
import {
  DownloadingInfo,
  LinkExpiredInfo,
  NotFoundInfo,
} from 'views/reports/statusComponents';

const statusCodeComponents: Record<number, React.FC> = {
  [FORBIDDEN]: InsufficientPermissionsPage,
  [NOT_FOUND]: NotFoundInfo,
  [TOKEN_EXPIRED]: LinkExpiredInfo,
  [OK]: DownloadingInfo,
}; //TODO https://hustro.atlassian.net/browse/PT-3862

export function DownloadWithToken(): React.ReactElement {
  const {
    isDownloading,
    statusCode,
    startDownloadSharedResource: startDownloadingEntity,
  } = useSharedResourceDownload();

  const dispatch = useDispatch();
  const intl = useIntl();
  const redirect = useRedirect();

  const location = useLocation();
  const { reportId } = useParams<{ reportId: string | undefined }>();
  const { documentId } = useParams<{ documentId: string | undefined }>();
  const { issueId } = useParams<{ issueId: string | undefined }>();
  const { eventId } = useParams<{ eventId: string | undefined }>();
  const { token } = qs.parse(location.search);

  useEffect(() => {
    if ((reportId || documentId) && typeof token === 'string') {
      startDownloadingEntity({
        reportId,
        documentId,
        issueId,
        eventId,
        token,
      });
    }
  }, [
    startDownloadingEntity,
    reportId,
    documentId,
    issueId,
    eventId,
    token,
  ]);

  useEffect(() => {
    if (
      statusCode === undefined ||
      ![UNPROCESSABLE_ENTITY, BAD_REQUEST].includes(statusCode)
    ) {
      return;
    }

    dispatch(getErrorToasterAction(intl));
  }, [statusCode, dispatch, intl]);

  useEffect(() => {
    if (statusCode !== REQUIRED_RESOURCE_NEEDS_AUTHORIZATION) {
      return;
    }

    const redirectLink = `${location.pathname}${location.search}`;
    localStorage.setItem('redirect_link', redirectLink);
    redirect('/login');
  }, [statusCode, location.pathname, location.search, redirect]);

  if (isDownloading || !statusCode || !statusCodeComponents[statusCode]) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100%',
        }}
      >
        <Spinner
          position='static'
          reason={`EntityDownload isDownloading: ${isDownloading} || !statusCode: ${!statusCode} || !statusCodeComponents[statusCode]`}
        />
      </Box>
    );
  }

  const DisplayedComponent = statusCodeComponents[statusCode];

  return <DisplayedComponent />;
}
