import {
  DateRangeIntervalWithoutWeek,
  StrictDateRange,
} from 'shared/types/analytics';
import { Dispatch, useCallback, useMemo } from 'react';
import {
  FilterActionType,
  FilterActionTypeInterval,
  RewriteFiltersAction,
  RewriteFiltersActionEnum,
  SetDateIntervalAction,
  SetDateRangeAction,
  SetManagersAction,
  SetProcessesAction,
  SetSitesAction,
} from 'charts/shared/types';

type FilterActionCalls<T> = {
  rewriteFilters: (value: T) => void;
  setDateRange: (value: StrictDateRange) => void;
  setProcesses: (value: string[]) => void;
  setSites: (value: string[]) => void;
  setManagers: (value: string[]) => void;
  setDateInterval: (value: DateRangeIntervalWithoutWeek) => void;
};

type Actions<T> =
  | RewriteFiltersAction<T>
  | SetDateRangeAction
  | SetProcessesAction
  | SetSitesAction
  | SetManagersAction;

type ActionsWithInterval<T> =
  | RewriteFiltersAction<T>
  | SetDateRangeAction
  | SetDateIntervalAction
  | SetProcessesAction
  | SetSitesAction
  | SetManagersAction;

export function useFiltersActions<T>(
  dispatch: Dispatch<Actions<T>> | Dispatch<ActionsWithInterval<T>>
): FilterActionCalls<T> {
  const setDateRange = useCallback(
    (value: StrictDateRange) => {
      dispatch({ type: FilterActionType.setDateRange, payload: value });
    },
    [dispatch]
  );

  const setDateInterval = useCallback(
    (value: DateRangeIntervalWithoutWeek) => {
      (dispatch as Dispatch<ActionsWithInterval<T>>)({
        type: FilterActionTypeInterval.setDateInterval,
        payload: value,
      });
    },
    [dispatch]
  );

  const setProcesses = useCallback(
    (value: string[]) => {
      dispatch({ type: FilterActionType.setProcesses, payload: value });
    },
    [dispatch]
  );

  const setSites = useCallback(
    (value: string[]) => {
      dispatch({ type: FilterActionType.setSites, payload: value });
    },
    [dispatch]
  );

  const setManagers = useCallback(
    (value: string[]) => {
      dispatch({ type: FilterActionType.setManagers, payload: value });
    },
    [dispatch]
  );

  const rewriteFilters = useCallback(
    (value: T) => {
      dispatch({
        type: RewriteFiltersActionEnum.rewriteFilters,
        payload: value,
      });
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      setDateRange,
      setDateInterval,
      setProcesses,
      setSites,
      setManagers,
      rewriteFilters,
    }),
    []
  );
}
