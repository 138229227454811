import { awaitEntities } from '../factories/awaitEntities';
import * as inspectionTemplates from 'serviceWorker/db/inspectionTemplates';
import * as inspectionTemplatesService from 'serviceWorker/db/inspectionTemplatesService';
import { ChannelNames } from 'shared/domain/channelNames';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { waitForMessage } from '../factories/waitForMessage';
import { InspectionTemplateEntity } from 'serviceWorker/repository/inspectionTemplate/entity';

const waitForInspectionTemplates = waitForMessage(
  ChannelNames.inspectionTemplateChannel,
  DomainMessagesTypes.allInspectionTemplates
);

export async function getInspectionTemplates(): Promise<
  InspectionTemplateEntity[]
> {
  await awaitEntities(
    inspectionTemplatesService,
    waitForInspectionTemplates
  );
  const result = await inspectionTemplates.get();
  if (!result) {
    throw new Error(`Cannot find inspection templates`);
  }
  return result;
}
