import { ChartFilter, StrictDateRange } from 'shared/types/analytics';
import { setMultiselectFilterValue } from 'charts/shared/filters';
import {
  FilterActionType,
  RewriteFiltersActionEnum,
} from 'charts/shared/types';

type GenericInspectionFilter = {
  dataScope: {
    labelId: string;
    filters: ChartFilter<StrictDateRange | string[]>[];
  };
};

type BaseAction<T> =
  | {
      type: RewriteFiltersActionEnum.rewriteFilters;
      payload: T;
    }
  | {
      type: FilterActionType.setDateRange;
      payload: StrictDateRange;
    }
  | {
      type: FilterActionType;
      payload: string[];
    };

export function filtersReducer<
  T extends GenericInspectionFilter,
  TAction extends BaseAction<T>,
>(state: T | undefined, action: TAction): T | undefined {
  if (action.type === RewriteFiltersActionEnum.rewriteFilters)
    return action.payload;
  if (!state) return undefined;

  switch (action.type) {
    case FilterActionType.setDateRange: {
      const [first, ...rest] = state.dataScope.filters;
      return {
        ...state,
        dataScope: {
          ...state.dataScope,
          filters: [
            {
              ...first,
              value: action.payload,
            },
            ...rest,
          ],
        },
      };
    }

    case FilterActionType.setProcesses:
      return setMultiselectFilterValue(state, 1, action.payload);

    case FilterActionType.setSites:
      return setMultiselectFilterValue(state, 2, action.payload);

    case FilterActionType.setManagers:
      return setMultiselectFilterValue(state, 3, action.payload);

    default:
      const exhaustiveCheck: never = action;
      throw new Error(
        `Unhandled filters reducer case: ${exhaustiveCheck['type']}`
      );
  }
}
