import { CellRenderer } from 'components/table/types';
import { DateTime } from 'luxon';
import { ISO6391LanguageCode } from 'shared/types/locale';
import {
  toLocalizedDateDisplayStringFormat,
  toLocalizedDateDisplayStringFormatWithTime,
  toStandardDateDisplayStringFormat,
} from 'shared/utils/date/dates';

export const displayDate = (
  date: DateTime | null | undefined | string,
  timezone: string
): null | string => {
  if (!date) {
    return null;
  }

  return toStandardDateDisplayStringFormat(date, timezone);
};
export const displayLocalizedDate = (
  date: DateTime | null | undefined | string,
  timezone: string,
  locale: ISO6391LanguageCode
): null | string => {
  if (!date) {
    return null;
  }

  return toLocalizedDateDisplayStringFormat(date, timezone, locale);
};

export const displayDateWithTime = (
  date: DateTime | null | undefined | string,
  timezone: string,
  locale: ISO6391LanguageCode
): null | string => {
  if (!date) {
    return null;
  }

  return toLocalizedDateDisplayStringFormatWithTime(
    date,
    timezone,
    locale
  );
};

export function createCellModificationDate(
  timezone: string,
  locale: ISO6391LanguageCode
): CellRenderer<{ modifiedAt: DateTime }> {
  return ({ rowData }): string | null => {
    return displayDateWithTime(rowData.modifiedAt, timezone, locale);
  };
}

export function createCellCreationDate(
  timezone: string,
  locale: ISO6391LanguageCode
): CellRenderer<{ createdAt: DateTime }> {
  return ({ rowData }): string | null => {
    return displayDateWithTime(rowData.createdAt, timezone, locale);
  };
}

export function createCellExecutionDate(
  timezone: string,
  locale: ISO6391LanguageCode
): CellRenderer<{ inspectionDate: DateTime }> {
  return ({ rowData }): string | null => {
    return displayLocalizedDate(rowData.inspectionDate, timezone, locale);
  };
}
