import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { debugLog } from 'shared/logger/debugLog';

export function startGetDocumentSecuredByTokenSignedRequest(params: {
  id: string;
  token: string;
  issueId: string;
  eventId: string | undefined;
}): void {
  debugLog('startGetDocumentSecuredByTokenSignedRequest');
  const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.DOCUMENTS,
    method: ServiceMethods.GET_ONE,
    data: params,
  });
  broadcast.close();
}
