import { BroadcastChannel } from 'broadcast-channel';
import { useGenerateForms } from 'components/issue/useGenerateForms';
import { projectIdSelector } from 'helpers/misc';
import { Store, useCreateStore } from 'hooks/createStore';
import { useGetIssue } from 'hooks/useGetIssue';
import { useMountedRef } from 'hooks/useMountRef';
import { DocumentsOnIssueView } from 'presentation/document/documentsOnIssueView';
import { documentsToDocumentsOnIssueView } from 'presentation/document/documentsToDocumentsOnIssueView';
import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import { projectDataSelector } from 'redux/selectors/project';
import { StoreState } from 'setup/types/core';
import { ChannelNames } from 'shared/domain/channelNames';
import { IssueModel } from 'shared/domain/issue/issueModel';
import { issueModelToOnSingleView } from 'shared/domain/issue/mapping/toView';
import {
  DomainMessagesTypes,
  Message,
  ServiceMethods,
  Services,
} from 'shared/domain/messages/message';
import { debugLog } from 'shared/logger/debugLog';
import { HashMap } from 'shared/types/commonView';
import { useIssueChannelListener } from '../../broadcastChannelListeners/withIssueChannelListener';
import { useCompanies } from '../../dataProviders/withCompanies';
import { useContracts } from '../withContracts';
import { useDocuments, withDocuments } from '../withDocuments';
import { useLevels } from '../withLevels';
import { useSites } from '../withSites';
import { useUsers } from '../withUsers';
import { IssueOnSingleView } from './IssueOnSingleView';

type IssueContext = {
  issueStore: Store<IssueOnSingleView | undefined>;
  issueModelStore: Store<IssueModel | undefined>;
  documentsStore: Store<DocumentsOnIssueView>;
  loadingStore: Store<boolean>;
  setIssueId: (id: string) => void;
  resync: () => void;
  sendSynchronizationRequest: () => void;
  errorStore: Store<string | undefined>;
  getIssuePromise: () => Promise<IssueModel>;
};

const WithIssueContext = React.createContext<IssueContext | undefined>(
  undefined
);

const WithIssue: React.FC<
  PropsWithChildren<{
    issueId?: string;
  }>
> = withDocuments(({ children, issueId }) => {
  const mountedRef = useMountedRef();
  const dispatch = useDispatch();
  const { projectId } = useParams<{ projectId: string }>();
  const projectIdRef = useRef(projectId);
  const selectedProjectId = useSelector(projectIdSelector);

  const promiseRef = useRef<{
    resolve: (issue: IssueOnSingleView) => void;
    ready?: boolean;
  }>({
    resolve: (): Promise<void> => {
      return Promise.resolve();
    },
  });
  const { locale } = useIntl();
  const [issueIdState, setIssueIdState] = useState<string | undefined>(
    issueId
  );
  const issueStore = useCreateStore<IssueOnSingleView | undefined>(
    undefined
  );
  const loadingStore = useCreateStore(true);
  const { subscribe } = useIssueChannelListener();
  const { getIssue } = useGetIssue();
  const { documents, setQuery } = useDocuments();
  const issueModelStore = useCreateStore<IssueModel | undefined>(
    undefined
  );
  const lastResyncTimeRef = useRef<number>(0);

  const currentUserId = useSelector(
    (state: StoreState) => state.user.data._id
  );

  const issueDocumentsStore = useCreateStore<DocumentsOnIssueView>({
    issueDocuments: [],
    eventDocuments: {},
    incomingEmailMessageDocuments: {},
  });
  const errorStore = useCreateStore<string | undefined>(undefined);

  useEffect(() => {
    issueDocumentsStore.set(documentsToDocumentsOnIssueView(documents));
  }, [documents, issueDocumentsStore]);
  const {
    all: { items: users },
  } = useUsers();
  const {
    sites: { items: sites },
  } = useSites();
  const {
    levels: { items: levels },
  } = useLevels();
  const {
    contracts: { items: contractsArray },
  } = useContracts();
  const { companies } = useCompanies();

  const contracts = useMemo(() => {
    return contractsArray.reduce((result, contract) => {
      result[contract._id] = contract;
      return result;
    }, {} as HashMap<any>);
  }, [contractsArray]);

  const _companies = useMemo(() => {
    return companies.items.reduce((result, company) => {
      result[company._id] = company;
      return result;
    }, {} as HashMap<any>);
  }, [companies.items]);

  const { processes, timezone, organizationId } = useSelector(
    projectDataSelector
  );
  const { forms, loading: loadingForms } = useGenerateForms();

  useEffect(() => {
    if (!issueIdState) return;
    debugLog('issueIdState set, GET ISSUE!', issueIdState);
    loadingStore.set(true);
    errorStore.set(undefined);
    getIssue(issueIdState)
      .then((response) => {
        if (!mountedRef.current) {
          return;
        }
        issueModelStore.set(response);
      })
      .catch((e) => {
        if (
          !mountedRef.current ||
          selectedProjectId !== projectIdRef.current
        ) {
          return;
        }
        displayGenericErrorToaster(dispatch);
        errorStore.set('error');
      });
    setQuery({ issueId: issueIdState });
  }, [
    getIssue,
    setQuery,
    issueIdState,
    locale,
    dispatch,
    errorStore,
    issueModelStore,
    loadingStore,
    projectId,
    selectedProjectId,
    mountedRef,
  ]);

  const [issueModel, setIssueModel] = useState<IssueModel | undefined>(
    issueModelStore.get()
  );

  useEffect(() => {
    setIssueModel(issueModelStore.get());
    return issueModelStore.subscribe(() => {
      setIssueModel(issueModelStore.get());
    });
  }, [issueModelStore]);

  useEffect(() => {
    if (!issueModel || !mountedRef.current || loadingForms || !users) {
      return;
    }
    if (issueModel && issueModel._id === issueIdState) {
      const issue = issueModelToOnSingleView(
        issueModel,
        currentUserId,
        sites,
        levels,
        processes,
        forms,
        _companies,
        contracts,
        users,
        timezone,
        organizationId
      );
      if (promiseRef.current.ready) {
        promiseRef.current.resolve(issue);
        promiseRef.current.ready = false;
      }
      issueStore.set(issue);
      loadingStore.set(false);
    }
  }, [
    currentUserId,
    processes,
    promiseRef,
    sites,
    issueModel,
    issueIdState,
    levels,
    forms,
    _companies,
    contracts,
    loadingForms,
    users,
    issueStore,
    loadingStore,
    mountedRef,
  ]);

  const resync = useCallback(() => {
    if (!issueIdState) {
      return;
    }
    const now = Date.now();
    const THROTTLE_TIME = 2000;
    // resyncs all the time after edit issue event, need to fix it somehow in future
    if (now - lastResyncTimeRef.current < THROTTLE_TIME) {
      debugLog('Throttled resync');
      return;
    }
    lastResyncTimeRef.current = now;
    errorStore.set(undefined);
    getIssue(issueIdState)
      .then((response) => {
        if (!mountedRef.current) {
          return;
        }
        if (
          response._id == issueIdState &&
          response.modifiedAt !== issueModelStore.get()?.modifiedAt
        ) {
          issueModelStore.set(response);
          setQuery({ issueId: issueIdState });
        }
      })
      .catch((e) => {
        if (
          !mountedRef.current ||
          selectedProjectId !== projectIdRef.current
        ) {
          return;
        }
        displayGenericErrorToaster(dispatch);
        errorStore.set('error');
      });
  }, [
    issueIdState,
    errorStore,
    getIssue,
    setQuery,
    issueModelStore,
    selectedProjectId,
    dispatch,
  ]);

  useEffect(
    function dataListener() {
      const unsubscribe = subscribe((event: Message): void => {
        if (!mountedRef.current) {
          return;
        }

        if (event.type === DomainMessagesTypes.tableChanged) {
          resync();
          promiseRef.current.ready = true;
        }
      });

      return (): void => {
        unsubscribe();
      };
    },
    [mountedRef, resync, subscribe]
  );

  const getIssuePromise = useCallback(() => {
    return getIssue(issueIdState);
  }, [getIssue, issueIdState]);

  const sendSynchronizationRequest = useCallback(() => {
    const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
    broadcast.postMessage({
      service: Services.ISSUES,
      method: ServiceMethods.SYNC,
    });
    broadcast.close();
  }, []);

  const ctx: IssueContext = useMemo(() => {
    return {
      issueStore,
      issueModelStore,
      errorStore,
      documentsStore: issueDocumentsStore,
      loadingStore,
      setIssueId: setIssueIdState,
      resync,
      getIssuePromise,
      sendSynchronizationRequest,
    };
  }, [
    errorStore,
    getIssuePromise,
    issueDocumentsStore,
    issueModelStore,
    issueStore,
    loadingStore,
    resync,
    sendSynchronizationRequest,
  ]);

  return (
    <WithIssueContext.Provider value={ctx}>
      {children}
    </WithIssueContext.Provider>
  );
});

function useIssue(): IssueContext {
  const context = React.useContext(WithIssueContext);
  if (context === undefined) {
    throw new Error(
      'useIssue must be used within an IssueContextProvider'
    );
  }
  return context;
}

const withIssue =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithIssue>
      <Component {...props} />
    </WithIssue>
  );

export { useIssue, WithIssue, withIssue };
