import { fromDate14DaysEarlier, toDateToday } from 'charts/shared';
import { FilterValues } from 'shared/types/analytics';

export function defaultValues(): FilterValues {
  return {
    dataScope: {
      labelId: 'data_scope_tab',
      filters: [
        {
          labelId: 'data_scope_date_range',
          value: {
            from: fromDate14DaysEarlier,
            to: toDateToday,
          },
        },
        {
          labelId: 'general_process',
          value: [],
        },
        {
          labelId: 'data_scope_sites',
          value: [],
        },
        {
          labelId: 'inspection_table_author',
          value: [],
        },
      ],
    },
  };
}
