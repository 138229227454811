import { useRedirectContext } from 'components/common/withRedirect';
import { Spinner } from 'components/core';
import { useDialog } from 'components/core/Dialog/common/DialogContext';
import {
  useCompanies,
  withCompanies,
} from 'components/dataProviders/withCompanies';
import {
  useContracts,
  withContracts,
} from 'components/dataProviders/withContracts';
import { useInspection } from 'components/dataProviders/withInspection';
import { useRequiredInspectionTemplate } from 'components/dataProviders/withRequiredInspectionTemplate';
import { useSites, withSites } from 'components/dataProviders/withSites';
import {
  useWorktypes,
  withWorktypes,
} from 'components/dataProviders/withWorktypes';
import { filterFieldValuesByProcess } from 'presentation/fieldValue/filterFieldValues';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { projectDataSelector } from 'redux/selectors/project';
import { HashMap, LabelledEntity } from 'shared/types/commonView';
import { DayMomentValidation } from 'shared/types/time';
import {
  contractsExtractor,
  controlledPartiesExtractor,
  toLabelledEntity,
} from './model';
import { InspectionFormPresentational } from './presentational';
import {
  InspectionFieldName,
  InspectionFieldProps,
  InspectionFormProps,
} from './types';

import {
  useIssuesOnInspection,
  withIssuesOnInspection,
} from 'components/dataProviders/withIssuesOnInspection';
import { withLevels } from 'components/dataProviders/withLevels';
import {
  useLevelsToSelect,
  withLevelsToSelect,
} from 'components/dataProviders/withLevelsToSelect';
import { keepUndeleted } from 'shared/domain/deletable/filters';
import {
  EditableFieldValuesEntityName,
  EditableStandardEntityName,
} from 'shared/domain/fieldValue/fields';
import { WorktypeOnView } from 'presentation/fieldValue/fieldValueOnView';
import { levelsExtractor } from 'presentation/level/levelExtractor';
import { nowTimezonedDateTime } from 'shared/utils/date/dates';

function InspectionForm({
  loading,
  onSubmit,
  onCancel,
  isPosting,
}: InspectionFormProps): ReactElement {
  const createDialog = useDialog();
  const { items: workTypes } = useWorktypes();
  const { timezone } = useSelector(projectDataSelector);
  const { setCanRedirect } = useRedirectContext();
  const { template } = useRequiredInspectionTemplate();
  const processWorkTypes = filterFieldValuesByProcess(
    workTypes,
    template?.process
  );
  const { inspection } = useInspection();

  const { companies } = useCompanies();
  const { contracts } = useContracts();

  const { setInspection } = useIssuesOnInspection();
  useEffect(() => {
    if (inspection?._id) {
      setInspection(inspection._id);
    }
  }, [inspection, setInspection]);

  const {
    sites: { items: sites },
  } = useSites();
  const { levelsPerSite } = useLevelsToSelect();

  const companiesPerSite = useMemo(() => {
    if (!sites) return {};
    const companiesOnSite = sites.reduce<HashMap<LabelledEntity[]>>(
      (map, site) => {
        map[site._id] = [];
        return map;
      },
      {}
    );
    companies.items.forEach((company) => {
      company.sites.forEach((site) => {
        if (Array.isArray(companiesOnSite[site])) {
          companiesOnSite[site].push({
            _id: company._id,
            label: company.shortLabel,
          });
        }
      });
    }, {});
    return companiesOnSite;
  }, [companies, sites]);

  const contractsPerCompany = useMemo(() => {
    return contracts.items.reduce<HashMap<LabelledEntity[]>>(
      (result, contract): HashMap<LabelledEntity[]> => {
        contract.parties.forEach((party) => {
          const contractLabelledEntity = {
            _id: contract._id,
            label: contract.label,
          };

          if (Array.isArray(result[party.companyId])) {
            result[party.companyId].push(contractLabelledEntity);
          } else {
            result[party.companyId] = [contractLabelledEntity];
          }
        });

        return result;
      },
      {}
    );
  }, [contracts]);

  const executionDateFieldProps: InspectionFieldProps = useMemo(() => {
    return {
      formKey: 'inspectionDate',
      required: false,
      labelId: 'inspection_execution_date',
      fieldName: InspectionFieldName.inspectionDate,
      disabled: false,
      timezone: timezone,
      daymoment: DayMomentValidation.start,
      defaultValue: nowTimezonedDateTime(
        timezone,
        DayMomentValidation.start
      ),
      reserveSpaceForHelperText: true,
      dense: true,
      'data-qa': 'inspection_execution_date_field',
    };
  }, [timezone]);

  const workTypesFieldProps: InspectionFieldProps = useMemo(() => {
    return {
      formKey: 'workTypes',
      required: false,
      entityName: EditableFieldValuesEntityName.workTypes,
      labelId: 'extendedField_Work_type',
      fieldName: InspectionFieldName.multiselectWorkTypes,
      available: processWorkTypes as WorktypeOnView[],
      reserveSpaceForHelperText: true,
      dense: true,
      'data-qa': 'inspection_worktypes_field',
    };
  }, [processWorkTypes]);

  const sitesFieldProps: InspectionFieldProps = useMemo(() => {
    const protocol = inspection?.protocol;
    const anyIssuesInProtocol =
      Boolean(protocol) &&
      protocol!.some(
        (protocolItem) => protocolItem.complianceCheck.issues.length
      );

    return {
      formKey: 'site',
      required: true,
      labelId: 'primaryField_Site',
      entityName: EditableStandardEntityName.location,
      fieldName: InspectionFieldName.location,
      available: sites.filter(keepUndeleted).map(toLabelledEntity),
      disabled: anyIssuesInProtocol
        ? { reason: 'inspection_form_issue_site_edit_warning' }
        : false,
      autoSelect: 'asSingleSelect',
      reserveSpaceForHelperText: true,
      dense: true,
      'data-qa': 'inspection_sites_field',
    };
  }, [sites, inspection]);

  const levelsFieldProps: InspectionFieldProps = useMemo(() => {
    return {
      formKey: 'levels',
      required: true,
      labelId: 'primaryField_Level',
      entityName: EditableStandardEntityName.level,
      fieldName: InspectionFieldName.multiselectLevels,
      available: levelsPerSite,
      dependsOn: 'site',
      extractor: levelsExtractor,
      autoSelect: 'asMultiSelect',
      reserveSpaceForHelperText: true,
      dense: true,
      'data-qa': 'inspection_level_field',
    };
  }, [levelsPerSite]);

  const controlledPartiesFieldProps: InspectionFieldProps = useMemo(() => {
    return {
      formKey: 'controlledParty',
      required: false,
      labelId: 'inspection_controlled_party',
      field: {
        name: InspectionFieldName.multiselectControlledParties,
      },
      fieldName: InspectionFieldName.multiselectControlledParties,
      entityName: EditableStandardEntityName.company,
      available: companiesPerSite,
      dependsOn: 'site',
      extractor: controlledPartiesExtractor,
      disabled: false,
      reserveSpaceForHelperText: true,
      dense: true,
      'data-qa': 'inspection_controlled_party_field',
    };
  }, [companiesPerSite]);

  const contractsFieldProps: InspectionFieldProps = useMemo(() => {
    return {
      formKey: 'contracts',
      required: false,
      labelId: 'inspection_contract_number',
      fieldName: InspectionFieldName.multiselectContracts,
      entityName: EditableStandardEntityName.contract,
      available: contractsPerCompany,
      dependsOn: 'controlledParty',
      extractor: contractsExtractor,
      disabled: false,
      reserveSpaceForHelperText: true,
      dense: true,
      'data-qa': 'inspection_contract_number_field',
    };
  }, [contractsPerCompany]);

  const commentFieldProps: InspectionFieldProps = useMemo(() => {
    return {
      formKey: 'comment',
      required: false,
      labelId: 'inspection_comment',
      fieldName: InspectionFieldName.textFieldComment,
      disabled: false,
      localStorageKey: 'inspection-comment',
      reserveSpaceForHelperText: true,
      dense: true,
      'data-qa': 'inspection_comment_field',
      multiline: true,
    };
  }, []);

  const submitWithRedirect = useCallback(() => {
    setCanRedirect(true);
    onSubmit();
  }, [setCanRedirect, onSubmit]);

  const finishWithRedirect = useCallback(() => {
    return createDialog({
      title: <FormattedMessage id='dialog_title_finish_inspection' />,
      description: (
        <span>
          <FormattedMessage id='dialog_confirmation_finish_inspection' />
        </span>
      ),
      customControllerLabels: ['general_cancel', 'form_save_and_finish'],
    }).then(() => {
      setCanRedirect(true);
      onSubmit({ finish: true });
    });
  }, [setCanRedirect, onSubmit, createDialog]);

  const protocolIssues = Array.isArray(inspection?.protocol)
    ? inspection!.protocol.reduce((count, item): number => {
        count += item.complianceCheck.issues.length;
        return count;
      }, 0) || 0
    : 0;

  const checklist = useMemo(
    () =>
      template
        ? template.checklist.filter((checklistItem) => {
            return !checklistItem.deleted;
          })
        : [],
    [template]
  );

  if (loading || !template) {
    return (
      <Spinner
        reason={`InspectionForm loading: ${loading} || !template: ${!template}`}
      />
    );
  }

  return (
    <InspectionFormPresentational
      template={template}
      onSubmit={submitWithRedirect}
      onFinish={finishWithRedirect}
      onCancel={onCancel}
      isPosting={isPosting}
      executionFieldProps={executionDateFieldProps}
      workTypesFieldProps={workTypesFieldProps}
      sitesFieldProps={sitesFieldProps}
      levelsFieldProps={levelsFieldProps}
      controlledPartiesFieldProps={controlledPartiesFieldProps}
      contractsFieldProps={contractsFieldProps}
      commentFieldProps={commentFieldProps}
      checklist={checklist}
      protocolIssues={protocolIssues}
    />
  );
}

export const MemoInspectionForm = React.memo(
  withSites(
    withLevels(
      withLevelsToSelect(
        withCompanies(
          withContracts(
            withWorktypes(withIssuesOnInspection(InspectionForm))
          )
        )
      )
    )
  )
);
