import { useGetChart } from 'charts/shared/hooks';
import { listViewDonutChartOptions } from 'charts/shared/options/donutChart';
import { useProcessesWithField } from 'charts/shared/useProcessesWithField';
import { useCallback, useContext, useEffect, useState } from 'react';
import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import { ApexSeriesAndLabels, IssueChart } from 'shared/types/analytics';
import { ChartDetailsContext } from 'views/analytics/chartDetails/ChartDetailsProvider';
import { ChartType } from 'shared/types/analytics';
import { GenericChartState } from '../shared/types';
import { useWorkTypeFilters } from './filters';
import { detailedViewWorkTypeChartOptions } from './options';
import { WorkTypeChartContext } from './types';

export function useWorkType(): WorkTypeChartContext {
  const processesWithWorkType = useProcessesWithField(
    IssueFieldNames.workTypes
  );
  const access = useCallback(() => {
    return Boolean(processesWithWorkType.length);
  }, [processesWithWorkType]);
  const filters = useWorkTypeFilters(processesWithWorkType);
  const { series, loading, load, reload, updatedAt } =
    useGetChart<ApexSeriesAndLabels>(IssueChart.workType, filters);

  const isLoading = !filters || loading;

  const isDetailedView = useContext(ChartDetailsContext);
  const options = isDetailedView
    ? detailedViewWorkTypeChartOptions
    : listViewDonutChartOptions;

  const [state, setState] = useState<GenericChartState>({
    series: [],
    options,
  });

  useEffect(() => {
    setState((prev: GenericChartState): GenericChartState => {
      return {
        ...prev,
        series: series[0],
        options: {
          ...options,
          labels: series[1],
        },
      };
    });
  }, [series, options]);

  return {
    state,
    filters,
    titleId: 'work_type_chart',
    chartType: ChartType.donut,
    availableTypes: [ChartType.donut],
    canAccess: access,
    loading: isLoading,
    load: load,
    forceUpdate: reload,
    updatedAt: (): number => updatedAt,
  };
}
