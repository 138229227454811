import { BroadcastChannel } from 'broadcast-channel';
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { ChannelNames } from 'shared/domain/channelNames';
import { startGetDocumentSecuredByTokenSignedRequest } from 'shared/domain/document/startGetDocumentSecuredByTokenSignedRequest';
import { debugLog } from 'shared/logger/debugLog';
import { SharedResourceDownloadContextType } from './types';
import { useMountedRef } from 'hooks/useMountRef';
import {
  DomainMessagesTypes,
  Message,
} from 'shared/domain/messages/message';
import { SharedDocumentDownloadData } from 'shared/types/document';
import {
  SharedMultiReportDownloadData,
  SharedSingleReportDownloadData,
} from 'shared/types/report';
import useFormEdit from 'views/issue/forms/useFormEdit';
import {
  useServiceWorkerReady,
  withServiceWorkerReady,
} from '../withServiceWorkerReady';
import { downloadZip } from './downloadZip';
import startGetReportSignedRequestUseCase from 'shared/domain/report/startGetReportSignedRequest';

type SharedResourceType = 'report' | 'document';
const SharedResourceDownloadContext = React.createContext<
  SharedResourceDownloadContextType | undefined
>(undefined);

const _WithSharedResourceDownload: React.FC<{
  entityType: SharedResourceType;
  children?: ReactNode;
}> = ({ entityType, children }) => {
  const { setEditing } = useFormEdit();
  const mountedRef = useMountedRef();
  const serviceWorkerReady = useServiceWorkerReady();
  const [statusCode, setStatusCode] = useState<number | undefined>();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [signedRequest, setSignedRequest] = useState<
    string | string[] | undefined
  >();
  const [resourceId, setResourceId] = useState<string | undefined>();
  const [issueId, setIssueId] = useState<string | undefined>();
  const [eventId, setEventId] = useState<string | undefined>();
  const [token, setToken] = useState<string | undefined>();

  const startDownloadSharedResource = useCallback(
    ({
      reportId,
      documentId,
      issueId,
      eventId,
      token,
    }: {
      reportId: string | undefined;
      documentId: string | undefined;
      issueId: string | undefined;
      eventId: string | undefined;
      token: string;
    }) => {
      setResourceId(reportId || documentId);
      setIssueId(issueId);
      setEventId(eventId);
      setToken(token);
      setIsDownloading(true);
      setStatusCode(undefined);
    },
    []
  );

  useEffect(() => {
    if (!resourceId || !serviceWorkerReady) {
      return;
    }

    const broadcast = new BroadcastChannel(
      ChannelNames.signedRequestChannel
    );

    broadcast.onmessage = (
      event: Message<
        | SharedMultiReportDownloadData
        | SharedSingleReportDownloadData
        | SharedDocumentDownloadData
      >
    ): void => {
      if (!mountedRef.current) {
        return;
      }

      if (
        event.data &&
        event.type === DomainMessagesTypes.signedRequest &&
        event.data.statusCode === 200 &&
        event.data._id === resourceId
      ) {
        debugLog(
          'broadcast close received shared resource signed request',
          event.data
        );
        const signedRequest =
          event.data.fileStructure === 'multi'
            ? event.data.signedRequests
            : event.data.signedRequest;
        setSignedRequest(signedRequest);
        setStatusCode(event.data.statusCode);
        setIsDownloading(false);
        broadcast.close();
      }

      if (
        event.data &&
        event.type === DomainMessagesTypes.signedRequest &&
        event.error &&
        event.data._id === resourceId
      ) {
        debugLog('signed request reception error', event);
        setStatusCode(event.data.statusCode);
        setIsDownloading(false);
      }
    };

    debugLog('broadcast signed request listening');

    return (): void => {
      broadcast.close();
    };
  }, [resourceId, serviceWorkerReady, mountedRef]);

  useEffect(() => {
    if (entityType === 'report') {
      if (!resourceId || !token || !serviceWorkerReady) {
        return;
      }

      startGetReportSignedRequestUseCase({ id: resourceId, token });
    }
  }, [resourceId, token, serviceWorkerReady, entityType]);

  useEffect(() => {
    if (entityType === 'document') {
      if (!resourceId || !token || !issueId || !serviceWorkerReady) {
        return;
      }

      startGetDocumentSecuredByTokenSignedRequest({
        id: resourceId,
        token,
        issueId,
        eventId,
      });
    }
  }, [resourceId, token, issueId, serviceWorkerReady, entityType]);

  const onZipDone = useCallback(() => {
    setEditing(false, 'reportDownload');
  }, [setEditing]);

  const downloadSignedRequest = useCallback(() => {
    if (!signedRequest) {
      return;
    }

    if (typeof signedRequest === 'string') {
      const link = document.createElement('a');
      link.href = signedRequest;
      link.dispatchEvent(new MouseEvent('click'));
      return;
    }

    if (Array.isArray(signedRequest)) {
      setEditing(true, 'reportDownload');
      downloadZip(signedRequest, onZipDone);
      return;
    }
  }, [signedRequest, onZipDone, setEditing]);

  useEffect(() => {
    downloadSignedRequest();
  }, [downloadSignedRequest]);

  const ctx = {
    statusCode,
    startDownloadSharedResource: startDownloadSharedResource,
    isDownloading,
    downloadSignedRequest,
  };

  return (
    <SharedResourceDownloadContext.Provider value={ctx}>
      {children}
    </SharedResourceDownloadContext.Provider>
  );
};

const WithSharedResourceDownload = withServiceWorkerReady(
  _WithSharedResourceDownload
);

const withSharedResourceDownload =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithSharedResourceDownload>
      <Component {...props} />
    </WithSharedResourceDownload>
  );

function useSharedResourceDownload(): SharedResourceDownloadContextType {
  const context = React.useContext(SharedResourceDownloadContext);
  if (context === undefined) {
    throw new Error(
      'useSharedResourceDownload must be used within a SharedResourceDownloadContextProvider'
    );
  }
  return context;
}

export {
  useSharedResourceDownload,
  WithSharedResourceDownload,
  withSharedResourceDownload,
};
