import { BroadcastChannel } from 'broadcast-channel';
import {
  makeSimpleBroadcastEntity,
  makeSimpleBroadcastError,
} from 'serviceWorker/repository/broadcasts/factory';
import { ChannelNames } from 'shared/domain/channelNames';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { DocumentOutDto } from 'shared/dtos/out/document';
import { SharedDocumentDownloadData } from 'shared/types/document';
import {
  SharedMultiReportDownloadData,
  SharedSingleReportDownloadData,
} from 'shared/types/report';

type MultiReportResponse = {
  type: 'multi';
  signedRequests: string[];
  signedRequest: null;
};
type SingleReportResponse = {
  type: 'single';
  signedRequest: string;
  signedRequests: null;
};
type SharedDocumentResponse = {
  document: DocumentOutDto;
  signedRequest: string;
};

function createReportMessage(
  responseData: MultiReportResponse | SingleReportResponse,
  statusCode: number,
  _id: string
): {
  error?: any;
  data?: SharedMultiReportDownloadData | SharedSingleReportDownloadData;
} {
  if (statusCode === 200) {
    if (responseData.type === 'multi') {
      const data: SharedMultiReportDownloadData = {
        statusCode,
        _id,
        signedRequests: responseData.signedRequests,
        fileStructure: responseData.type,
        entityType: 'report',
      };
      return {
        data,
      };
    }
    const data: SharedSingleReportDownloadData = {
      statusCode,
      _id,
      signedRequest: responseData.signedRequest,
      fileStructure: responseData.type,
      entityType: 'report',
    };
    return {
      data,
    };
  }

  return {
    error: responseData,
  };
}

function createDocumentMessage(
  responseData: SharedDocumentResponse,
  statusCode: number,
  _id: string
): { error?: any; data: SharedDocumentDownloadData } {
  if (statusCode === 200) {
    return {
      data: {
        statusCode,
        _id,
        signedRequest: responseData.signedRequest,
        fileStructure: 'single',
        entityType: 'document',
        document: responseData.document,
      },
    };
  }

  return {
    error: responseData,
    data: {
      statusCode,
      _id,
      signedRequest: responseData?.signedRequest,
      fileStructure: 'single',
      entityType: 'document',
      document: responseData?.document,
    },
  };
}
export function broadcastSharedResourceSignedRequest(
  resourceId: string,
  reponseData:
    | MultiReportResponse
    | SingleReportResponse
    | SharedDocumentResponse,
  statusCode: number,
  entityType: 'document' | 'report'
): void {
  const message =
    entityType === 'document'
      ? createDocumentMessage(
          reponseData as SharedDocumentResponse,
          statusCode,
          resourceId
        )
      : createReportMessage(
          reponseData as MultiReportResponse | SingleReportResponse,
          statusCode,
          resourceId
        );
  const broadcast = new BroadcastChannel(
    ChannelNames.signedRequestChannel
  );
  broadcast.postMessage({
    ...message,
    type: DomainMessagesTypes.signedRequest,
  });
  broadcast.close();
}

export const broadcastReportCreated = makeSimpleBroadcastEntity(
  DomainMessagesTypes.createdEntity,
  ChannelNames.reportChannel
);

export const broadcastCreateReportError = makeSimpleBroadcastError(
  DomainMessagesTypes.createdEntity,
  ChannelNames.reportChannel
);
