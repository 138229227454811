import { InspectionEntity } from 'serviceWorker/repository/inspection/entity';
import {
  ApexSeriesAndLabels,
  CompletedInspectionsByTemplateFilters,
} from 'shared/types/analytics';
import {
  filterByCreatedBy,
  filterByDateRange,
  filterByProcess,
  filterInspectionBySite,
  increaseEntitySeries,
  toSortedApexSeries,
} from '../seriesCreation';
import { InspectionTemplateEntity } from 'serviceWorker/repository/inspectionTemplate/entity';
import { LabelledEntity } from 'shared/types/commonView';

export async function createSeriesAndLabelsFromInspectionsWithFilters(
  inspections: InspectionEntity[],
  templateProcessByIdRecord: Record<string, { process: string }>,
  filters: CompletedInspectionsByTemplateFilters,
  timezone: string,
  additionalParams: { templates: InspectionTemplateEntity[] }
): Promise<ApexSeriesAndLabels> {
  const templatesLabels = {};
  const templatesMap = additionalParams.templates.reduce(
    (map, template) => {
      map.set(template._id, {
        _id: template._id,
        label:
          template.summary.length > 15
            ? template.summary.substring(0, 15) + `…`
            : template.summary,
      });
      return map;
    },
    new Map<string, LabelledEntity>()
  );
  const result: Record<string, number> = {};

  inspections
    .filter((inspection) => {
      return isInspectionValid(
        inspection,
        filters,
        timezone,
        templateProcessByIdRecord,
        templatesMap
      );
    })
    .forEach((inspection) => {
      increaseEntitySeries(
        result,
        templatesLabels,
        templatesMap.get(inspection.template)!
      );
    });

  return toSortedApexSeries(result, templatesLabels);
}

function isInspectionValid(
  inspection: InspectionEntity,
  filters: CompletedInspectionsByTemplateFilters,
  timezone: string,
  templateProcessByIdRecord: Record<string, { process: string }>,
  templatesMap: Map<string, LabelledEntity>
): boolean {
  const [dateRangeFilter, processFilter, siteFilter, createdByFilter] =
    filters.dataScope.filters;
  if (!inspection.isCompleted) return false;
  if (!templatesMap.get(inspection.template)) {
    throw new Error(
      `Template _id:${inspection.template}; missing for inspection _id:${inspection._id}`
    );
  }
  if (!templateProcessByIdRecord[inspection.template]) {
    throw new Error(
      `Template _id:${inspection.template}; missing for inspection _id:${inspection._id}`
    );
  }

  return (
    filterByDateRange(
      dateRangeFilter.value,
      inspection.inspectionDate || inspection.createdAt,
      timezone
    ) &&
    filterByProcess(
      processFilter.value,
      templateProcessByIdRecord[inspection.template]
    ) &&
    filterInspectionBySite(siteFilter.value, inspection) &&
    filterByCreatedBy(createdByFilter.value, inspection)
  );
}
