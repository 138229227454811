import { ApexOptions } from 'apexcharts';
import { palette } from '../index';
import { chartMenu } from '../toolbar';
import { ApexToolbarOptions } from '../types';

const MAX_LEGEND_LABEL_LENGTH = 30;

function truncateString(value: string, maxLength: number): string {
  if (maxLength > 0 && value.length > maxLength) {
    return value.slice(0, maxLength).trim() + '…';
  }

  return value;
}

export const sharedDonutChartOptions: ApexOptions = {
  colors: Object.values(palette),
  dataLabels: {
    enabled: true,
    formatter: function (val: number): string {
      return Math.floor(val) + '%';
    },
    style: {
      fontSize: '1rem',
      fontFamily: 'Archivo, Arial',
      fontWeight: '700',
      colors: ['black'],
    },
    dropShadow: {
      enabled: false,
    },
  },
  stroke: {
    width: 0,
  },

  legend: {
    formatter: function (val: string, opts: any): string {
      return (
        val + ' | ' + opts.w.globals.series[opts.seriesIndex] + ' issues'
      );
    },
  },
};

export const sharedDonutChartInspectionOptions: ApexOptions = {
  colors: Object.values(palette),
  dataLabels: {
    enabled: true,
    formatter: function (val: number): string {
      return Math.floor(val) + '%';
    },
    style: {
      fontSize: '1rem',
      fontFamily: 'Archivo, Arial',
      fontWeight: '700',
      colors: ['black'],
    },
    dropShadow: {
      enabled: false,
    },
  },
  stroke: {
    width: 0,
  },

  legend: {
    formatter: function (val: string, opts: any): string {
      return (
        val +
        ' | ' +
        opts.w.globals.series[opts.seriesIndex] +
        ' inspections'
      );
    },
  },
};

export function createDonutChartToolbarOptions(
  csvFilename: string
): ApexToolbarOptions {
  return {
    show: true,
    offsetX: 0,
    offsetY: 0,
    tools: {
      download: `<img src="${chartMenu}" width="24">`,
    },

    export: {
      csv: {
        filename: csvFilename,
        columnDelimiter: ',',
        headerCategory: 'category',
        headerValue: 'value',
      },
    },
    autoSelected: 'pan',
  };
}

export const listViewDonutChartOptions: ApexOptions = {
  ...sharedDonutChartOptions,
  chart: {
    fontFamily: 'Archivo, Arial',
    toolbar: { show: false },
    redrawOnParentResize: true,
  },
  legend: {
    formatter: function (val: string, opts: any): string {
      return (
        truncateString(val, MAX_LEGEND_LABEL_LENGTH) +
        ' | ' +
        opts.w.globals.series[opts.seriesIndex] +
        ' issues'
      );
    },
  },
};

export const listViewInspectionsDonutChartOptions: ApexOptions = {
  ...sharedDonutChartOptions,
  chart: {
    fontFamily: 'Archivo, Arial',
    toolbar: { show: false },
    redrawOnParentResize: true,
  },
  legend: {
    formatter: function (val: string, opts: any): string {
      return (
        truncateString(val, MAX_LEGEND_LABEL_LENGTH) +
        ' | ' +
        opts.w.globals.series[opts.seriesIndex] +
        ' inspections'
      );
    },
  },
};
