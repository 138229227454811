import { ApexOptions } from 'apexcharts';
import { palette } from 'charts/shared';

function valueFormatter(val: number, opts): string {
  if (opts.seriesIndex === 1) return val + '%';
  return `${val}`;
}

export const dataLabels = {
  enabled: true,
  offsetY: -16,
  style: {
    colors: ['#000000'],
    fontWeight: '100',
    fontSize: '14px',
  },
  formatter: valueFormatter,
  background: { enabled: false, foreColor: '#000000', dropShadow: {} },
};

export const detailedViewInspectionEvolutionChartOptions: ApexOptions = {
  colors: Object.values(palette),
  markers: {
    size: 0,
  },
  stroke: {
    width: [0, 4],
  },
  dataLabels: dataLabels,
  legend: {
    horizontalAlign: 'left' as 'left',
    offsetX: -26,
    // CANNOT USE REM
    fontSize: '14px',
    showForSingleSeries: true,
  },
  xaxis: {
    type: 'datetime',
  },
  chart: {
    fontFamily: 'Archivo, Arial',
    redrawOnParentResize: true,
    toolbar: { show: false },
  },
  tooltip: {
    y: {
      formatter: valueFormatter,
    },
  },
};

export const listViewInspectionEvolutionChartOptions: ApexOptions = {
  colors: Object.values(palette),
  markers: {
    size: 0,
  },
  stroke: {
    width: [0, 4],
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    horizontalAlign: 'left' as 'left',
    offsetX: -26,
    // CANNOT USE REM
    fontSize: '14px',
    showForSingleSeries: true,
  },
  xaxis: {
    type: 'datetime',
  },
  chart: {
    fontFamily: 'Archivo, Arial',
    redrawOnParentResize: true,
    toolbar: { show: false },
  },
};
