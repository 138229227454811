import { ApexOptions } from 'apexcharts';
import {
  createDonutChartToolbarOptions,
  sharedDonutChartInspectionOptions,
} from '../shared/options/donutChart';

export const detailedViewCompletedInspectionsByTemplateChartOptions: ApexOptions =
  {
    ...sharedDonutChartInspectionOptions,
    chart: {
      fontFamily: 'Archivo, Arial',
      toolbar: createDonutChartToolbarOptions(
        'completedInspectionsByTemplate'
      ),
      redrawOnParentResize: true,
    },
  };
