import {
  Autocomplete,
  Box,
  FormHelperText,
  IconButton,
  AutocompleteRenderOptionState,
  Tooltip,
} from '@mui/material';
import { AutocompleteOwnerState } from '@mui/material/Autocomplete';
import { ErrorPresentation } from 'helpers/validators';
import {
  FocusEventHandler,
  ReactElement,
  Ref,
  SyntheticEvent,
  forwardRef,
  memo,
  useCallback,
  MouseEvent,
  HTMLAttributes,
  ReactNode,
  useState,
  useEffect,
} from 'react';
import { useIntl } from 'react-intl';
import {
  getAutocompleteRenderInput,
  getHelperTextFromError,
} from './common';
import ClearIcon from '@mui/icons-material/Clear';

const MemoAutocomplete = memo(Autocomplete);

function get(key: string): string[] {
  const item = localStorage.getItem(key);
  if (item) {
    const parsed = JSON.parse(item);
    if (Array.isArray(parsed)) {
      if (parsed.every((i) => typeof i === 'string')) {
        return parsed;
      }
    }
  }
  return [];
}

function set(key: string, newValue: string[]): void {
  localStorage.setItem(key, JSON.stringify(newValue));
}

function setInLocalStorage(key: string, value: string): void {
  const items = get(key);
  const index = items.indexOf(value);
  if (index === 0) {
    return;
  }
  if (index > 0) {
    // remove from existing position
    items.splice(index, 1);
  }
  // insert on top
  items.unshift(value);
  if (items.length > 50) {
    items.length = 50;
  }
  set(key, items);
}

function removeFromLocalStorage(key: string, value: string): string[] {
  const items = get(key);
  const index = items.indexOf(value);
  if (index !== -1) {
    items.splice(index, 1);
    set(key, items);
  }
  return items;
}

type AutocompleteWithTextProps = {
  autoFocus?: boolean;
  handleBlur?: FocusEventHandler<HTMLDivElement>;
  handleChange: (event: any, value: string | null | undefined) => void;
  labelId: string;
  disabled?: boolean | { reason: string };
  error: ErrorPresentation;
  required?: boolean;
  minRows?: number;
  localStorageKey: string;
  isHalf?: boolean;
  defaultValue?: string;
  dense: boolean;
  reserveSpaceForHelperText: boolean;
  multiline?: boolean;
};

function AutocompleteWithText(
  {
    autoFocus,
    labelId,
    required,
    disabled,
    handleChange,
    handleBlur,
    error,
    minRows,
    localStorageKey,
    isHalf,
    defaultValue,
    dense,
    reserveSpaceForHelperText,
    multiline,
  }: AutocompleteWithTextProps,
  ref: Ref<HTMLDivElement>
): ReactElement {
  const intl = useIntl();
  if (!localStorageKey) {
    throw new Error('local storage key is required');
  }

  const id = `autocomplete-${localStorageKey}`;
  const [options, setOptions] = useState<string[]>(get(id));

  // Update options when component mounts
  useEffect(() => {
    setOptions(get(id));
  }, [id]);

  const sx = {
    fontFamily: 'Archivo, Arial',
    width: '100%',
    '.MuiFormLabel-root': {
      fontFamily: 'Archivo, Arial',
      color: '#979797',
      bottom: '1rem',
      lineHeight: 1,
    },
    '.MuiInputBase-input': {
      height: '1rem',
    },
    '.MuiFormLabel-root.Mui-focused': {
      color: '#FBCC30',
    },
    '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
      {
        borderColor: '#FBCC30',
      },
  };

  const helperText = getHelperTextFromError(error, intl);

  const renderInput = getAutocompleteRenderInput({
    autoFocus,
    id,
    required: !!required,
    error,
    disabled: !!disabled,
    label: labelId ? intl.formatMessage({ id: labelId }) : '',
    placeholder: '',
    dense,
    multiline,
  });

  const onBlur: FocusEventHandler<HTMLDivElement> = useCallback(
    (event): void => {
      const value = (event.target as HTMLInputElement).value;
      if (typeof value === 'string' && value.length > 0) {
        setInLocalStorage(id, value);
        // Update options after adding a new value
        setOptions(get(id));
      }
      handleBlur && handleBlur(event);
    },
    [id, handleBlur]
  );

  const unifiedHandleChange = useCallback(
    (event: SyntheticEvent<Element, Event>, value: unknown) => {
      handleChange(event, value as string);
    },
    [handleChange]
  );

  const handleRemoveOption = useCallback(
    (option: string, event: MouseEvent) => {
      event.stopPropagation();
      // Remove from localStorage and update options state
      const updatedOptions = removeFromLocalStorage(id, option);
      setOptions([...updatedOptions]); // Create a new array to trigger re-render
    },
    [id]
  );

  const renderOption = useCallback(
    (
      props: HTMLAttributes<HTMLLIElement>,
      option: unknown,
      state: AutocompleteRenderOptionState,
      ownerState: AutocompleteOwnerState<
        unknown,
        boolean,
        boolean,
        boolean
      >
    ): ReactNode => {
      const optionValue = option as string;
      return (
        <li {...props}>
          <Box
            component='div'
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <span>{optionValue}</span>
            <Tooltip title={intl.formatMessage({ id: 'general_remove' })}>
              <IconButton
                size='small'
                sx={{
                  visibility: 'hidden',
                  '.MuiAutocomplete-option:hover &': {
                    visibility: 'visible',
                  },
                  color: '#757575',
                  '&:hover': {
                    color: '#000000',
                  },
                }}
                onClick={(event) => handleRemoveOption(optionValue, event)}
                aria-label='remove option'
              >
                <ClearIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          </Box>
        </li>
      );
    },
    [handleRemoveOption]
  );

  return (
    <Box>
      <MemoAutocomplete
        value={defaultValue}
        options={options}
        id={id}
        disabled={Boolean(disabled)}
        freeSolo
        onChange={unifiedHandleChange}
        onBlur={onBlur}
        onInputChange={unifiedHandleChange}
        blurOnSelect={true}
        renderInput={renderInput}
        renderOption={renderOption}
        sx={sx}
      />
      <FormHelperText error={!!error}>
        {helperText || (reserveSpaceForHelperText && ' ')}
      </FormHelperText>
    </Box>
  );
}

export const MemoAutocompleteWithText = memo(
  forwardRef(AutocompleteWithText)
);
