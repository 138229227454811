import { FilterOptionsState } from '@mui/base';
import { TextField } from '@mui/material';
import {
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
  createFilterOptions,
} from '@mui/material/Autocomplete';
import { CreateCompanyContext } from 'components/dataCreationForms/company/types';
import {
  WithCreateCompany,
  useCreateCompany,
} from 'components/dataCreationForms/company/withCreateCompany';
import { CreateContractContext } from 'components/dataCreationForms/contract/types';
import {
  WithCreateContract,
  useCreateContract,
} from 'components/dataCreationForms/contract/withCreateContract';
import {
  CreateLevelContext,
  OpenCreateLevelDialog,
} from 'components/dataCreationForms/level/types';
import {
  WithCreateLevel,
  useCreateLevel,
} from 'components/dataCreationForms/level/withCreateLevel';
import { CreateOrganizationContext } from 'components/dataCreationForms/organization/types';
import {
  WithCreateOrganization,
  useCreateOrganization,
} from 'components/dataCreationForms/organization/withCreateOrganization';
import { CreateSiteContext } from 'components/dataCreationForms/site/types';
import {
  WithCreateSite,
  useCreateSite,
} from 'components/dataCreationForms/site/withCreateSite';
import { OpenCreateDialog } from 'components/dataCreationForms/types';
import {
  CreateUserContext,
  OpenCreateUserDialog,
} from 'components/dataCreationForms/user/types';
import {
  WithCreateUser,
  useCreateUser,
} from 'components/dataCreationForms/user/withCreateUser';
import {
  FieldValueFormContext,
  WithFieldValueForm,
  useFieldValueForm,
} from 'components/dataCreationForms/withFieldValueForm';
import { withFieldValuesForm } from 'components/dataCreationForms/withFieldValuesForm';
import { useInputForm } from 'components/dataCreationForms/withInputForm';
import { withCustomizedField } from 'components/dataProviders/withCustomizedField';
import { validateCreatePermission } from 'helpers/permission';
import { ErrorPresentation } from 'helpers/validators';
import { ReactElement, SyntheticEvent, useMemo } from 'react';
import { IntlShape } from 'react-intl';
import { useSelector } from 'react-redux';
import { toUserRole } from 'redux/selectors/role';
import {
  CREATE_COMPANY_PERMISSIONS,
  CREATE_CONTRACT_PERMISSIONS,
  CREATE_LEVEL_PERMISSIONS,
  CREATE_LOCATION_PERMISSIONS,
  CREATE_ORGANIZATION_PERMISSIONS,
  CREATE_USER_PERMISSIONS,
} from 'setup/permissions';
import {
  EditableFieldValuesEntityName,
  EditableStandardEntityName,
  editableFieldValuesEntityNames,
} from 'shared/domain/fieldValue/fields';
import { isAdmin } from 'shared/domain/role/isAdmin';
import { LabelledEntity } from 'shared/types/commonView';
import { UserRole } from 'shared/types/userRole';
import { MemoCreateCompanyWizardDialog } from 'views/companies/wizard/create/dialog';
import { MemoCreateContractWizardDialog } from 'views/contracts/wizard/create/dialog';
import { FieldValueDialog } from 'views/fieldsSettings/wizard/dialog';
import { MemoCreateLevelWizardDialog } from 'views/level/wizard/create/dialog';
import { MemoCreateOrganizationWizardDialog } from 'views/organization/wizard/create/dialog';
import { MemoCreateSiteWizardDialog } from 'views/site/wizard/create/dialog';
import { MemoCreateUserWizardDialog } from 'views/users/wizard/create/dialog';
import { useIsOffline } from '../withIsOffline';
import { SingleChoiceValue } from './singleChoice/types';
import { FreeSoloMultiAutocompleteValue } from './types';

export function getHelperTextFromError(
  error: any,
  intl: IntlShape
): string | undefined {
  if (!error) {
    return '';
  }
  if (error) {
    if (typeof error === 'string') {
      return error;
    } else {
      return intl.formatMessage(
        { id: error.helperText?.id },
        error?.values
      );
    }
  }
}

export function getLocalizedLabel(
  intl: IntlShape,
  localisedLabel?: string,
  labelId?: string
): string {
  return (
    localisedLabel ||
    (labelId ? intl.formatMessage({ id: labelId }) : 'Unknown label')
  );
}

export function unifiedGetOptionLabel(option: any): string {
  if (typeof option === 'string') {
    return option;
  } else {
    return option?.label ?? option?.displayName ?? '';
  }
}

export function unifiedGetOptionKey(option: any): string {
  if (typeof option === 'string') {
    return option;
  } else {
    return option?._id ?? option?.id ?? `randomKey-${Math.random()}`;
  }
}

export function isOptionEqualToValue(
  option: string | LabelledEntity,
  value: LabelledEntity
): boolean {
  if (typeof option === 'string' || typeof value === 'string') {
    return false;
  }
  if (option?._id === value?._id) {
    return true;
  }
  return false;
}

export function canCreateOption(
  userRole: UserRole,
  entityName?:
    | string
    | EditableFieldValuesEntityName
    | EditableStandardEntityName
): Boolean {
  if (!entityName) {
    return false;
  }
  const isOffline = useIsOffline();

  switch (entityName) {
    case EditableStandardEntityName.company:
      return validateCreatePermission(
        CREATE_COMPANY_PERMISSIONS[userRole],
        isOffline
      ).permission;
    case EditableStandardEntityName.contract:
      return validateCreatePermission(
        CREATE_CONTRACT_PERMISSIONS[userRole],
        isOffline
      ).permission;
    case EditableStandardEntityName.location:
      return validateCreatePermission(
        CREATE_LOCATION_PERMISSIONS[userRole],
        isOffline
      ).permission;
    case EditableStandardEntityName.level:
      return validateCreatePermission(
        CREATE_LEVEL_PERMISSIONS[userRole],
        isOffline
      ).permission;
    case EditableStandardEntityName.user:
      return validateCreatePermission(
        CREATE_USER_PERMISSIONS[userRole],
        isOffline
      ).permission;
    case EditableStandardEntityName.organization:
      return validateCreatePermission(
        CREATE_ORGANIZATION_PERMISSIONS[userRole],
        isOffline
      ).permission;
    default:
      return (
        editableFieldValuesEntityNames.some(
          (name) => name === entityName
        ) && isAdmin(userRole)
      );
  }
}

export function getAutocompleteDisplayedHelperText(
  intl: IntlShape,
  helperText?: string,
  error?: any
): string | undefined {
  return (
    helperText ||
    getHelperTextFromError(error, intl) ||
    // TODO MUI5 pomysł jest dobry, ale wyświetla się to od razu przy podlokalizacji na dialogu tworzenia issue - trzeba to przemyśleć
    // (optionsLength === 0 &&
    //   intl.formatMessage({ id: 'autocomplete_no_options' })) ||
    undefined
  );
}

type GetRenderInputProps = {
  autoFocus?: boolean;
  id: string;
  required: boolean;
  error: ErrorPresentation | string;
  disabled: boolean;
  label: string;
  placeholder: string;
  helperText?: string;
  dense: boolean;
  multiline?: boolean;
};

export const getAutocompleteRenderInput = ({
  autoFocus,
  id,
  required,
  error,
  disabled,
  label,
  placeholder,
  helperText,
  dense,
  multiline = false,
}: GetRenderInputProps): ((
  params: AutocompleteRenderInputParams
) => ReactElement) => {
  return function (params: AutocompleteRenderInputParams) {
    const inputProps = useMemo(() => {
      return multiline
        ? {
            ...params.InputProps,
            style: {
              whiteSpace: 'pre-wrap',
            },
          }
        : {
            ...params.InputProps,
          };
    }, [multiline, params.InputProps]);

    return (
      <TextField
        autoFocus={autoFocus}
        variant='outlined'
        {...params}
        multiline={multiline}
        required={required}
        error={Boolean(error)}
        label={label}
        disabled={disabled}
        helperText={helperText}
        id={`${id}-textField`}
        placeholder={placeholder}
        margin={dense ? 'dense' : 'none'}
        InputProps={inputProps}
      />
    );
  };
};
export function getFreeSoloProps(
  entityName:
    | string
    | EditableFieldValuesEntityName
    | EditableStandardEntityName
):
  | {
      Provider: ({ children }) => ReactElement;
      useProvider: () =>
        | FieldValueFormContext
        | CreateCompanyContext
        | CreateSiteContext
        | CreateLevelContext
        | CreateUserContext
        | CreateContractContext
        | CreateOrganizationContext;
      DialogComponent: ReactElement;
    }
  | undefined {
  const userRole = useSelector(toUserRole);

  const canUserCreateOption = canCreateOption(userRole, entityName);
  if (canUserCreateOption) {
    if (
      editableFieldValuesEntityNames.find(
        (customizableField) => customizableField === entityName
      )
    ) {
      return {
        Provider: withCustomizedField(
          withFieldValuesForm(WithFieldValueForm, true),
          entityName as EditableFieldValuesEntityName
        ),
        useProvider: useFieldValueForm,
        DialogComponent: <FieldValueDialog instantSubmit={true} />,
      };
    } else if (entityName === EditableStandardEntityName.company) {
      return {
        Provider: WithCreateCompany,
        useProvider: useCreateCompany,
        DialogComponent: <MemoCreateCompanyWizardDialog />,
      };
    } else if (entityName === EditableStandardEntityName.location) {
      return {
        Provider: WithCreateSite,
        useProvider: useCreateSite,
        DialogComponent: <MemoCreateSiteWizardDialog />,
      };
    } else if (entityName === EditableStandardEntityName.level) {
      return {
        Provider: WithCreateLevel,
        useProvider: useCreateLevel,
        DialogComponent: <MemoCreateLevelWizardDialog />,
      };
    } else if (entityName === EditableStandardEntityName.user) {
      return {
        Provider: WithCreateUser,
        useProvider: useCreateUser,
        DialogComponent: <MemoCreateUserWizardDialog />,
      };
    } else if (entityName === EditableStandardEntityName.contract) {
      return {
        Provider: WithCreateContract,
        useProvider: useCreateContract,
        DialogComponent: <MemoCreateContractWizardDialog />,
      };
    } else if (entityName === EditableStandardEntityName.organization) {
      return {
        Provider: WithCreateOrganization,
        useProvider: useCreateOrganization,
        DialogComponent: <MemoCreateOrganizationWizardDialog />,
      };
    }
  }
}

function getAddValueLabel(inputValue: string, intl: IntlShape): string {
  return `${intl.formatMessage({
    id: 'bottom_nav_create',
  })} "${inputValue}"`;
}

export function multipleFreeSoloFilterOptions(
  options: FreeSoloMultiAutocompleteValue[],
  params: FilterOptionsState<
    FreeSoloMultiAutocompleteValue & { inputValue?: string | undefined }
  >,
  intl: IntlShape
): FreeSoloMultiAutocompleteValue[] {
  const filter = createFilterOptions<FreeSoloMultiAutocompleteValue>();
  const filtered = filter(options, params);

  if (params.inputValue !== '') {
    filtered.push({
      _id: '',
      inputValue: params.inputValue,
      label: getAddValueLabel(params.inputValue, intl),
    });
  }

  return filtered;
}

export function singleFreeSoloFilterOptions(
  options: LabelledEntity[],
  params: FilterOptionsState<
    LabelledEntity & { inputValue?: string | undefined }
  >,
  intl: IntlShape
): Array<LabelledEntity & { inputValue?: string | undefined }> {
  const filter = createFilterOptions<
    LabelledEntity & { inputValue?: string }
  >();

  const filtered = filter(options, params);

  const { inputValue } = params;
  // Suggest the creation of a new value
  if (inputValue && typeof inputValue === 'string') {
    const isExisting = options.some(
      (option) => inputValue === option.label
    );
    if (!isExisting) {
      filtered.push({
        _id: '',
        inputValue,
        label: getAddValueLabel(inputValue, intl),
      });
    }
  }

  return filtered;
}

export type GetOnMultipleFreeSoloChangeHandlerAdditionalParams = {
  siteId?: string;
  siteIds?: string[];
};

export function getOnMultipleFreeSoloChangeHandler(
  openDialog:
    | OpenCreateDialog
    | OpenCreateLevelDialog
    | OpenCreateUserDialog,
  handleChange: (_: any, value: FreeSoloMultiAutocompleteValue[]) => void,
  additionalParams: GetOnMultipleFreeSoloChangeHandlerAdditionalParams
) {
  return (
    event: SyntheticEvent<Element, Event>,
    newValues: FreeSoloMultiAutocompleteValue[]
  ): void => {
    const newlyCreatedOption = newValues.find((item) => !item?._id);
    if (newlyCreatedOption) {
      const newLabel =
        typeof newlyCreatedOption === 'string'
          ? newlyCreatedOption
          : newlyCreatedOption.inputValue;
      openDialog({
        freeSoloLabel: newLabel || '',
        siteId: additionalParams.siteId || '',
        siteIds: additionalParams.siteIds || [],
      });
      event.preventDefault();
      event.stopPropagation();
    } else {
      handleChange(event, newValues);
    }
  };
}

export function getOnSingleFreeSoloChangeHandler(
  openDialog: OpenCreateDialog | OpenCreateLevelDialog,
  handleChange: (_: any, value?: SingleChoiceValue) => void,
  setValue: (value: any) => void,
  { siteId }: { siteId?: string }
) {
  return (
    event: SyntheticEvent<Element, Event>,
    newValue: SingleChoiceValue & { inputValue?: string },
    reason: AutocompleteChangeReason
  ): void => {
    if (typeof newValue === 'string') {
      // if written directly by user and not selected from dropdown
      // reason is then 'createReason'
      setValue(undefined);
      return openDialog({ freeSoloLabel: newValue, siteId: siteId || '' });
    }
    if (newValue?.inputValue) {
      // if selected from dropdown as "Add something" option
      // reason is then 'selectOption'
      setValue(undefined);
      openDialog({
        freeSoloLabel: newValue.inputValue,
        siteId: siteId || '',
      });
      return;
    }
    handleChange(event, newValue);
  };
}

export function getSiteIdFromForm(formValuesGetter): string {
  const values = formValuesGetter
    ? formValuesGetter().values
    : useInputForm().values;

  return values['site']?._id || '';
}

type FreeSoloAdditionalParams = { siteId?: string; siteIds?: string[] };

export function getFreeSoloDialogAdditionalParams(
  siteId?: string
): FreeSoloAdditionalParams {
  return {
    siteId,
    siteIds: siteId ? [siteId] : undefined,
  };
}
