import {
  DependeciesActionEnum,
  DependeciesSetFiltersActionEnum,
  Dependencies,
} from 'charts/shared/types';
import { FilterValues } from 'shared/types/analytics';
type BaseActions =
  | {
      readonly type: DependeciesActionEnum;
      payload: unknown;
    }
  | {
      readonly type: DependeciesSetFiltersActionEnum;
      payload: FilterValues;
    };

export function dependenciesReducer<
  TDeps,
  TFilters,
  TActions extends BaseActions,
>(
  state: Dependencies<TDeps, TFilters>,
  action: TActions
): Dependencies<TDeps, TFilters> {
  switch (action.type) {
    case DependeciesSetFiltersActionEnum.setFiltersResponse:
      return {
        ...state,
        filters: action.payload,
      };

    case DependeciesActionEnum.setProcesses:
      return {
        ...state,
        processes: action.payload,
      };

    case DependeciesActionEnum.setSites:
      return {
        ...state,
        sites: action.payload,
      };

    case DependeciesActionEnum.setManagers:
      return {
        ...state,
        managers: action.payload,
      };

    case DependeciesActionEnum.setProjectId:
      return {
        ...state,
        projectId: action.payload,
      };

    case DependeciesActionEnum.setTimezone:
      return {
        ...state,
        timezone: action.payload,
      };
    case DependeciesActionEnum.setTemplates:
      return {
        ...state,
        templates: action.payload,
      };

    default:
      const exhaustiveCheck: never = action;
      throw new Error(
        `Unhandled dependencies reducer case: ${exhaustiveCheck['type']}`
      );
  }
}

function assertNever(actionType: never, value: { type: string }): never {
  throw new Error(`Unhandled dependencies reducer case: ${value['type']}`);
}
