import { accessAtLeastManager } from 'charts/shared/access';
import { useGetChart } from 'charts/shared/hooks';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  ApexSeriesAndLabels,
  ChartType,
  InspectionChart,
} from 'shared/types/analytics';
import { ChartDetailsContext } from 'views/analytics/chartDetails/ChartDetailsProvider';
import { SimpleChartContext } from 'views/analytics/types';
import { useCompletedInspectionsByTemplateFilters } from './filters';
import { detailedViewCompletedInspectionsByTemplateChartOptions } from './options';
import { listViewInspectionsDonutChartOptions } from 'charts/shared/options/donutChart';
import { GenericChartState } from 'charts/shared/types';
import { useInspectionTemplates } from 'components/dataProviders/withInspectionTemplates';

export function useCompletedInspectionsByTemplate(): SimpleChartContext<any> {
  const { inspectionTemplates, loading: loadingTemplates } =
    useInspectionTemplates();

  const memoedTemplates = useMemo(
    () => ({
      templates: inspectionTemplates.items,
    }),
    [inspectionTemplates]
  );

  const filters = useCompletedInspectionsByTemplateFilters();
  const { series, loading, load, reload, updatedAt } =
    useGetChart<ApexSeriesAndLabels>(
      InspectionChart.completedInspectionsByTemplate,
      filters,
      memoedTemplates
    );

  const isLoading = !filters || loading || loadingTemplates;

  const isDetailedView = useContext(ChartDetailsContext);
  const options = isDetailedView
    ? detailedViewCompletedInspectionsByTemplateChartOptions
    : listViewInspectionsDonutChartOptions;

  const [state, setState] = useState<GenericChartState>({
    series: [],
    options,
  });

  useEffect(() => {
    setState((prev: GenericChartState): GenericChartState => {
      return {
        ...prev,
        series: series[0],
        options: {
          ...options,
          labels: series[1],
        },
      };
    });
  }, [series, options]);

  return {
    state,
    filters,
    titleId: 'completed_inspections_by_template_chart',
    chartType: ChartType.donut,
    availableTypes: [ChartType.donut],
    canAccess: accessAtLeastManager,
    loading: isLoading,
    load: load,
    forceUpdate: reload,
    updatedAt: (): number => updatedAt,
  };
}
