import { TripletSeries } from 'shared/types/analytics';

export function isTripletSeries(
  series: unknown[]
): series is TripletSeries {
  return (
    series.length === 3 &&
    series.every(
      (data) =>
        data !== null &&
        typeof data === 'object' &&
        Object.keys(data as {}).includes('value')
    )
  );
}
