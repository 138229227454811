import {
  IncomingEmailMessageModel,
  IssueEventModel,
  IssueLocationType,
  IssueModel,
} from 'shared/domain/issue/issueModel';
import { entityToModelBase } from 'shared/domain/toBaseModel';
import { toOptionalUserWithPersonalData } from 'shared/domain/user/mapping/toView';
import { UserEntity } from 'shared/domain/user/types/entity';
import {
  EventEntity,
  IncomingEmailMessageEntity,
  IssueEntity,
} from './entity';

export function issueEntityToModel(
  entity: IssueEntity,
  users: UserEntity[]
): IssueModel {
  const modelBase = entityToModelBase({ entity, users });
  return {
    ...modelBase,
    inspection: entity.inspection,
    protocolItem: entity.protocolItem,
    process: entity.process,
    stage: entity.stage,
    userAccesses: entity.userAccesses,
    primaryData: {
      assignee: toOptionalUserWithPersonalData(
        users.find((user) => user._id === entity.primaryData.assignee)
      ),
      contractNumbers: entity.primaryData.contractNumbers || [],
      description: entity.primaryData.description,
      detectionDate: entity.primaryData.detectionDate,
      level: entity.primaryData.level,
      positionOnMap: entity.primaryData.positionOnMap,
      site: entity.primaryData.site,
      subcontractors: entity.primaryData.subcontractors || [],
      title: entity.primaryData.title,
      executor: toOptionalUserWithPersonalData(
        users.find((user) => user._id === entity.primaryData.executor)
      ),
      selectedLocationType:
        entity.primaryData.selectedLocationType || IssueLocationType.pin,
      targetAreas: entity.primaryData.targetAreas || [],
      finalAreas: entity.primaryData.finalAreas || [],
    },
    hashtag: entity._id ? entity._id.slice(-4) : '',
    events: entity.events.map((event) =>
      issueEventEntityToModel(event, users)
    ),
    incomingEmailMessages:
      entity.incomingEmailMessages?.map((message) =>
        incomingEmailMessageEntityToModel(message, users)
      ) || [],
    extendedData: {
      ...entity.extendedData,
    },
    mainImage: entity.mainImage,
    emailAddress: entity.emailAddress,
  };
}

function issueEventEntityToModel(
  issueEventEntity: EventEntity,
  users: UserEntity[]
): IssueEventModel {
  const { description, title, type } = issueEventEntity;
  const modelBase = entityToModelBase({ entity: issueEventEntity, users });
  return {
    ...modelBase,

    description,
    title,
    type,
  };
}

function incomingEmailMessageEntityToModel(
  incomingEmailMessageEntity: IncomingEmailMessageEntity,
  users: UserEntity[]
): IncomingEmailMessageModel {
  const modelBase = entityToModelBase({
    entity: incomingEmailMessageEntity,
    users,
  });
  return {
    ...modelBase,
    subject: incomingEmailMessageEntity.subject,
    incomingEmailMessageContent:
      incomingEmailMessageEntity.incomingEmailMessageContent,
  };
}
