import { Button } from '@mui/material';
import { BigChartCard } from 'charts/shared/BigCard';
import React, { ReactElement, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { MemoChartReloader } from '../chartReloader';
import { ChartRenderer } from '../ChartRenderer';
import { useChartContext } from '../ChartsProvider';
import { FiltersRenderer } from '../filtersRenderer/index';
import { useStyles } from './styles';
import { ReactComponent as RefreshIcon } from '../../../assets/icons/chart_reset_zoom_move_icon.svg';
import { capitalize, toRelativeDate } from './helpers';
import { useIsOffline } from 'components/common/withIsOffline';
import ForbiddenTooltip from 'components/general/ForbiddenTooltip/ForbiddenTooltip';
import useIsMobile from 'hooks/useIsMobile';

type ChartDetailsProps = { id: string };

function ChartDetails({ id }: ChartDetailsProps): ReactElement {
  const isMobile = useIsMobile();
  const intl = useIntl();
  const context = useChartContext(id);
  const isOffline = useIsOffline();

  const classes = useStyles({ isMobile });
  const forceUpdate = context?.forceUpdate;
  const updatedAt = context?.updatedAt();

  const forceReload = useCallback(() => {
    forceUpdate && forceUpdate();
  }, [forceUpdate]);

  const history = useHistory();
  if (!context) {
    history.push('/analytics');
    return <></>;
  }

  const LAST_UPDATE = `${intl.formatMessage({
    id: 'chart_last_update',
  })}: ${capitalize(
    toRelativeDate(
      updatedAt ? new Date(updatedAt) : undefined,
      new Date(),
      intl.formatMessage({ id: 'never' }),
      intl.locale
    )
  )}`;

  return (
    <MemoChartReloader context={context}>
      <div className={classes.root}>
        {isMobile && (
          <h1>
            {context.titleId
              ? intl.formatMessage({
                  id: context.titleId,
                })
              : ' '}
          </h1>
        )}
        <div className={classes.flex}>
          {!isMobile && (
            <h1>
              {context.titleId
                ? intl.formatMessage({
                    id: context.titleId,
                  })
                : ' '}
            </h1>
          )}
          <div className={classes.flexEnd}>
            <p className={classes.mr}>{LAST_UPDATE}</p>
            <ForbiddenTooltip
              visibleWhen={isOffline}
              reason='general_offline_operation_unavailable'
            >
              <Button
                id={id}
                disabled={isOffline}
                data-qa='refresh-chart'
                onClick={forceReload}
                startIcon={<RefreshIcon />}
                className={classes.capitalize}
              >
                {intl.formatMessage({ id: 'refresh' })}
              </Button>
            </ForbiddenTooltip>
          </div>
        </div>
        <BigChartCard noMarginTop>
          <ChartRenderer id={id} height='400px' />
        </BigChartCard>

        <BigChartCard noPadding>
          <FiltersRenderer id={id} />
        </BigChartCard>
      </div>
    </MemoChartReloader>
  );
}

export const MemoChartDetails = React.memo(ChartDetails);
