import React from 'react';
import { InfoBanner } from 'components/general/InfoBanner';
import BrokenLink from 'assets/icons/broken_link.svg';
import Download from 'assets/icons/download.svg';
import ReportNotFound from 'assets/icons/report_not_found.svg';
import { ButtonContained } from 'components/general';
import { useIntl } from 'react-intl';
import useIsMobile from 'hooks/useIsMobile';
import { useSharedResourceDownload } from 'components/common/withSharedResourceDownload/withSharedResourceDownload';
export function DownloadingInfo(): React.ReactElement {
  const intl = useIntl();
  const { downloadSignedRequest } = useSharedResourceDownload();
  const isMobile = useIsMobile();

  return (
    <InfoBanner
      dataQa='downloading-info'
      messageId={
        isMobile ? 'downloading_info_web_mobile' : 'downloading_info'
      }
      icon={Download}
    >
      <p>{intl.formatMessage({ id: 'download_click_button_prompt' })}</p>
      <ButtonContained onClick={downloadSignedRequest}>
        {intl.formatMessage({ id: 'general_download' })}
      </ButtonContained>
    </InfoBanner>
  );
}
export function LinkExpiredInfo(): React.ReactElement {
  return (
    <InfoBanner
      dataQa='link-expired-info'
      messageId='report_link_expired_info'
      icon={BrokenLink}
    />
  );
}
export function NotFoundInfo(): React.ReactElement {
  return (
    <InfoBanner
      dataQa='report-not-found-info'
      messageId='entity_not_found_report_title'
      icon={ReportNotFound}
    />
  );
}
