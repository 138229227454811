import { useCompanies } from 'components/dataProviders/withCompanies';
import { FinancialDataContext } from 'components/dataProviders/withFinancialData';
import { useSites } from 'components/dataProviders/withSites';
import { ColumnKey } from 'components/table/columnShapes/types';
import { toReactColumn } from 'components/table/skeleton';
import {
  sortTableEntities,
  useColumnSortHandler,
} from 'components/table/sort';
import 'components/table/tableOverrides.scss';
import { useSavedTableColumnWidths } from 'hooks/table/useSavedTableColumnWidths';
import { useSyncFinancialData } from 'hooks/useSyncFinancialData';
import { ReactElement, useContext, useMemo, useState } from 'react';
import BaseTable from 'react-base-table';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { projectDataSelector } from 'redux/selectors/project';
import { populateData } from 'shared/domain/company/mapping/toModel';
import { CompanyModel } from 'shared/domain/company/types/model';
import { Sorting, SortingOrder } from 'shared/filter/sort/types';
import { Identificable } from 'shared/types/commonView';
import { EmptyTableInfo } from '../../common/noTableContentInfo/NoTableContentInfo';
import { Nothing } from '../../general/Nothing';
import { TableWidthStorageKey } from '../../table/types';
import { CompanyColumnsFactory } from './columns';
import { headerRenderer } from './headerRenderer';
import { useStyles } from './styles';
import { ISO6391LanguageCode } from 'shared/types/locale';

export function CompanyTable({
  width,
  height,
}: {
  width: number;
  height: number;
}): ReactElement {
  const { companies, emptyListReason } = useCompanies();

  const { financialData, loading, recievedAt } = useContext(
    FinancialDataContext
  );

  const { saveColumnWidth } = useSavedTableColumnWidths<CompanyModel>(
    TableWidthStorageKey.company
  );

  useSyncFinancialData();

  const {
    sites: { items: sites },
  } = useSites();

  const companiesWithTransformedSites = useMemo(
    () =>
      populateData(
        companies.items,
        sites,
        financialData,
        loading,
        recievedAt
      ),
    [companies.items, sites, financialData, loading, recievedAt]
  );

  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const { timezone } = useSelector(projectDataSelector);

  const { columnWidths } = useSavedTableColumnWidths(
    TableWidthStorageKey.company
  );

  const columns = useMemo(
    () =>
      new CompanyColumnsFactory(
        intl.formatMessage,
        columnWidths,
        timezone,
        intl.locale as ISO6391LanguageCode
      ).create(),
    [intl.formatMessage, columnWidths, timezone, intl.locale]
  );

  const [sortBy, setSortBy] = useState<Sorting>({
    key: ColumnKey.shortLabel,
    order: SortingOrder.asc,
  });

  const sortedCompanies = useMemo(
    () =>
      sortTableEntities(companiesWithTransformedSites, columns, sortBy),
    [companiesWithTransformedSites, columns, sortBy]
  );

  const rowEventHandlers = useMemo(() => {
    return {
      onClick: ({ rowData }: { rowData: Identificable }): void => {
        history.push(`company/${rowData._id}`);
      },
    };
  }, [history]);

  const onColumnSort = useColumnSortHandler(setSortBy);

  if (emptyListReason) {
    return <EmptyTableInfo emptyTableReason={emptyListReason} />;
  }

  const reactColumns = columns.map(toReactColumn);
  return (
    <BaseTable
      fixed
      rowKey='customId'
      width={width}
      headerHeight={[50, 50]}
      height={height}
      data={sortedCompanies}
      sortBy={sortBy}
      onColumnSort={onColumnSort}
      className={classes.doubleHeaderNoOverflow}
      rowEventHandlers={rowEventHandlers}
      headerRenderer={headerRenderer}
      onColumnResizeEnd={saveColumnWidth}
      components={{
        SortIndicator: Nothing,
      }}
    >
      {reactColumns}
    </BaseTable>
  );
}
