import { ISO6391LanguageCode } from 'shared/types/locale';
import { LocaleCodeDto } from 'shared/types/localeCodeDto';
import { neverReached } from '../neverReached';
import { debugLog } from 'shared/logger/debugLog';

export function appLocaleToLocaleCodeOutDto(
  appLocale: ISO6391LanguageCode
): LocaleCodeDto {
  switch (appLocale) {
    case ISO6391LanguageCode.en: {
      return LocaleCodeDto.en;
    }
    case ISO6391LanguageCode.ar: {
      return LocaleCodeDto.ar_AE;
    }
    case ISO6391LanguageCode.bg: {
      return LocaleCodeDto.bg_BG;
    }
    case ISO6391LanguageCode.hr: {
      return LocaleCodeDto.hr_HR;
    }
    case ISO6391LanguageCode.cs: {
      return LocaleCodeDto.cs_CZ;
    }
    case ISO6391LanguageCode.fr: {
      return LocaleCodeDto.fr;
    }
    case ISO6391LanguageCode.de: {
      return LocaleCodeDto.de_DE;
    }
    case ISO6391LanguageCode.he: {
      return LocaleCodeDto.he_IL;
    }
    case ISO6391LanguageCode.hu: {
      return LocaleCodeDto.hu_HU;
    }
    case ISO6391LanguageCode.nb: {
      return LocaleCodeDto.nb;
    }
    case ISO6391LanguageCode.pl: {
      return LocaleCodeDto.pl;
    }
    case ISO6391LanguageCode.pt: {
      return LocaleCodeDto.pt_PT;
    }
    case ISO6391LanguageCode.ro: {
      return LocaleCodeDto.ro;
    }
    case ISO6391LanguageCode.ru: {
      return LocaleCodeDto.ru_UA;
    }
    case ISO6391LanguageCode.sk: {
      return LocaleCodeDto.sk_SK;
    }
    case ISO6391LanguageCode.es: {
      return LocaleCodeDto.es;
    }
    case ISO6391LanguageCode.tr: {
      return LocaleCodeDto.tr_TR;
    }
    case ISO6391LanguageCode.uk: {
      return LocaleCodeDto.uk_UA;
    }
    default: {
      debugLog(`Unknown locale code: ${appLocale}`);
      neverReached(appLocale);
    }
  }
}
