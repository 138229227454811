import { InspectionEntity } from 'serviceWorker/repository/inspection/entity';
import {
  InspectionTripletFilters,
  TripletSeriesWithLabels,
} from 'shared/types/analytics';
import {
  filterByCreatedBy,
  filterByDateRange,
  filterByProcess,
  filterInspectionBySite,
} from '../seriesCreation';
import { ComplianceCheckResult } from 'shared/domain/inspection/inspectionModel';

/**
 * Calculates inspection metrics based on completed inspections and compliance check results
 *
 * Score Formula:
 * 1. Completed inspections count
 * 2. Failed Items Ratio: [F / (T - N)] * 100%
 * 3. Score Formula: [((T - N) - F) / (T - N)] * 100%
 *
 * Where:
 * T = Total items checked during inspection (P + N + F)
 * N = Not applicable items
 * F = Failed/non-compliant items
 * P = Passed items
 *
 * The formulas exclude not applicable items (N) from calculations to ensure
 * the metrics reflect only items that were actually checked.
 *
 * Example:
 * If inspection has:
 * - 100 total items (T)
 * - 20 not applicable (N)
 * - 10 failed (F)
 *
 * Then:
 * Failed Ratio = [10 / (100 - 20)] * 100% = 12.5%
 * Score = [((100 - 20) - 10) / (100 - 20)] * 100% = 87.5%
 *
 * @returns calculated scores in ApexSeriesAndLabels format
 */
export async function createSeriesAndLabelsFromInspectionsWithFilters(
  inspections: InspectionEntity[],
  templateProcessByIdRecord: Record<string, { process: string }>,
  filters: InspectionTripletFilters,
  timezone: string
): Promise<TripletSeriesWithLabels> {
  const [dateRangeFilter, processFilter, siteFilter, createdByFilter] =
    filters.dataScope.filters;

  const protocolResultsObject = {
    [ComplianceCheckResult.passed]: 0,
    [ComplianceCheckResult.notPassed]: 0,
    [ComplianceCheckResult.notApplicable]: 0,
  };

  const filteredInspections = inspections.filter((inspection) => {
    return isInspectionValid(
      inspection,
      filters,
      timezone,
      templateProcessByIdRecord
    );
  });
  filteredInspections.forEach((inspection) => {
    inspection.protocol.forEach((protocolElement) => {
      if (
        protocolElement.complianceCheck.result ===
        ComplianceCheckResult.unset
      ) {
        return;
      }
      protocolResultsObject[protocolElement.complianceCheck.result] += 1;
    });
    return true;
  });

  const passed = protocolResultsObject[ComplianceCheckResult.passed];
  const notPassed = protocolResultsObject[ComplianceCheckResult.notPassed];

  const failedRatio = normalize(
    (notPassed / (passed + notPassed || 1)) * 100
  );
  const inspectionScore = normalize(
    (passed / (passed + notPassed || 1)) * 100
  );

  return [
    [
      { value: filteredInspections.length },
      { value: failedRatio, maxValue: 100 },
      { value: inspectionScore, maxValue: 100 },
    ],
    ['', '%', '%'],
  ];
}

function normalize(value: number): number {
  return Math.round((value + Number.EPSILON) * 100) / 100;
}

function isInspectionValid(
  inspection: InspectionEntity,
  filters: InspectionTripletFilters,
  timezone: string,
  templateProcessByIdRecord: Record<string, { process: string }>
): boolean {
  const [dateRangeFilter, processFilter, siteFilter, createdByFilter] =
    filters.dataScope.filters;
  if (!inspection.isCompleted) return false;
  if (!templateProcessByIdRecord[inspection.template]) {
    throw new Error(
      `Template _id:${inspection.template}; missing for inspection _id:${inspection._id}`
    );
  }

  return (
    filterByDateRange(
      dateRangeFilter.value,
      inspection.inspectionDate || inspection.createdAt,
      timezone
    ) &&
    filterByProcess(
      processFilter.value,
      templateProcessByIdRecord[inspection.template]
    ) &&
    filterInspectionBySite(siteFilter.value, inspection) &&
    filterByCreatedBy(createdByFilter.value, inspection)
  );
}
