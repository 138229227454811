import { fromDate14DaysEarlier, toDateToday } from 'charts/shared';
import { DateRangeInterval } from 'shared/types/analytics';
import {
  SetingsSaver,
  useSettingsSaver,
} from 'charts/shared/settingsSaver';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useSubcontractors } from 'charts/shared/hooks';
import { useSites } from 'components/dataProviders/withSites';
import { useVisibleFieldsState } from 'components/dataProviders/withVisibleFields';
import { useDispatch, useSelector } from 'react-redux';
import { processesSelector } from 'redux/selectors/processes';
import { StoreState } from 'setup/types/core';
import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import {
  StaticField,
  getAvailableFields,
} from 'shared/domain/visibleField/availableFields';
import { createAvailableFieldsSet } from 'shared/domain/visibleField/createAvailableFieldsSet';
import { TOASTER_TYPES } from 'shared/enums';
import { HashMap, LabelledEntity } from 'shared/types/commonView';
import { ProcessType } from 'shared/types/form';
import { DayMomentValidation } from 'shared/types/time';
import { shortISOStringToProjectTimezonedString } from 'shared/utils/date/dates';
import { ChartType } from 'shared/types/analytics';
import { showToaster } from '../../redux/actions/toasterActions';
import {
  createLabels,
  isDateDifferenceInvalid,
  setMultiselectFilterValue,
} from '../shared/filters';
import { PresentedTypes } from 'shared/types/analytics';
import {
  DetectedResolvedFilters,
  FilterTypes,
} from 'shared/types/analytics';
import { DateRangeObject } from 'shared/types/analytics';

export function useDetectedResolvedFilters(): DetectedResolvedFilters {
  const subcontractors = useSubcontractors();
  const timezone = useSelector((state: StoreState) => {
    return state.projectData.timezone;
  });
  const processes = useSelector(processesSelector);

  const {
    sites: { items: sites },
  } = useSites();
  const projectId = useSelector((state: StoreState) => {
    return state.projectData._id;
  });

  const dispatch = useDispatch();

  const showTooLargeIntervalErrorToast = useCallback(() => {
    dispatch(
      showToaster({
        type: TOASTER_TYPES.FAILURE,
        message: { id: 'date_range_interval_too_large_error' },
        toasterPosition: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        hideDelay: 6000,
      })
    );
  }, [dispatch]);

  const saver = useSettingsSaver(`${projectId}_detected_resolved`);
  const visibleFields = useVisibleFieldsState();
  const visibleFieldsSet = useMemo(
    () =>
      createAvailableFieldsSet(
        visibleFields,
        getAvailableFields().filter(
          (field) => !field.canBeDisabled
        ) as StaticField[],
        processes
      ),
    [visibleFields, processes]
  );

  const [filters, setFilters] = useState<DetectedResolvedFilters>();
  const defaultFilters = useMemo(() => {
    if (filters) return;
    return createFilters({
      setFilters: setFilters as Dispatch<
        SetStateAction<DetectedResolvedFilters>
      >,
      saver,
      subcontractors,
      processes,
      timezone,
      showTooLargeIntervalErrorToast,
      sites,
      visibleFieldsSet,
    });
  }, [
    setFilters,
    saver,
    subcontractors,
    processes,
    timezone,
    showTooLargeIntervalErrorToast,
    sites,
    visibleFieldsSet,
    filters,
  ]);

  useEffect(() => {
    setFilters(
      createFilters({
        setFilters: setFilters as Dispatch<
          SetStateAction<DetectedResolvedFilters>
        >,
        saver,
        subcontractors,
        processes,
        timezone,
        showTooLargeIntervalErrorToast,
        sites,
        visibleFieldsSet,
      })
    );
  }, [
    setFilters,
    saver,
    subcontractors,
    processes,
    timezone,
    showTooLargeIntervalErrorToast,
    sites,
    visibleFieldsSet,
  ]);

  return (filters || defaultFilters) as DetectedResolvedFilters;
}

function createFilters({
  setFilters,
  saver,
  subcontractors,
  processes,
  timezone,
  showTooLargeIntervalErrorToast,
  sites,
  visibleFieldsSet,
}: {
  setFilters: Dispatch<SetStateAction<DetectedResolvedFilters>>;
  saver: SetingsSaver;
  subcontractors: LabelledEntity[];
  processes: ProcessType[];
  timezone: string;
  showTooLargeIntervalErrorToast: CallableFunction;
  sites: LabelledEntity[];
  visibleFieldsSet: Set<string>;
}): DetectedResolvedFilters {
  return {
    chartSettings: {
      labelId: 'chart_settings_tab',
      filters: [
        {
          labelId: 'chart_type',
          type: FilterTypes.radio,
          available: [ChartType.bar, ChartType.line],
          labels: {
            [ChartType.bar]: 'chart_type_bar',
            [ChartType.line]: 'chart_type_line',
          },
          value: saver.get(`chart_settings_chart_type`) || ChartType.bar,
          setter: (value: ChartType): void => {
            saver.set(`chart_settings_chart_type`, value);
            setFilters((prev) => {
              const result = {
                ...prev,
                chartSettings: {
                  ...prev.chartSettings,
                  filters: [...prev.chartSettings.filters],
                },
              };
              result.chartSettings.filters[0] = {
                ...prev.chartSettings.filters[0],
                value: value,
              };
              return result;
            });
          },
        },
        {
          labelId: ' ',
          type: FilterTypes.checkbox,
          labels: {
            hasDataLabels: 'chart_settings_data_labels',
          },
          value: saver.getObject<{ hasDataLabels: boolean }>(
            `chart_settings_data_labels`
          ) || {
            hasDataLabels: true,
          },
          setter: (value: { hasDataLabels: boolean }): void => {
            saver.set(`chart_settings_data_labels`, value);
            setFilters((prev) => {
              const result = {
                ...prev,
                chartSettings: {
                  ...prev.chartSettings,
                  filters: [...prev.chartSettings.filters],
                },
              };
              result.chartSettings.filters[1] = {
                ...prev.chartSettings.filters[1],
                value: value,
              };
              return result;
            });
          },
        },
      ],
    },
    dataScope: {
      labelId: 'data_scope_tab',
      filters: [
        {
          labelId: 'data_scope_interval',
          type: FilterTypes.radio,
          available: Object.values(DateRangeInterval),
          labels: {
            [DateRangeInterval.day]: 'date_range_interval_day',
            [DateRangeInterval.week]: 'date_range_interval_week',
            [DateRangeInterval.month]: 'date_range_interval_month',
            [DateRangeInterval.year]: 'date_range_interval_year',
          },
          value:
            saver.get(`data_scope_interval`) || DateRangeInterval.month,
          toUrlQuery: (value: DateRangeInterval): string => {
            return value ? `&unit=${value}` : '';
          },
          setter: (value: DateRangeInterval): void => {
            setFilters((prev): DetectedResolvedFilters => {
              const { from, to } = prev.dataScope.filters[2]
                .value as DateRangeObject;

              if (isDateDifferenceInvalid(from, to, value, timezone)) {
                showTooLargeIntervalErrorToast();
                return prev;
              }

              const dateRangeValue = {
                from,
                to,
                interval: value,
              };

              saver.set(`data_scope_interval`, value);
              saver.set('data_scope_date_range', dateRangeValue);

              const result = {
                ...prev,
                dataScope: {
                  ...prev.dataScope,
                  filters: [...prev.dataScope.filters],
                },
              };
              result.dataScope.filters[0] = {
                ...prev.dataScope.filters[0],
                value: value,
              };
              result.dataScope.filters[2] = {
                ...prev.dataScope.filters[2],
                value: dateRangeValue,
              };
              return result;
            });
          },
        },
        {
          labelId: 'data_scope_originators',
          type: FilterTypes.multiselect,
          available: subcontractors,
          labels: createLabels(subcontractors),
          value:
            saver.getObject<string[]>(`data_scope_originators`) ||
            ([] as string[]),
          toUrlQuery: (value: string[]): string => {
            return value.length ? `&originator=[${value.join(',')}]` : '';
          },
          setter: (value: string[]): void => {
            if (!visibleFieldsSet.has(IssueFieldNames.subcontractors)) {
              value = [];
            }
            saver.set(`data_scope_originators`, value);
            setFilters(
              (prev): DetectedResolvedFilters =>
                setMultiselectFilterValue(prev, 1, value)
            );
          },
          fieldRelation: IssueFieldNames.subcontractors,
        },
        {
          labelId: 'data_scope_date_range',
          type: FilterTypes.daterange,
          value: saver.getObject<DateRangeObject>(
            'data_scope_date_range',
            (value) => ({
              from: value.from,
              to: value.to,
            })
          ) || {
            from: fromDate14DaysEarlier(timezone),
            to: toDateToday(timezone),
          },
          toUrlQuery: (value: DateRangeObject): string => {
            return value.from && value.to
              ? `&from=${shortISOStringToProjectTimezonedString(
                  value.from,
                  timezone,
                  DayMomentValidation.start
                ).replace(
                  '+',
                  '%2B'
                )}&to=${shortISOStringToProjectTimezonedString(
                  value.to,
                  timezone,
                  DayMomentValidation.end
                ).replace('+', '%2B')}`
              : '';
          },
          setter: (value: DateRangeObject): void => {
            setFilters((prev): DetectedResolvedFilters => {
              const { from, to } = value;
              const interval = prev.dataScope.filters[0]
                .value as DateRangeInterval;
              if (isDateDifferenceInvalid(from, to, interval, timezone)) {
                showTooLargeIntervalErrorToast();
                return prev;
              }

              saver.set('data_scope_date_range', value);
              const result = {
                ...prev,
                dataScope: {
                  ...prev.dataScope,
                  filters: [...prev.dataScope.filters],
                },
              };
              result.dataScope.filters[2] = {
                ...prev.dataScope.filters[2],
                value: value,
              };
              return result;
            });
          },
        },
        {
          labelId: 'general_process',
          type: FilterTypes.checkbox,
          labels: processes.reduce<HashMap<string>>((labels, process) => {
            labels[process._id] = process.label;
            return labels;
          }, {}),
          value:
            saver.getObject<HashMap<boolean>>('data_scope_processes') ||
            Object.fromEntries(
              Object.values(processes).map((process) => [
                process._id,
                true,
              ])
            ),
          toUrlQuery: (value: string[]): string => {
            const entries = Object.entries(value);
            return entries.length
              ? `&process=[${entries
                  .map(([key, value]) => (value ? key : ''))
                  .filter((x) => x)
                  .join(',')}]`
              : '';
          },
          setter: (value: HashMap<boolean>): void => {
            saver.set('data_scope_processes', value);
            setFilters((prev) => {
              const result = {
                ...prev,
                dataScope: {
                  ...prev.dataScope,
                  filters: [...prev.dataScope.filters],
                },
              };
              result.dataScope.filters[3] = {
                ...prev.dataScope.filters[3],
                value: { ...value },
              };
              return result;
            });
          },
        },
        {
          labelId: 'data_scope_sites',
          type: FilterTypes.multiselect,
          available: sites,
          labels: createLabels(sites),
          value:
            saver.getObject<string[]>('data_scope_sites') ||
            ([] as string[]),
          toUrlQuery: (value: string[]): string => {
            return value.length ? `&site=[${value.join(',')}]` : '';
          },
          setter: (value: string[]): void => {
            if (!visibleFieldsSet.has(IssueFieldNames.site)) {
              value = [];
            }
            saver.set('data_scope_sites', value);
            setFilters(
              (prev): DetectedResolvedFilters =>
                setMultiselectFilterValue(prev, 4, value)
            );
          },
          fieldRelation: IssueFieldNames.site,
        },
      ],
    },
    presented: {
      labelId: 'presented_tab',
      filters: [
        {
          labelId: 'presented_issue_type',
          type: FilterTypes.checkbox,
          labels: {
            [PresentedTypes.detected]: 'issue_type_detected',
            [PresentedTypes.resolved]: 'issue_type_resolved',
          },
          value:
            saver.getObject<Record<PresentedTypes, boolean>>(
              'presented_issue_types'
            ) ||
            (Object.fromEntries(
              Object.values(PresentedTypes).map((type) => [type, true])
            ) as Record<PresentedTypes, boolean>),
          setter: (value: Record<PresentedTypes, boolean>): void => {
            saver.set('presented_issue_types', value);
            setFilters((prev) => {
              return {
                ...prev,
                presented: {
                  ...prev.presented,
                  filters: [
                    {
                      ...prev.presented.filters[0],
                      value: { ...value },
                    },
                  ],
                },
              };
            });
          },
        },
      ],
    },
  };
}
