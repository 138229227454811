import { ColumnShapeFactory } from 'components/table/columnShapes';
import {
  ColumnKey,
  InitialColumnWidth,
} from 'components/table/columnShapes/types';
import { InspectionOnTableView } from 'presentation/inspection/inspectionOnView';
import { ColumnShape } from 'react-base-table';
import { IntlShape } from 'react-intl';
import { ColumnWidths } from 'setup/types/core';
import { HashMap } from 'shared/types/commonView';
import { ProcessType } from 'shared/types/form';
import { ISO6391LanguageCode } from 'shared/types/locale';

export class InspectionColumnsFactory extends ColumnShapeFactory {
  constructor(
    formatMessage: IntlShape['formatMessage'],
    columnWidths: ColumnWidths,
    private processes: HashMap<ProcessType>,
    private timezone: string,
    private locale: ISO6391LanguageCode
  ) {
    super(formatMessage, columnWidths);
  }
  create(): ColumnShape[] {
    return [
      this.orderNumber(),
      this.processCode(this.processes),
      this.text(
        {
          dataKey: 'template.summary',
          labelId: 'inspection_table_summary',
          initialWidth: InitialColumnWidth.large,
          accessor: (inspection: InspectionOnTableView) =>
            inspection.template.summary,
        },
        ColumnKey.summary
      ),
      this.inspectionStatus(),
      this.executionDate(this.timezone, this.locale),
      this.createdBy(),
      this.controlledParty(),
      this.workTypes(),
    ];
  }
}
