import { DocumentOutDto } from 'shared/dtos/out/document';

export enum DocumentTypes {
  file = 'FILE',
}

export type SharedDocumentDownloadData = {
  fileStructure: 'single';
  signedRequest: string;
  statusCode: number;
  _id: string;
  entityType: 'document';
  document: DocumentOutDto;
};
