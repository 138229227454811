import {
  DateRangeIntervalWithoutWeek,
  InspectionEvolutionFilters,
} from 'shared/types/analytics';
import {
  isDateDifferenceInvalid,
  setMultiselectFilterValue,
} from 'charts/shared/filters';
import { FilterAction } from '../types';
import {
  FilterActionType,
  FilterActionTypeInterval,
  RewriteFiltersActionEnum,
} from 'charts/shared/types';
import { showToaster } from 'redux/actions/toasterActions';
import { TOASTER_TYPES } from 'shared/enums';
import { Dispatch } from 'redux';

type InspectionEvolutionReducer = (
  state: InspectionEvolutionFilters | undefined,
  action: FilterAction
) => InspectionEvolutionFilters | undefined;
export function createInspectionEvolutionReducer(
  reduxDispatch: Dispatch,
  timezone: string
): InspectionEvolutionReducer {
  const showTooLargeIntervalErrorToast = (): void => {
    reduxDispatch(
      showToaster({
        type: TOASTER_TYPES.FAILURE,
        message: { id: 'date_range_interval_too_large_error' },
        toasterPosition: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        hideDelay: 6000,
      })
    );
  };

  return function filtersReducer(
    state: InspectionEvolutionFilters | undefined,
    action: FilterAction
  ): InspectionEvolutionFilters | undefined {
    if (action.type === RewriteFiltersActionEnum.rewriteFilters)
      return action.payload;
    if (!state) return undefined;

    switch (action.type) {
      case FilterActionTypeInterval.setDateInterval: {
        const { from, to } = state.dataScope.filters[1].value;

        if (isDateDifferenceInvalid(from, to, action.payload, timezone)) {
          showTooLargeIntervalErrorToast();
          return state;
        }

        const [first, ...rest] = state.dataScope.filters;
        return {
          ...state,
          dataScope: {
            ...state.dataScope,
            filters: [{ ...first, value: action.payload }, ...rest],
          },
        };
      }

      case FilterActionType.setDateRange: {
        const { from, to } = action.payload;
        const interval: DateRangeIntervalWithoutWeek =
          state.dataScope.filters[0].value;
        if (isDateDifferenceInvalid(from, to, interval, timezone)) {
          showTooLargeIntervalErrorToast();
          return state;
        }

        const [first, second, ...rest] = state.dataScope.filters;
        return {
          ...state,
          dataScope: {
            ...state.dataScope,
            filters: [
              first,
              {
                ...second,
                value: {
                  from: action.payload.from,
                  to: action.payload.to,
                },
              },
              ...rest,
            ],
          },
        };
      }

      case FilterActionType.setProcesses:
        return setMultiselectFilterValue(state, 2, action.payload);

      case FilterActionType.setSites:
        return setMultiselectFilterValue(state, 3, action.payload);

      case FilterActionType.setManagers:
        return setMultiselectFilterValue(state, 4, action.payload);
      default:
        const exhaustiveCheck: never = action;
        throw new Error(
          `Unhandled filters reducer case: ${exhaustiveCheck['type']}`
        );
    }
  };
}
