import {
  HttpRequestEntity,
  HttpRequestModelType,
  HttpRequestStatus,
} from 'shared/domain/httpRequest/httpRequestModel';
import {
  broadcastDocumentationDeletedError,
  broadcastDocumentationMovedError,
} from 'serviceWorker/broadcasts/documentations';
import {
  broadcastCreatedIssueError,
  broadcastUpdatedIssueError,
} from 'serviceWorker/broadcasts/issues';
import { broadcastUploadedLevelError } from 'serviceWorker/broadcasts/levels';
import { broadcastCreatedProjectError } from 'serviceWorker/broadcasts/project/error';
import { broadcastCreateReportError } from 'serviceWorker/broadcasts/reports';
import { broadcastUploadedSiteError } from 'serviceWorker/broadcasts/sites';
import { broadcastUploadedUserError } from 'serviceWorker/broadcasts/user/error';
import { broadcastUploadedVisibleFieldError } from 'serviceWorker/broadcasts/visibleFields';
import { debugLog } from 'shared/logger/debugLog';
import { neverReached } from 'shared/utils/neverReached';
import { broadcastCompanyError } from '../responseHandlers/company';
import { broadcastContractError } from '../responseHandlers/contract';
import { broadcastOrganizationError } from '../responseHandlers/organization';
import { broadcastDocumentationError } from './documentation';

export async function emitErrorOnProperChannel(
  request: HttpRequestEntity,
  error: unknown
): Promise<void> {
  switch (request.entityType) {
    case HttpRequestModelType.issue: {
      return broadcastIssueError(request, error);
    }
    case HttpRequestModelType.drawing:
    case HttpRequestModelType.document: {
      return broadcastDocumentError(request, error);
    }
    case HttpRequestModelType.report: {
      return broadcastReportError(request, error);
    }
    case HttpRequestModelType.documentation: {
      return broadcastDocumentationError(request, error);
    }
    case HttpRequestModelType.documentationMove: {
      return broadcastMoveDocumentationError(request, error);
    }
    case HttpRequestModelType.documentationDelete: {
      return broadcastDeleteDocumentationError(request, error);
    }
    case HttpRequestModelType.level: {
      return broadcastLevelError(request, error);
    }
    case HttpRequestModelType.site: {
      return broadcastSiteError(request, error);
    }
    case HttpRequestModelType.directory: {
      return;
    }
    case HttpRequestModelType.visibleField: {
      return broadcastVisibleFieldError(request, error);
    }
    case HttpRequestModelType.project: {
      return broadcastProjectError(request, error);
    }
    case HttpRequestModelType.organization: {
      return broadcastOrganizationError(request, error);
    }
    case HttpRequestModelType.company: {
      return broadcastCompanyError(request, error);
    }
    case HttpRequestModelType.user: {
      return broadcastUserError(request, error);
    }
    case HttpRequestModelType.contract: {
      return broadcastContractError(request, error);
    }
    default:
      // ensure every case is handled when we add new type.
      neverReached(request.entityType);
  }
}

function broadcastDocumentError(
  request: HttpRequestEntity,
  error: any
): void {
  debugLog('document error', error?.message, error?.stack);
}

function broadcastReportError(
  request: HttpRequestEntity,
  error: unknown
): void {
  if (request.status === HttpRequestStatus.FAILED) {
    broadcastCreateReportError(error, undefined, request.data);
  }
}

function broadcastLevelError(
  request: HttpRequestEntity,
  error: any
): void {
  broadcastUploadedLevelError(error);
}

function broadcastSiteError(request: HttpRequestEntity, error: any): void {
  broadcastUploadedSiteError(error);
}

function broadcastMoveDocumentationError(
  request: HttpRequestEntity,
  error: any
): void {
  if (request.method === 'PUT') {
    broadcastDocumentationMovedError(error);
  }
}

function broadcastDeleteDocumentationError(
  request: HttpRequestEntity,
  error: any
): void {
  if (request.method === 'DELETE') {
    broadcastDocumentationDeletedError(error);
  }
}

export function broadcastIssueError(
  request: HttpRequestEntity,
  error: unknown
): void {
  if (request.method === 'POST') {
    broadcastCreatedIssueError(error);
  } else {
    broadcastUpdatedIssueError(error);
  }
}

function broadcastVisibleFieldError(
  request: HttpRequestEntity,
  error: any
): void {
  broadcastUploadedVisibleFieldError(error);
}

function broadcastProjectError(
  request: HttpRequestEntity,
  error: any
): void {
  broadcastCreatedProjectError(error);
}

function broadcastUserError(request: HttpRequestEntity, error: any): void {
  broadcastUploadedUserError(error);
}
