import React from 'react';
import { Layout, Spinner, Toaster } from 'components/core';
import { useAuth0 } from 'services/auth0/react-auth0.spa';
import { DownloadWithToken } from 'components/report/download/downloadWithToken';
import { WithSharedResourceDownload } from 'components/common/withSharedResourceDownload/withSharedResourceDownload';
import { Box } from '@mui/material';

export function DocumentDownloadView(): React.ReactElement {
  const { isAuthenticated, loading: auth0ClientLoading } = useAuth0();

  const component = auth0ClientLoading ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
      }}
    >
      <Spinner
        position='static'
        reason='DocumentDownloadView auth0ClientLoading'
      />
    </Box>
  ) : (
    <DownloadWithToken />
  );

  if (isAuthenticated) {
    return (
      <WithSharedResourceDownload entityType='document'>
        <Layout>{component}</Layout>
      </WithSharedResourceDownload>
    );
  }

  return (
    <WithSharedResourceDownload entityType='document'>
      {component}
      <Toaster />
    </WithSharedResourceDownload>
  );
}
