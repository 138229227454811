import { InspectionModel } from 'shared/domain/inspection/inspectionModel';
import { inspectionEntityToModel } from 'serviceWorker/repository/inspection/inspectionEntityToModel';
import * as inspections from 'serviceWorker/db/inspections';
import * as inspectionsService from 'serviceWorker/db/inspectionsService';
import { awaitEntities } from '../factories/awaitEntities';
import { getUsersSynchronized } from '../users/getUsers';
import { waitForInspections } from './waitForInspections';

export async function getInspectionById(
  id: string
): Promise<InspectionModel> {
  await awaitEntities(inspectionsService, waitForInspections);
  const [inspectionEntity, users] = await Promise.all([
    inspections.getOne(id),
    getUsersSynchronized(),
  ]);
  if (!inspectionEntity) {
    throw new Error(`Cannot find inspection: ${id}`);
  }
  return inspectionEntityToModel(inspectionEntity, users);
}
