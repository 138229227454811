import { debugLog } from 'shared/logger/debugLog';
import {
  AppLocaleOnView,
  ISO6391LanguageCode,
  LANGUAGE_LABELS,
} from 'shared/types/locale';
import { anyToSupportedISO6391LanguageCode } from './anyToSupportedISO6391LanguageCode';

export function anyToAppLocaleOnView(locale: any): AppLocaleOnView {
  const code = anyToSupportedISO6391LanguageCode(locale);

  switch (code) {
    case ISO6391LanguageCode.en:
      return {
        _id: ISO6391LanguageCode.en,
        label: LANGUAGE_LABELS.en,
      };
    case ISO6391LanguageCode.ar:
      return {
        _id: ISO6391LanguageCode.ar,
        label: LANGUAGE_LABELS.ar,
      };
    case ISO6391LanguageCode.bg:
      return {
        _id: ISO6391LanguageCode.bg,
        label: LANGUAGE_LABELS.bg,
      };
    case ISO6391LanguageCode.hr:
      return {
        _id: ISO6391LanguageCode.hr,
        label: LANGUAGE_LABELS.hr,
      };
    case ISO6391LanguageCode.cs:
      return {
        _id: ISO6391LanguageCode.cs,
        label: LANGUAGE_LABELS.cs,
      };
    case ISO6391LanguageCode.fr:
      return {
        _id: ISO6391LanguageCode.fr,
        label: LANGUAGE_LABELS.fr,
      };
    case ISO6391LanguageCode.de:
      return {
        _id: ISO6391LanguageCode.de,
        label: LANGUAGE_LABELS.de,
      };
    case ISO6391LanguageCode.he:
      return {
        _id: ISO6391LanguageCode.he,
        label: LANGUAGE_LABELS.he,
      };
    case ISO6391LanguageCode.hu:
      return {
        _id: ISO6391LanguageCode.hu,
        label: LANGUAGE_LABELS.hu,
      };
    case ISO6391LanguageCode.nb:
      return {
        _id: ISO6391LanguageCode.nb,
        label: LANGUAGE_LABELS.nb,
      };
    case ISO6391LanguageCode.pl:
      return {
        _id: ISO6391LanguageCode.pl,
        label: LANGUAGE_LABELS.pl,
      };
    case ISO6391LanguageCode.pt:
      return {
        _id: ISO6391LanguageCode.pt,
        label: LANGUAGE_LABELS.pt,
      };
    case ISO6391LanguageCode.ro:
      return {
        _id: ISO6391LanguageCode.ro,
        label: LANGUAGE_LABELS.ro,
      };
    case ISO6391LanguageCode.ru:
      return {
        _id: ISO6391LanguageCode.ru,
        label: LANGUAGE_LABELS.ru,
      };
    case ISO6391LanguageCode.sk:
      return {
        _id: ISO6391LanguageCode.sk,
        label: LANGUAGE_LABELS.sk,
      };
    case ISO6391LanguageCode.es:
      return {
        _id: ISO6391LanguageCode.es,
        label: LANGUAGE_LABELS.es,
      };
    case ISO6391LanguageCode.tr:
      return {
        _id: ISO6391LanguageCode.tr,
        label: LANGUAGE_LABELS.tr,
      };
    case ISO6391LanguageCode.uk:
      return {
        _id: ISO6391LanguageCode.uk,
        label: LANGUAGE_LABELS.uk,
      };
    default:
      debugLog(`Unknown locale code: ${locale}`);
      return {
        _id: ISO6391LanguageCode.en,
        label: LANGUAGE_LABELS.en,
      };
  }
}
