import { DefinedDependencies } from 'charts/shared/types';
import {
  InspectionEvolutionDependencies,
  FilterActionCalls,
} from './types';
import {
  InspectionEvolutionFilters,
  FilterTypes,
  DateRangeIntervalWithoutWeek,
} from 'shared/types/analytics';
import { sanitizeDateFilterValue } from 'charts/shared/model';
import { LabelledEntity } from 'shared/types/commonView';
import { createLabels } from 'charts/shared/filters';
import { Process } from 'shared/types/process';

export function createFiltersForView(
  {
    filters,
    managers,
    processes,
    sites,
    timezone,
  }: DefinedDependencies<
    InspectionEvolutionDependencies,
    InspectionEvolutionFilters
  >,
  {
    setDateInterval,
    setDateRange,
    setManagers,
    setProcesses,
    setSites,
  }: Pick<
    FilterActionCalls,
    | 'setDateInterval'
    | 'setDateRange'
    | 'setProcesses'
    | 'setSites'
    | 'setManagers'
  >
): InspectionEvolutionFilters {
  return {
    dataScope: {
      labelId: 'data_scope_tab',
      filters: [
        {
          labelId: 'data_scope_interval',
          type: FilterTypes.radio,
          available: Object.values(DateRangeIntervalWithoutWeek),
          labels: {
            [DateRangeIntervalWithoutWeek.day]: 'date_range_interval_day',
            [DateRangeIntervalWithoutWeek.month]:
              'date_range_interval_month',
            [DateRangeIntervalWithoutWeek.year]:
              'date_range_interval_year',
          },
          value: DateRangeIntervalWithoutWeek.month,
          setter: (value: DateRangeIntervalWithoutWeek): void => {
            setDateInterval(value);
          },
        },
        {
          labelId: 'data_scope_date_range',
          type: FilterTypes.daterange,
          value: {
            from: sanitizeDateFilterValue(
              filters.dataScope.filters[1].value.from,
              timezone
            ),
            to: sanitizeDateFilterValue(
              filters.dataScope.filters[1].value.to,
              timezone
            ),
          },
          setter: (value): void => {
            setDateRange(value);
          },
        },
        {
          labelId: 'general_process',
          type: FilterTypes.multiselect,
          available: processes
            .filter((process: LabelledEntity) => {
              return process._id !== Process.TSK;
            })
            .map((process: LabelledEntity) => {
              return { _id: process._id, label: process.label };
            }),
          labels: createLabels(processes),
          value: filters.dataScope.filters[2].value,
          setter: (value: string[]): void => {
            setProcesses(value);
          },
        },
        {
          labelId: 'data_scope_sites',
          type: FilterTypes.multiselect,
          available: sites,
          labels: createLabels(sites),
          value: filters.dataScope.filters[3].value,
          setter: (value: string[]): void => {
            setSites(value);
          },
        },
        {
          labelId: 'inspection_table_author',
          type: FilterTypes.multiselect,
          available: managers,
          labels: createLabels(managers),
          value: filters.dataScope.filters[4].value,
          setter: (value: string[]): void => {
            setManagers(value);
          },
        },
      ],
    },
  };
}
