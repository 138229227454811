import { useAtLeastManagers } from 'charts/shared/hooks';
import { useSites } from 'components/dataProviders/withSites';
import { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { projectDataSelector } from 'redux/selectors/project';
import {
  InspectionEvolutionFilters,
  InspectionChart,
} from 'shared/types/analytics';
import { useMountedRef } from 'hooks/useMountRef';
import { createInspectionEvolutionReducer } from './reducers/filtersReducer';
import { useFiltersPersistence } from 'charts/shared/useFiltersPersistence';
import { defaultValues } from './defaultFilters';
import { useDependencies } from './hooks/useDependencies';
import { createFiltersForView } from './createFilters';
import { useFiltersActions } from 'charts/shared/filtersActions';

export function useInspectionEvolutionFilters():
  | InspectionEvolutionFilters
  | undefined {
  const mountedRef = useMountedRef();
  const reduxDispatch = useDispatch();
  const managers = useAtLeastManagers();
  const {
    _id: projectId,
    timezone,
    processes: userProcesses,
  } = useSelector(projectDataSelector);

  const {
    sites: { items: sites },
  } = useSites();

  const [filters, dispatchFilters] = useReducer(
    createInspectionEvolutionReducer(reduxDispatch, timezone),
    undefined
  );
  const { rewriteFilters, ...filtersActions } =
    useFiltersActions(dispatchFilters);

  const { savedFilters } = useFiltersPersistence(
    `${projectId}_${InspectionChart.inspectionEvolution}`,
    filters,
    projectId,
    defaultValues
  );

  const { dependencies, isDependenciesDefined } = useDependencies(
    managers,
    userProcesses,
    sites,
    projectId,
    timezone,
    savedFilters
  );

  useEffect(() => {
    if (!mountedRef.current || !userProcesses.length) return;

    if (isDependenciesDefined(dependencies)) {
      rewriteFilters(createFiltersForView(dependencies, filtersActions));
    }
  }, [
    dependencies,
    userProcesses.length,
    isDependenciesDefined,
    rewriteFilters,
  ]);

  return filters;
}
