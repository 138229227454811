import { ApexOptions } from 'apexcharts';
import { ReactElement } from 'react';
import { ChartType, TripletSeries } from 'shared/types/analytics';
import { TripletPartChart } from './TripletPartChart';
import { useIntl } from 'react-intl';
import {
  chartTextStyle,
  chartWrapperStyle,
  numberWrapperStyle,
  wrapperStyle,
} from './style';

const tripletStyle = {
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'repeat(3, 2fr 0.5fr)',
} as const;

export function VerticalTripletChart({
  series,
  options,
}: {
  series: TripletSeries;
  options: ApexOptions;
}): ReactElement {
  const intl = useIntl();
  return (
    <div style={wrapperStyle}>
      <div id='chart' style={tripletStyle}>
        <div style={numberWrapperStyle}>
          <span>
            {series[0].value}
            {options.labels?.[0]}
          </span>
        </div>
        <div style={chartTextStyle}>
          {intl.formatMessage({ id: 'completed_inspections_chart' })}
        </div>
        <div style={chartWrapperStyle}>
          <TripletPartChart
            color='#FDB22B'
            chartType={ChartType.radialBar}
            index={1}
            isMobile={true}
            options={options}
            series={series}
          />
        </div>
        <div style={chartTextStyle}>
          {intl.formatMessage({ id: 'failed_inspection_factor_chart' })}
        </div>
        <div style={chartWrapperStyle}>
          <TripletPartChart
            color='#288AE2'
            chartType={ChartType.radialBar}
            index={2}
            isMobile={true}
            options={options}
            series={series}
          />
        </div>
        <div style={chartTextStyle}>
          {intl.formatMessage({ id: 'average_inspection_result_chart' })}
        </div>
      </div>
    </div>
  );
}
