import { IssueOnSingleView } from 'components/dataProviders/withIssue/IssueOnSingleView';
import { generateNcrNumber } from 'helpers/generators';
import { toLabelledEntity, toUnsafeLabelledEntity } from 'helpers/misc';
import { CompanyModel } from 'shared/domain/company/types/model';
import { ContractModel } from 'shared/domain/contract/types/model';
import {
  incomingEmailMessageModelToOnView,
  issueEventModeltoOnView,
} from 'shared/domain/issueEvent/mapping/toView';
import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import { LevelModel } from 'shared/domain/level/types/model';
import { SiteModel } from 'shared/domain/site/types/model';
import { modelToViewBase } from 'shared/domain/toBaseView';
import { userWithPersonalDataToOptionalLabelled } from 'shared/domain/user/mapping/toView';
import { UserModel } from 'shared/domain/user/types/model';
import { HashMap, LabelledEntity } from 'shared/types/commonView';
import { FieldSkeleton, ProcessType } from 'shared/types/form';
import { toViewDate } from 'shared/utils/date/toViewDate';
import { LegacyIssueForm } from 'views/issue/issueView/UnifiedIssueViewContainer/types';
import { IssueModel } from '../issueModel';

function findField(
  form: LegacyIssueForm,
  fieldType: 'extendedFields' | 'primaryFields',
  fieldName: string
): FieldSkeleton | undefined {
  return form[fieldType].find((field) => field.name === fieldName);
}

function findValue(
  fieldSkeleton: FieldSkeleton | undefined,
  valueToFind: string | undefined | null
): LabelledEntity | undefined {
  if (!fieldSkeleton || !valueToFind) return undefined;
  return (fieldSkeleton.items || []).find(
    (item) => item._id === valueToFind
  );
}

function findValues(
  fieldSkeleton: FieldSkeleton | undefined,
  valuesToFind: string[] | undefined | null
): LabelledEntity[] {
  if (!fieldSkeleton || !valuesToFind) return [];
  const map =
    (fieldSkeleton.items || []).reduce<HashMap<LabelledEntity>>(
      (result, item) => {
        result[item._id] = item;
        return result;
      },
      {} as HashMap<LabelledEntity>
    ) || {};

  return valuesToFind.map((value) => map[value]).filter((x) => x);
}

export function issueModelToOnSingleView(
  issueModel: IssueModel,
  currentUserId: string,
  sites: SiteModel[],
  levels: LevelModel[],
  processes: ProcessType[],
  issueForms: HashMap<LegacyIssueForm>,
  subcontractors: HashMap<CompanyModel>,
  contractNumbers: HashMap<ContractModel>,
  users: UserModel[],
  timezone: string,
  organizationId: string
): IssueOnSingleView {
  const issueProcess = processes.find(
    ({ _id }) => _id === issueModel.process
  );
  const issueSite = sites.find(
    ({ _id }) => _id === issueModel.primaryData.site
  );
  const issueLevel = levels.find(
    ({ _id }) => _id === issueModel.primaryData.level
  );

  // @ts-ignore cannot use undefined as index
  const issueForm: LegacyIssueForm = issueForms[issueProcess?._id] || {
    primaryFields: [],
    extendedFields: [],
  };

  const ncrNumber = generateNcrNumber(
    issueModel.createdAt,
    issueProcess?.code ?? '',
    issueSite?.code ?? '',
    issueModel._id.slice(-4)
  );
  const onViewBase = modelToViewBase(issueModel, organizationId);
  return {
    ...onViewBase,
    events: !issueModel.events
      ? []
      : issueModel.events?.map((event) =>
          issueEventModeltoOnView(
            event,
            currentUserId,
            users,
            organizationId
          )
        ),
    incomingEmailMessages: !issueModel.incomingEmailMessages
      ? []
      : issueModel.incomingEmailMessages?.map((message) =>
          incomingEmailMessageModelToOnView(message, organizationId)
        ),
    extendedData: {
      effect: findValue(
        findField(issueForm, 'extendedFields', 'effect'),
        issueModel.extendedData.effect
      ),
      environmentalAspect: findValue(
        findField(issueForm, 'extendedFields', 'environmentalAspect'),
        issueModel.extendedData.environmentalAspect
      ),
      estimatedCost: issueModel.extendedData.estimatedCost
        ? issueModel.extendedData.estimatedCost?.map((costObject) => {
            const found = subcontractors[costObject.coveredBy as string];
            return {
              cost: costObject.cost,
              coveredBy: found
                ? { _id: found._id, label: found.shortLabel }
                : undefined,
            };
          })
        : undefined,
      expectedFine: issueModel.extendedData.expectedFine,
      finalCompletionDate: toViewDate(
        timezone,
        issueModel.extendedData.finalCompletionDate
      ),
      finalCost: issueModel.extendedData.finalCost
        ? issueModel.extendedData.finalCost?.map((costObject) => {
            const found = subcontractors[costObject.coveredBy as string];
            return {
              outstanding: costObject.outstanding,
              settled: costObject.settled,
              coveredBy: found
                ? { _id: found._id, label: found.shortLabel }
                : undefined,
            };
          })
        : undefined,
      hazardCategory: findValues(
        findField(issueForm, 'extendedFields', 'hazardCategory'),
        issueModel.extendedData.hazardCategory
      ),
      impact: findValue(
        findField(issueForm, 'extendedFields', 'impact'),
        issueModel.extendedData.impact
      ),
      imposedFine: issueModel.extendedData.imposedFine,
      rootCauses: findValues(
        findField(issueForm, 'extendedFields', 'rootCauses'),
        issueModel.extendedData.rootCauses
      ),
      solutionProposal: issueModel.extendedData.solutionProposal,
      targetCompletionDate: toViewDate(
        timezone,
        issueModel.extendedData.targetCompletionDate
      ),
      workTypes: findValues(
        findField(issueForm, 'extendedFields', IssueFieldNames.workTypes),
        issueModel.extendedData.workTypes
      ),
      circumstances: findValue(
        findField(issueForm, 'extendedFields', 'circumstances'),
        issueModel.extendedData.circumstances
      ),
      contaminatedSoilScale: issueModel.extendedData.contaminatedSoilScale,
      costCode: issueModel.extendedData.costCode,
      daysOfInabilityToWork: issueModel.extendedData.daysOfInabilityToWork,
      decisionToImposeFine: findValue(
        findField(issueForm, 'extendedFields', 'decisionToImposeFine'),
        issueModel.extendedData.decisionToImposeFine
      ),
      personUnableToWork: issueModel.extendedData.personUnableToWork,
      proposedCorrectiveAction: findValue(
        findField(issueForm, 'extendedFields', 'proposedCorrectiveAction'),
        issueModel.extendedData.proposedCorrectiveAction
      ),
      soilLeakageScale: issueModel.extendedData.soilLeakageScale,
      solutionMethod: issueModel.extendedData.solutionMethod,
      spilledSubstance: findValue(
        findField(issueForm, 'extendedFields', 'spilledSubstance'),
        issueModel.extendedData.spilledSubstance
      ),
      subcontractorRepresentative:
        issueModel.extendedData.subcontractorRepresentative,
      waterLeakageScale: issueModel.extendedData.waterLeakageScale,
      targetStartDate: toViewDate(
        timezone,
        issueModel.extendedData.targetStartDate
      ),
      finalStartDate: toViewDate(
        timezone,
        issueModel.extendedData.finalStartDate
      ),
      targetAmount: issueModel.extendedData.targetAmount,
      completedAmount: issueModel.extendedData.completedAmount,
      amountPercentage: issueModel.extendedData.amountPercentage,
      executedByCompanies: !issueModel.extendedData.executedByCompanies
        ? []
        : issueModel.extendedData.executedByCompanies
            ?.map((company) => {
              const found = subcontractors[company];
              return found && { _id: found._id, label: found.shortLabel };
            })
            .filter((x) => x),
      startDateDelay: issueModel.extendedData.startDateDelay,
      completionDateDelay: issueModel.extendedData.completionDateDelay,
      numberOfEmployees: issueModel.extendedData.numberOfEmployees,
    },
    hashtag: issueModel.hashtag,
    inspection: issueModel.inspection,
    primaryData: {
      site: toLabelledEntity(issueSite!),
      title: issueModel.primaryData.title,
      assignee: userWithPersonalDataToOptionalLabelled(
        issueModel.primaryData.assignee,
        organizationId
      ),
      description: issueModel.primaryData.description,
      detectionDate: toViewDate(
        timezone,
        issueModel.primaryData.detectionDate
      ),
      subcontractors: !issueModel.primaryData.subcontractors
        ? []
        : issueModel.primaryData.subcontractors
            ?.map((subcontractor) => {
              const found = subcontractors[subcontractor];
              return found && { _id: found._id, label: found.shortLabel };
            })
            .filter((x) => x),
      contractNumbers: !issueModel.primaryData.contractNumbers
        ? []
        : (issueModel.primaryData.contractNumbers
            ?.map((contract) =>
              toUnsafeLabelledEntity(contractNumbers[contract])
            )
            .filter((x) => x) as LabelledEntity[]),
      level: toLabelledEntity(issueLevel!),
      positionOnMap: issueModel.primaryData.positionOnMap,
      executor: userWithPersonalDataToOptionalLabelled(
        issueModel.primaryData.executor,
        organizationId
      ),
      selectedLocationType: issueModel.primaryData.selectedLocationType,
      targetAreas: issueModel.primaryData.targetAreas || [],
      finalAreas: issueModel.primaryData.finalAreas || [],
    },
    process: toLabelledEntity(issueProcess!),
    protocolItem: issueModel.protocolItem,
    stage: issueModel.stage,
    userAccesses: issueModel.userAccesses,
    mainImage: issueModel.mainImage,
    emailAddress: issueModel.emailAddress,
    ncrNumber,
  };
}
