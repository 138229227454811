import { ApexOptions } from 'apexcharts';
import { ReactElement } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ChartType, TripletSeries } from 'shared/types/analytics';
type Props = {
  index: number;
  chartType: ChartType;
  isMobile: boolean;
  options: ApexOptions;
  series: TripletSeries;
  color: string;
};

export function TripletPartChart({
  index,
  chartType,
  isMobile,
  options,
  series,
  color,
}: Props): ReactElement | null {
  const value = series[index].value;
  if (value === undefined) return null;

  return (
    <div id={`triplet-chart-part-${index}`}>
      <ReactApexChart
        key={`${index},${chartType},${isMobile},${JSON.stringify(
          options
        )},${JSON.stringify(series)}`}
        options={{
          fill: {
            colors: [color],
          },
          labels: [`${value}%`],
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  color: '#000',
                  show: true,
                  offsetY: 6,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          chart: {
            fontFamily: 'Archivo, Arial',
            redrawOnParentResize: true,
            toolbar: {
              show: false,
            },
            type: ChartType.radialBar,
          },
          legend: {
            show: false,
          },
          stroke: {
            width: 0,
          },
        }}
        series={[value]}
        type={ChartType.radialBar}
        height='300px'
      />
    </div>
  );
}
