import { EventDescriptionPartModel } from 'shared/domain/issue/issueModel';
import { EventDescriptionType } from 'shared/types/eventDescription';

const makeField = (
  formValues: any,
  fieldTypes: string[],
  fieldType: 'extendedFields' | 'primaryFields'
): any => {
  return Object.assign(
    {},
    ...(fieldTypes[fieldType as any] as any)
      .filter(
        (field: any) =>
          typeof formValues[field] === 'number' || !!formValues[field]
      )
      .map((prop: any) => ({ [prop]: formValues[prop] }))
  );
};

export const parseEventDescription = (
  markup: string
): EventDescriptionPartModel[] => {
  const descriptionParts: EventDescriptionPartModel[] = [];

  const insertMentionAndCutMarkup = (_id: any, endIndex: any): void => {
    const mentionPart = {
      type: EventDescriptionType.mention,
      user: _id,
    };

    descriptionParts.push(mentionPart);

    markup = markup.slice(endIndex);
  };

  const insertTextAndCutMarkup = (text: any, endIndex: any): void => {
    const textPart = {
      text,
      type: EventDescriptionType.text,
    };

    descriptionParts.push(textPart);

    markup = markup.slice(endIndex);
  };

  while (markup !== '') {
    const occurenceOfMention = markup.match(
      /(@\[)(.+?)(]\()(currentUser:|otherUser:)(.+?)\)/
    );

    if (occurenceOfMention) {
      const mentionPosition = occurenceOfMention.index;

      if (mentionPosition === 0) {
        // if we have mention right at the beginning, insert it as a mention
        const userId = occurenceOfMention[5];
        const label = occurenceOfMention[2];
        const mentionLength = occurenceOfMention[0].length;

        insertMentionAndCutMarkup(userId, mentionLength);
      } else {
        // if we have mention a bit later, insert the part before it as a text
        const textToBeInserted = markup.slice(0, mentionPosition);

        insertTextAndCutMarkup(textToBeInserted, mentionPosition);
      }
    } else {
      // no mention found so insert the remaining markup as a text
      insertTextAndCutMarkup(markup, markup.length);
    }
  }

  return descriptionParts;
};
