import {
  ChartFiltersUnion,
  DateRangeInterval,
  DateRangeIntervalWithoutWeek,
} from 'shared/types/analytics';
import { HashMap, LabelledEntity } from 'shared/types/commonView';
import { AnyFilters, ChartFilter } from 'shared/types/analytics';
import { FilterTypes } from 'shared/types/analytics';
import { shortISOStringToProjectTimezonedDate } from 'shared/utils/date/dates';
import { DayMomentValidation } from 'shared/types/time';

export function setMultiselectFilterValue<F extends ChartFiltersUnion>(
  prevFilters: F,
  filterIndex: number,
  value: string[]
): F {
  return setMultiselect(prevFilters, filterIndex, value, 'value');
}

function setMultiselect<F extends ChartFiltersUnion>(
  prevFilters: F,
  filterIndex: number,
  value: string[] | LabelledEntity[],
  key: string
): F {
  if (!prevFilters) return prevFilters;
  const result = {
    ...prevFilters,
    dataScope: {
      ...prevFilters.dataScope,
      filters: [...prevFilters.dataScope.filters],
    },
  };
  result.dataScope.filters[filterIndex] = {
    ...prevFilters.dataScope.filters[filterIndex],
    [key]: [...value],
  };
  return result;
}

export function createLabels(array: LabelledEntity[]): HashMap<string> {
  return array.reduce((labels: HashMap<string>, element) => {
    labels[element._id] = element.label;
    return labels;
  }, {});
}

export function clearFilterByFieldVisibility<T>(
  visibleFields: Set<string>,
  filters: AnyFilters | undefined
): AnyFilters<T> | undefined {
  if (!filters) return filters;
  filters.dataScope.filters.forEach((filter: ChartFilter<any>) => {
    if (!filter.fieldRelation) return;

    if (!visibleFields.has(filter.fieldRelation)) {
      clearByType(filter);
    }
  });
  return filters;
}

function clearByType(filter: ChartFilter<any>): void {
  switch (filter.type) {
    case FilterTypes.multiselect: {
      filter.value = [];
      break;
    }
    case FilterTypes.checkbox: {
      filter.value = {};
      break;
    }
    case FilterTypes.select:
    case FilterTypes.radio: {
      filter.value = null;
      break;
    }
  }
}

export function isDateDifferenceInvalid(
  dateFrom: string,
  dateTo: string,
  interval: DateRangeInterval | DateRangeIntervalWithoutWeek,
  timezone: string
): boolean {
  const intervalString = toDurationObjectUnits(interval);
  const startL = shortISOStringToProjectTimezonedDate(
    dateFrom,
    timezone,
    DayMomentValidation.start
  );
  const endL = shortISOStringToProjectTimezonedDate(
    dateTo,
    timezone,
    DayMomentValidation.end
  );
  const difference = endL.diff(startL, intervalString);

  return difference[intervalString] > 366;
}

function toDurationObjectUnits(
  dateRange: DateRangeInterval | DateRangeIntervalWithoutWeek
): 'days' | 'months' | 'years' | 'weeks' {
  return (dateRange + 's') as 'days' | 'months' | 'years' | 'weeks';
}
