import { Dispatch, useCallback, useMemo } from 'react';
import { SiteModel } from 'shared/domain/site/types/model';
import { FilterValues } from 'shared/types/analytics';
import { LabelledEntity } from 'shared/types/commonView';
import { ProcessType } from 'shared/types/form';
import { InspectionTemplateModel } from 'shared/domain/template/inspectionTemplateModel';
import {
  DependeciesActionEnum,
  DependeciesSetFiltersActionEnum,
} from 'charts/shared/types';

type DependenciesActionCalls = {
  setFiltersResponse: (value: FilterValues) => void;
  setProcesses: (value: ProcessType[]) => void;
  setSites: (value: SiteModel[]) => void;
  setTimezone: (value: string) => void;
  setProjectId: (value: string) => void;
  setManagers: (value: LabelledEntity[]) => void;
  setTemplates: (value: InspectionTemplateModel[]) => void;
};

type BaseActions =
  | {
      readonly type: DependeciesActionEnum;
      payload: unknown;
    }
  | {
      readonly type: DependeciesSetFiltersActionEnum;
      payload: FilterValues;
    };
export function useDependenciesActions(
  dispatch: Dispatch<BaseActions>
): DependenciesActionCalls {
  const setFiltersResponse = useCallback(
    (value: FilterValues) => {
      dispatch({
        type: DependeciesSetFiltersActionEnum.setFiltersResponse,
        payload: value,
      });
    },
    [dispatch]
  );

  const setProcesses = useCallback(
    (value: ProcessType[]) => {
      dispatch({
        type: DependeciesActionEnum.setProcesses,
        payload: value,
      });
    },
    [dispatch]
  );

  const setSites = useCallback(
    (value: SiteModel[]) => {
      dispatch({ type: DependeciesActionEnum.setSites, payload: value });
    },
    [dispatch]
  );

  const setProjectId = useCallback(
    (value: string) => {
      dispatch({
        type: DependeciesActionEnum.setProjectId,
        payload: value,
      });
    },
    [dispatch]
  );
  const setTimezone = useCallback(
    (value: string) => {
      dispatch({
        type: DependeciesActionEnum.setTimezone,
        payload: value,
      });
    },
    [dispatch]
  );
  const setManagers = useCallback(
    (value: LabelledEntity[]) => {
      dispatch({
        type: DependeciesActionEnum.setManagers,
        payload: value,
      });
    },
    [dispatch]
  );
  const setTemplates = useCallback(
    (value: InspectionTemplateModel[]) => {
      dispatch({
        type: DependeciesActionEnum.setTemplates,
        payload: value,
      });
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      setManagers,
      setTimezone,
      setProcesses,
      setProjectId,
      setFiltersResponse,
      setSites,
      setTemplates,
    }),
    []
  );
}
