import { ApexOptions } from 'apexcharts';
import { Dispatch, SetStateAction } from 'react';
import {
  ApexAxisChartSeries,
  DateRangeIntervalWithoutWeek,
  StrictDateRange,
} from 'shared/types/analytics';
import { FilterValues } from 'shared/types/analytics';

// taken directly from ApexOptions
export type ApexToolbarOptions = {
  show?: boolean;
  offsetX?: number;
  offsetY?: number;
  tools?: {
    download?: boolean | string;
    selection?: boolean | string;
    zoom?: boolean | string;
    zoomin?: boolean | string;
    zoomout?: boolean | string;
    pan?: boolean | string;
    reset?: boolean | string;
    customIcons?: {
      icon?: string;
      title?: string;
      index?: number;
      class?: string;
      click?(chart?: any, options?: any, e?: any): any;
    }[];
  };
  export?: {
    csv?: {
      filename?: undefined | string;
      columnDelimiter?: string;
      headerCategory?: string;
      headerValue?: string;
      dateFormatter?(timestamp?: number): any;
    };
    svg?: {
      filename?: undefined | string;
    };
    png?: {
      filename?: undefined | string;
    };
  };
  autoSelected?: 'zoom' | 'selection' | 'pan';
};

type ApexNonAxisChartSeries = number[];

export type GenericChartState = {
  series:
    | ApexAxisChartSeries
    | ApexNonAxisChartSeries
    | [ApexAxisChartSeries, ApexAxisChartSeries];
  options: ApexOptions;
};
export type TypedChartState<T> = {
  series: T;
  options: ApexOptions;
};

export type GetChart<T> = {
  series: T;
  loading: boolean;
  load: () => void;
  reload: () => void;
  updatedAt: number;
};

export type DependencyKeys =
  | 'filters'
  | 'assignees'
  | 'subcontractors'
  | 'processes'
  | 'sites'
  | 'impacts'
  | 'workTypes'
  | 'projectId'
  | 'timezone'
  | 'setFilters'
  | 'envProcessId'
  | 'hsProcessId'
  | 'templates';

export type Dependencies<T, K> = { [key in keyof T]?: T[key] } & {
  setFilters?: Dispatch<SetStateAction<K | undefined>>;
} & { filters?: FilterValues };

export type DefinedDependencies<T, K> = Required<Dependencies<T, K>>;

export enum DependeciesSetFiltersActionEnum {
  setFiltersResponse = 'SET_FILTERS_RESPONSE',
}
export enum DependeciesActionEnum {
  setProcesses = 'SET_PROCESSES',
  setSites = 'SET_SITES',
  setProjectId = 'SET_PROJECT_ID',
  setTimezone = 'SET_TIMEZONE',
  setManagers = 'SET_MANAGERS',
  setTemplates = 'SET_TEMPLATES',
}

export enum RewriteFiltersActionEnum {
  rewriteFilters = 'REWRITE_FILTERS',
}
export enum FilterActionType {
  setDateRange = 'SET_DATE_RANGE',
  setProcesses = 'SET_PROCESSES',
  setSites = 'SET_SITES',
  setManagers = 'SET_MANAGERS',
}
export enum FilterActionTypeInterval {
  setDateInterval = 'SET_DATE_INTERVAL',
}
export type RewriteFiltersAction<TFilters> = {
  type: RewriteFiltersActionEnum.rewriteFilters;
  payload: TFilters;
};
export type SetDateRangeAction = {
  type: FilterActionType.setDateRange;
  payload: StrictDateRange;
};
export type SetProcessesAction = {
  type: FilterActionType.setProcesses;
  payload: string[];
};
export type SetSitesAction = {
  type: FilterActionType.setSites;
  payload: string[];
};
export type SetManagersAction = {
  type: FilterActionType.setManagers;
  payload: string[];
};
export type SetDateIntervalAction = {
  type: FilterActionTypeInterval.setDateInterval;
  payload: DateRangeIntervalWithoutWeek;
};
