import { DocumentModel } from 'shared/domain/document/documentModel';
import { DocumentEntity } from './entity';

export function documentEntityToModel(
  documentEntity: DocumentEntity
): DocumentModel {
  return {
    _id: documentEntity._id,
    localId: documentEntity.localId,
    title: documentEntity.title,
    data: documentEntity.data,
    description: documentEntity.description,
    deleted: documentEntity.deleted,
    modifiedAt: documentEntity.modifiedAt,
    localData: documentEntity.localData,
    type: documentEntity.data?.type || documentEntity.type || 'FILE',
    issueId: documentEntity.issueId,
    inspectionId: documentEntity.inspectionId,
    checklistId: documentEntity.checklistId,
    eventId: documentEntity.eventId,
    protocolId: documentEntity.protocolId,
    templateId: documentEntity.templateId,
    incomingEmailMessageId: documentEntity.incomingEmailMessageId,
    syncStatus: documentEntity.syncStatus,
    drawingSyncStatus: documentEntity.drawingSyncStatus,
  };
}
