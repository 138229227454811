export enum RepositoryMessagesTypes {
  logout = 'logout',
  clearData = 'clearData',
  configSet = 'configSet',
  dataFlushed = 'dataFlushed',
  syncIssues = 'syncIssues',
  getIssueList = 'getIssueList',
  getIssue = 'getIssue',
  addIssue = 'addIssue',
  editIssue = 'editIssue',
  deleteIssue = 'deleteIssue',

  getStateFiltered = 'getStateFiltered',

  syncCompanies = 'syncCompanies',
  getCompany = 'getCompany',
  addCompany = 'addCompany',
  editCompany = 'editCompany',
  deleteCompany = 'deleteCompany',
  restoreCompany = 'restoreCompany',

  syncContracts = 'syncContracts',
  getContract = 'getContract',
  addContract = 'addContract',
  editContract = 'editContract',

  permissions = 'permissions',
  permissionsChanged = 'permissionsChanged',

  syncUsers = 'syncUsers',
  getUser = 'getUser',
  addUser = 'addUser',
  editUser = 'editUser',
  deleteUser = 'deleteUser',

  calculateAnalytics = 'calculateAnalytics',
  getChart = 'getChart',
  getChartFilters = 'getChartFilters',
  setChartFilters = 'setChartFilters',
  getFinancialData = 'getFinancialData',

  getProject = 'getProject',
  addProject = 'addProject',
  editProject = 'editProject',
  deleteProject = 'deleteProject',
  restoreProject = 'restoreProject',

  projectDataFlushed = 'projectDataFlushed',

  selectedProjectSet = 'selectedProjectSet',
  getSelectedProject = 'getSelectedProject',
  clearSelectedProjectData = 'clearSelectedProjectData',

  getOrganization = 'getOrganization',
  organization = 'organization',
  addOrganization = 'addOrganization',
  editOrganization = 'editOrganization',
  getAllOrganizations = 'getAllOrganizations',

  syncInspectionTemplates = 'syncInspectionTemplates',

  newRequest = 'newRequest',

  syncInspections = 'syncInspections',

  languageChange = 'languageChange',

  getDocuments = 'getDocuments',

  getDocumentUrls = 'getDocumentUrls',
  addDocument = 'addDocument',
  editDocument = 'editDocument',
  deleteDocument = 'deleteDocument',

  addDrawing = 'addDrawing',

  getSite = 'getSite',
  syncSites = 'syncSites',
  addSite = 'addSite',
  editSite = 'editSite',
  deleteSite = 'deleteSite',
  restoreSite = 'restoreSite',

  getReportSignedRequest = 'getReportSignedRequest',
  addReport = 'addReport',

  syncWorktypes = 'syncWorktypes',
  syncHazardCategories = 'syncHazardCategories',
  syncEnvironmentalAspects = 'syncEnvironmentalAspects',
  syncCorrectiveActionTypes = 'syncCorrectiveActionTypes',

  createFieldValues = 'createFieldValues',
  editFieldValues = 'editFieldValues',
  syncLevels = 'syncLevels',
  addLevel = 'addLevel',
  editLevel = 'editLevel',
  deleteLevel = 'deleteLevel',
  restoreLevel = 'restoreLevel',

  syncDocumentations = 'syncDocumentations',
  allDocumentations = 'allDocumentations',
  addDocumentation = 'addDocumentation',
  editDocumentation = 'editDocumentation',

  searchedDocumentations = 'searchedDocumentations',

  syncDirectories = 'syncDirectories',
  addDirectory = 'addDirectory',
  getDirectory = 'getDirectory',
  editDirectory = 'editDirectory',

  deleteDocumentations = 'deleteDocumentations',
  setParent = 'setParent',
  abortUpload = 'abortUpload',

  syncIssueForm = 'syncIssueForm',

  syncVisibleFields = 'syncVisibleFields',
  editVisibleFields = 'editVisibleFields',

  // in future it should be removed
  allUsers = 'allUsers',
  getAllUsers = 'getAllUsers',
  getAllProjects = 'getAllProjects',

  getInspectionChart = 'getInspectionChart',
}
