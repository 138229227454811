import { DocumentModel } from 'shared/domain/document/documentModel';
import { addToHashMap } from 'helpers/mappings';
import { modifiedAtSort } from 'helpers/sort';
import { DocumentOnView } from 'presentation/document/documentOnView';
import { DocumentsOnIssueView } from 'presentation/document/documentsOnIssueView';
import { HashMap } from 'shared/types/commonView';
import { documentModelToDocumentOnView } from './documentModelToDocumentOnView';
import { isDocumentRemoteAndUnavailable } from './documentRemoteAndUnavailable';

export function documentsToDocumentsOnIssueView(
  documents: DocumentModel[]
): DocumentsOnIssueView {
  const issueDocuments: DocumentOnView[] = [];
  const eventDocuments: HashMap<DocumentOnView[]> = {};
  const incomingEmailMessageDocuments: HashMap<DocumentOnView[]> = {};

  documents.sort(modifiedAtSort).forEach((document) => {
    if (document.deleted) {
      return;
    }

    if (isDocumentRemoteAndUnavailable(document)) {
      return;
    }

    const documentOnIssueView = documentModelToDocumentOnView(document);
    if (!documentOnIssueView) return;

    if (document.eventId) {
      addToHashMap(eventDocuments, documentOnIssueView, document.eventId);
    } else if (document.incomingEmailMessageId) {
      addToHashMap(
        incomingEmailMessageDocuments,
        documentOnIssueView,
        document.incomingEmailMessageId
      );
    } else {
      issueDocuments.push(documentOnIssueView);
    }
  });

  return {
    issueDocuments,
    eventDocuments,
    incomingEmailMessageDocuments,
  };
}
