import { HsEffectChartContext } from './types';
import { ApexAxisChartSeries } from 'shared/types/analytics';
import { useGetChart } from '../shared/hooks';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ChartDetailsContext } from '../../views/analytics/chartDetails/ChartDetailsProvider';
import { useHsEffectFilters } from './filters';
import { GenericChartState } from '../shared/types';
import {
  detailedViewHsEffectChartOptions,
  listViewHsEffectChartOptions,
} from './options';
import { createCategories } from './seriesCreation';
import { ChartType } from 'shared/types/analytics';
import { customAccessFunction } from '../shared/access';
import { useIntl } from 'react-intl';
import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import { useIssueForm } from 'components/common/withIssueForm';
import { useProcessesWithField } from 'charts/shared/useProcessesWithField';
import { IssueChart } from 'shared/types/analytics';

export function useHsEffect(): HsEffectChartContext {
  const processesWithHsEffect = useProcessesWithField(
    IssueFieldNames.effect
  );
  const access = useCallback(() => {
    return Boolean(processesWithHsEffect.length);
  }, [processesWithHsEffect]);
  const intl = useIntl();

  const filters = useHsEffectFilters(processesWithHsEffect);
  const additionalParams = useMemo(
    () => ({
      visibleProcesses: processesWithHsEffect.map(
        (process) => process._id
      ),
    }),
    [processesWithHsEffect]
  );

  const { series, loading, load, reload, updatedAt } = useGetChart<
    [ApexAxisChartSeries, []]
  >(IssueChart.hsEffect, filters, additionalParams);

  const isLoading = !filters || loading;

  const isDetailedView = useContext(ChartDetailsContext);

  const options = useMemo(() => {
    return isDetailedView
      ? detailedViewHsEffectChartOptions(intl)
      : listViewHsEffectChartOptions(intl);
  }, [intl, isDetailedView]);

  const { issueForm } = useIssueForm();

  const effectFieldValues = useMemo(() => {
    if (processesWithHsEffect.length) {
      const found = issueForm.find(
        (field) => field.name === IssueFieldNames.effect
      );
      if (found) return found.items;
      return undefined;
    }
  }, [issueForm, processesWithHsEffect]);

  const [state, setState] = useState<GenericChartState>({
    series: [],
    options,
  });

  useEffect(() => {
    if (!effectFieldValues) {
      return;
    }

    setState((prev: GenericChartState): GenericChartState => {
      return {
        ...prev,
        series: series[0],
        options: {
          ...options,
          xaxis: {
            ...prev.options.xaxis,
            categories: createCategories(effectFieldValues, intl),
          },
        },
      };
    });
  }, [series, effectFieldValues, intl, options]);

  return {
    state,
    filters,
    titleId: 'hs_effect_chart',
    chartType: ChartType.bar,
    availableTypes: [ChartType.bar],
    canAccess: customAccessFunction(access),
    loading: isLoading,
    load: load,
    forceUpdate: reload,
    updatedAt: (): number => updatedAt,
  };
}
