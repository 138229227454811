import { ApexOptions } from 'apexcharts';
import { sharedDonutChartOptions } from '../shared/options/donutChart';

export const detailedViewInspectionTripletChartOptions: ApexOptions = {
  ...sharedDonutChartOptions,
  chart: {
    fontFamily: 'Archivo, Arial',
    toolbar: undefined,
    redrawOnParentResize: true,
  },
};
