import { ColumnShape } from 'react-base-table';
import { ColumnWidths } from '../../../setup/types/core';

import { IntlShape } from 'react-intl/src/types';
import { HashMap } from 'shared/types/commonView';
import { ProcessType } from 'shared/types/form';
import { ISO6391LanguageCode } from 'shared/types/locale';
import {
  getCorrespondenceCountryColumnShapeSkeleton,
  getRegisteredCountryColumnShapeSkeleton,
} from './skeletons/address/country';
import {
  getCorrespondencePostalCodeColumnShapeSkeleton,
  getRegisteredPostalCodeColumnShapeSkeleton,
} from './skeletons/address/postalCode';
import {
  getCorrespondenceStreetAndNumberColumnShapeSkeleton,
  getRegisteredStreetAndNumberColumnShapeSkeleton,
} from './skeletons/address/streetAndNumber';
import { getControlledPartyColumnShapeSkeleton } from './skeletons/controlledParty';
import { getEstimatedCostColumnShapeSkeleton } from './skeletons/cost/estimated/estimatedCost';
import { getFinalCostOustandingColumnShapeSkeleton } from './skeletons/cost/final/finalCostOutstanding';
import { getFinalCostSettledColumnShapeSkeleton } from './skeletons/cost/final/finalCostSettled';
import {
  getCreatedAtColumnShapeSkeleton,
  getInspectionExecutionDateColumnShapeSkeleton,
  getModifiedAtColumnShapeSkeleton,
} from './skeletons/date';
import { getExpectedFineColumnShapeSkeleton } from './skeletons/fine/expectedFine';
import { getImposedFineColumnShapeSkeleton } from './skeletons/fine/imposedFine';
import { getInspectionStatusColumnShapeSkeleton } from './skeletons/inspectionStatus';
import {
  TextColumnParams,
  getCompanyLongLabelColumnShapeSkeleton,
  getCompanyShortLabelColumnShapeSkeleton,
  getContractLabelColumnShapeSkeleton,
  getRoleColumnShapeSkeleton,
  getTextColumnShape,
  getUserLabelColumnShapeSkeleton,
} from './skeletons/label';
import { getOrderNumberColumnShapeSkeleton } from './skeletons/orderNumber';
import { getProcessColumnShapeSkeleton } from './skeletons/process';
import { getProjectInvolvementTypeColumnShapeSkeleton } from './skeletons/projectInvolvementType';
import { getSitesColumnShapeSkeleton } from './skeletons/sites';
import { getTaxNumberColumnShapeSkeleton } from './skeletons/taxNumber';
import {
  getCreatedByColumnShapeSkeleton,
  getInspectionAuthorColumnShapeSkeleton,
} from './skeletons/user';
import { getWorktypesColumnShapeSkeleton } from './skeletons/workTypes';
import { ColumnKey, ColumnShapeSkeleton } from './types';
// TODO https://hustro.atlassian.net/browse/PT-5126
export abstract class ColumnShapeFactory {
  constructor(
    formatMessage: IntlShape['formatMessage'],
    columnWidths: ColumnWidths
  ) {
    this.formatMessage = formatMessage;
    this.columnWidths = columnWidths;
  }
  abstract create(): ColumnShape[];

  orderNumber(): ColumnShape {
    return this.mapSkeletonToShape(
      getOrderNumberColumnShapeSkeleton(),
      ColumnKey.no
    );
  }
  estimatedCost(): ColumnShape {
    return this.mapSkeletonToShape(
      getEstimatedCostColumnShapeSkeleton(),
      ColumnKey.estimatedCost
    );
  }
  finalCostSettled(): ColumnShape {
    return this.mapSkeletonToShape(
      getFinalCostSettledColumnShapeSkeleton(),
      ColumnKey.finalCostSettled
    );
  }
  finalCostOutstanding(): ColumnShape {
    return this.mapSkeletonToShape(
      getFinalCostOustandingColumnShapeSkeleton(),
      ColumnKey.finalCostOutstanding
    );
  }
  correspondenceAddressStreetAndNumber(): ColumnShape {
    return this.mapSkeletonToShape(
      getCorrespondenceStreetAndNumberColumnShapeSkeleton(),
      ColumnKey.correspondenceAddressStreetAndNumber
    );
  }
  registeredAddressStreetAndNumber(): ColumnShape {
    return this.mapSkeletonToShape(
      getRegisteredStreetAndNumberColumnShapeSkeleton(),
      ColumnKey.registeredAddressStreetAndNumber
    );
  }
  correspondenceAddressPostalCode(): ColumnShape {
    return this.mapSkeletonToShape(
      getCorrespondencePostalCodeColumnShapeSkeleton(),
      ColumnKey.correspondenceAddressPostalCode
    );
  }
  registeredAddressPostalCode(): ColumnShape {
    return this.mapSkeletonToShape(
      getRegisteredPostalCodeColumnShapeSkeleton(),
      ColumnKey.registeredAddressPostalCode
    );
  }
  correspondenceAddressCountry(): ColumnShape {
    return this.mapSkeletonToShape(
      getCorrespondenceCountryColumnShapeSkeleton(),
      ColumnKey.correspondenceAddressCountry
    );
  }
  registeredAddressCountry(): ColumnShape {
    return this.mapSkeletonToShape(
      getRegisteredCountryColumnShapeSkeleton(),
      ColumnKey.registeredAddressCountry
    );
  }
  createdAt(timezone: string, locale: ISO6391LanguageCode): ColumnShape {
    return this.mapSkeletonToShape(
      getCreatedAtColumnShapeSkeleton(timezone, locale),
      ColumnKey.createdAt
    );
  }
  modifiedAt(timezone: string, locale: ISO6391LanguageCode): ColumnShape {
    return this.mapSkeletonToShape(
      getModifiedAtColumnShapeSkeleton(timezone, locale),
      ColumnKey.modifiedAt
    );
  }
  companyShortLabel(): ColumnShape {
    return this.mapSkeletonToShape(
      getCompanyShortLabelColumnShapeSkeleton(),
      ColumnKey.shortLabel
    );
  }
  companyLongLabel(): ColumnShape {
    return this.mapSkeletonToShape(
      getCompanyLongLabelColumnShapeSkeleton(),
      ColumnKey.longLabel
    );
  }
  contractLabel(): ColumnShape {
    return this.mapSkeletonToShape(
      getContractLabelColumnShapeSkeleton(),
      ColumnKey.label
    );
  }
  createdBy(): ColumnShape {
    return this.mapSkeletonToShape(
      getCreatedByColumnShapeSkeleton(),
      ColumnKey.createdBy
    );
  }
  expectedFine(): ColumnShape {
    return this.mapSkeletonToShape(
      getExpectedFineColumnShapeSkeleton(),
      ColumnKey.expectedFine
    );
  }
  imposedFine(): ColumnShape {
    return this.mapSkeletonToShape(
      getImposedFineColumnShapeSkeleton(),
      ColumnKey.imposedFine
    );
  }
  sites(): ColumnShape {
    return this.mapSkeletonToShape(
      getSitesColumnShapeSkeleton(),
      ColumnKey.sites
    );
  }
  taxNumber(): ColumnShape {
    return this.mapSkeletonToShape(
      getTaxNumberColumnShapeSkeleton(),
      ColumnKey.taxNumber
    );
  }
  projectInvolvementType(): ColumnShape {
    return this.mapSkeletonToShape(
      getProjectInvolvementTypeColumnShapeSkeleton(),
      ColumnKey.projectInvolvementType
    );
  }
  processCode(processes: HashMap<ProcessType>): ColumnShape {
    return this.mapSkeletonToShape(
      getProcessColumnShapeSkeleton(processes),
      ColumnKey.process
    );
  }
  inspectionStatus(): ColumnShape {
    return this.mapSkeletonToShape(
      getInspectionStatusColumnShapeSkeleton(),
      ColumnKey.status
    );
  }
  executionDate(
    timezone: string,
    locale: ISO6391LanguageCode
  ): ColumnShape {
    return this.mapSkeletonToShape(
      getInspectionExecutionDateColumnShapeSkeleton(timezone, locale),
      ColumnKey.executionDate
    );
  }
  inspectionAuthor(): ColumnShape {
    return this.mapSkeletonToShape(
      getInspectionAuthorColumnShapeSkeleton(),
      ColumnKey.author
    );
  }
  controlledParty(): ColumnShape {
    return this.mapSkeletonToShape(
      getControlledPartyColumnShapeSkeleton(),
      ColumnKey.controlledParty
    );
  }
  workTypes(): ColumnShape {
    return this.mapSkeletonToShape(
      getWorktypesColumnShapeSkeleton(),
      ColumnKey.workTypes
    );
  }
  userLabel(): ColumnShape {
    return this.mapSkeletonToShape(
      getUserLabelColumnShapeSkeleton(),
      ColumnKey.label
    );
  }
  role(): ColumnShape {
    return this.mapSkeletonToShape(
      getRoleColumnShapeSkeleton(),
      ColumnKey.role
    );
  }
  text(params: TextColumnParams, columnKey: ColumnKey): ColumnShape {
    return this.mapSkeletonToShape(getTextColumnShape(params), columnKey);
  }
  private formatMessage: IntlShape['formatMessage'];
  private columnWidths: ColumnWidths;

  private mapSkeletonToShape(
    skeleton: ColumnShapeSkeleton,
    columnKey: ColumnKey
  ): ColumnShape {
    return {
      dataKey: skeleton.dataKey,
      cellRenderer: skeleton.cellRenderer,
      headerRenderer: skeleton.headerRenderer,
      flexGrow: skeleton.flexGrow,
      flexShrink: skeleton.flexShrink,
      sortable: skeleton.sortable,
      sortType: skeleton.sortType,
      accessor: skeleton.accessor,
      style: skeleton.style,
      key: columnKey,
      _id: columnKey,
      resizable: true,
      width: this.columnWidths[columnKey] || skeleton.initialWidth,
      label:
        this.formatMessage({ id: skeleton.labelId }) || skeleton.labelId,
      mergeGroup: skeleton.mergeGroup && {
        _id: skeleton.mergeGroup._id,
        label: this.formatMessage({
          id: skeleton.mergeGroup.labelId,
        }),
      },
    };
  }
}
