import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { sumCosts as sumEstimatedCosts } from 'components/common/forms/fields/EstimatedCosts/helpers';
import { sumCosts as sumFinalCosts } from 'components/common/forms/fields/FinalCosts/helpers';
import { IssueOnSingleView } from 'components/dataProviders/withIssue/IssueOnSingleView';
import { makeDateAccessor } from 'components/table/accessors';
import { displayLocalizedDate } from 'components/table/renderers/cell/dates';
import { ColumnValue } from 'components/table/types';
import { arrayFilterOutDuplicates } from 'helpers/misc';
import React from 'react';
import BaseTable, { ColumnShape, TableComponents } from 'react-base-table';
import { createSelector } from 'reselect';
import { logError } from 'setup/logger/logError';
import { ColumnWidths, StoreState } from 'setup/types/core';
import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import {
  Field,
  StaticField,
} from 'shared/domain/visibleField/availableFields';
import { VisibleFieldModel } from 'shared/domain/visibleField/visibleFieldModel';
import { SORT_TYPE, SortingOrder } from 'shared/filter/sort/types';
import { HashMap, Labelled, Named } from 'shared/types/commonView';
import {
  ExpectedDataType,
  FieldSkeleton,
  InputType,
  ProcessType,
} from 'shared/types/form';
import { separateFields } from 'views/issue/issueView/UnifiedIssueViewContainer/generateForm';
import { IssueTableColumn } from './TableController';
import {
  AccessorCreator,
  ExtractFieldData,
  IssueData,
  fixedColumnsIds,
} from './types';
import { ISO6391LanguageCode } from 'shared/types/locale';
const costFieldTypes = [
  IssueFieldNames.finalCost.toString(),
  IssueFieldNames.estimatedCost.toString(),
];

export const isCostField = (fieldType: string): boolean =>
  costFieldTypes.includes(fieldType);

const DATE_FIELD_WIDTH = 280;
const MIN_FIELD_WORD_COUNT = 6;
const GENERAL_WORD_WIDTH = 9.6;
const SORT_PLACEHOLDER_WIDTH = 32;

const displayNumber = (number: number): string => {
  return number.toString();
};

export const formatters: HashMap<
  (
    value: any,
    timezone: string,
    locale: ISO6391LanguageCode
  ) => ColumnValue
> = {
  [InputType.datePicker]: displayLocalizedDate,
  [InputType.numberField]: displayNumber,
};

export const createCostFieldAccessor = (
  finalParser?: (arg: any) => string | number | null
): AccessorCreator => {
  const accessors: HashMap<Function> = {
    finalCost: sumFinalCosts,
    estimatedCost: sumEstimatedCosts,
  };
  return (data, field: FieldSkeleton): ColumnValue => {
    const accessor = accessors[field.inputType];
    if (!accessor) {
      return null;
    }
    const value = data[field.name as keyof IssueData];
    if (!value) {
      return null;
    }
    return finalParser ? finalParser(accessor(value)) : accessor(value);
  };
};

export const createDataGetter: AccessorCreator = (data, field) => {
  if (field.inputType === InputType.datePicker) {
    return data[field.name as keyof IssueData]; //return data 'as is', it is a date string
  } else {
    return createAccessor(data, field);
  }
};

export const createAccessor: AccessorCreator = (data, field) => {
  const parseIdWithCost: AccessorCreator = (data): any => {
    return data;
  };

  const parseIdWithSettledAndOutstandingCost: AccessorCreator = (
    data
  ): any => {
    return data;
  };

  const parseLabelledEntity: AccessorCreator = (
    data,
    field
  ): null | string => {
    const fieldValue = (data as HashMap<unknown>)[field.name];
    if (!fieldValue) {
      return null;
    }

    if (Array.isArray(fieldValue)) {
      return fieldValue.map((value: Labelled) => value.label).join(', ');
    }

    return (fieldValue as Labelled)?.label;
  };

  const parseString: AccessorCreator = (data, field): string => {
    return String((data as HashMap<unknown>)[field.name]) ?? '';
  };

  const accessors: HashMap<AccessorCreator> = {
    idWithCost: parseIdWithCost,
    idWithSettledAndOutstandingCost: parseIdWithSettledAndOutstandingCost,
    idWithLabel: parseLabelledEntity,
    string: parseString,
    date: (): number | null =>
      makeDateAccessor(data[field.name as keyof IssueData]),
    number: (): null | string =>
      data[field.name as keyof IssueData] ?? null,
  };

  const customAccessor =
    isCostField(field.inputType) && createCostFieldAccessor();

  const newAccessor = customAccessor || accessors[field.expectedDataType];
  if (!newAccessor) {
    const errorMessage = `Accessor ${field.expectedDataType} not found`;
    logError(errorMessage);
    throw new Error(errorMessage);
  }
  return newAccessor(data, field);
};

const columnsFixedWidths: HashMap<number> = {
  title: 250,
  site: 260,
};

export const getInitialWidth = (
  field: Pick<FieldSkeleton, 'expectedDataType' | 'name' | 'label'>
): number => {
  const widthByDataType = getWidthByDataType(field);

  return (
    columnsFixedWidths[field.name] ??
    Math.max(
      Math.ceil(field.label.length * GENERAL_WORD_WIDTH) +
        SORT_PLACEHOLDER_WIDTH,
      widthByDataType
    )
  );
};

const getWidthByLongestWord = (
  field: Pick<FieldSkeleton, 'label'>
): number => {
  const strings = field.label
    .split(' ')
    .sort((a, b) => (a.length > b.length ? -1 : 1));

  const longest = strings[0];

  return (
    Math.ceil(longest.length * GENERAL_WORD_WIDTH) + SORT_PLACEHOLDER_WIDTH
  );
};

const getWidthByDataType = (
  field: Pick<FieldSkeleton, 'expectedDataType'>
): number => {
  return field.expectedDataType === ExpectedDataType.date
    ? DATE_FIELD_WIDTH
    : MIN_FIELD_WORD_COUNT * GENERAL_WORD_WIDTH;
};

export const getColumnMinWidth = (
  field: Pick<FieldSkeleton, 'expectedDataType' | 'label'>
): number => {
  const widthByDataType = getWidthByDataType(field);
  const widthByWords = getWidthByLongestWord(field);

  return Math.max(widthByDataType, widthByWords);
};

export const getColumnIndex = (columnFor: string): number => {
  const columnIndexes: HashMap<number> = {
    title: 2,
    site: 4,
    level: 5,
    detectionDate: 7,
    finalCompletionDate: 8,
    issueDelay: 9,
    estimatedCost: 10,
    finalCost: 11,
  };

  return columnIndexes[columnFor] ?? 200;
};

type ColumnStylesType = {
  style: {
    justifyContent: 'center';
  };
};

type ColumnSortType = {
  sortType: SORT_TYPE;
};

export const getColumnSortType = (
  column: FieldSkeleton
): ColumnSortType | {} => {
  const numberSort = [
    column.expectedDataType === 'date',
    column.inputType === InputType.datePicker,
    column.expectedDataType === InputType.numberField,
  ];

  return {
    ...(column.expectedDataType !== InputType.numberField && {
      sortType: SORT_TYPE.STRING,
    }),
    ...(numberSort.some((p) => p) && {
      sortType: SORT_TYPE.NUMBERS,
    }),
  };
};

export const getSortIcon = (
  container: BaseTable,
  column: ColumnShape
): React.ReactElement | null => {
  const { key, order } = container?.props.sortBy ?? {
    key: undefined,
    order: undefined,
  };

  return key === column?.id || key === column.key ? (
    order === SortingOrder.desc ? (
      <ExpandLessIcon />
    ) : (
      <ExpandMoreIcon />
    )
  ) : null;
};

export const shouldRenderNotApplicable = (
  issue: IssueOnSingleView,
  columns: ColumnShape<IssueOnSingleView>[],
  columnName: string
): boolean => {
  const issueProcess = issue.process._id;
  const columnProcesses = columns.find(
    (col) => col._id === columnName
  )?.processIds;

  return !columnProcesses.includes(issueProcess);
};

export const getProcessesWithField = (
  fieldName: string,
  processes: ProcessType[],
  visibleFields: HashMap<VisibleFieldModel[]>,
  availableFields: HashMap<Field>
): string[] => {
  if (
    availableFields[fieldName] &&
    !availableFields[fieldName].canBeDisabled
  ) {
    return (availableFields[fieldName] as StaticField).processes;
  }

  return processes
    .filter((process) => {
      if (!visibleFields[process._id]) {
        return false;
      }
      return visibleFields[process._id].some(
        (field) => field.fieldName === fieldName
      );
    })
    .map((process) => process._id);
};

const Cell: TableComponents['TableCell'] = (
  props
): React.ReactElement | null => {
  const { cellData } = props;

  if (!cellData) {
    return null;
  }

  return <span>{cellData}</span>;
};

const Dummy = (): JSX.Element => <></>;

export const components = {
  TableCell: Cell,
  SortIndicator: Dummy,
};

const columnWidths = (state: StoreState): ColumnWidths | undefined =>
  state.ui.issueView.columnWidths;

const columnWidthsSelector = createSelector(columnWidths, (f) => f);

const fieldExtractAndClean = <T extends Named>(
  init: T[],
  additional: T[],
  filter: (value: T) => boolean
): T[] => {
  const filtered = additional.filter(filter);
  const newSet = [...init, ...filtered];
  return arrayFilterOutDuplicates(newSet, 'name');
};

const nonFilterablePrimaryFields: string[] = [
  IssueFieldNames.documents,
  IssueFieldNames.positionOnMap,
];

export const extractFieldData: ExtractFieldData = (
  processes,
  issueForm,
  duplicates
) => {
  let pri: FieldSkeleton[] = [];
  let ext: FieldSkeleton[] = [];

  processes.forEach((process) => {
    if (!issueForm) {
      return;
    }
    const { primaryFields, extendedFields } = separateFields(issueForm);

    const primaryFieldFilter = (field: Named): boolean =>
      !nonFilterablePrimaryFields.includes(field.name);
    const extendedFieldFilter = (field: Labelled): boolean =>
      !duplicates.includes(field.label);

    pri = fieldExtractAndClean(pri, primaryFields, primaryFieldFilter);
    ext = fieldExtractAndClean(ext, extendedFields, extendedFieldFilter);
  });

  return { pri, ext };
};

const sortingSelector = (
  state: StoreState
): { headerName: string; sorting: SortingOrder } | undefined =>
  state.ui.issueView.tableSorting;

export const headersIdsSelector = (state: StoreState): string[] =>
  state.ui.issueView.tableHeaders;

export const restrictFixed = (column: IssueTableColumn): boolean => {
  return !fixedColumnsIds.includes(column?._id);
};
