import { WithUserRoleObject } from 'helpers/misc';
import { isDefined } from 'shared/domain/role/isDefined';
import { isStandard } from 'shared/domain/role/isStandard';
import { isViewer } from 'shared/domain/role/isViewer';

export function accessForAllRoles(): boolean {
  return true;
}

type Predicate = (...args: any) => boolean;

export function customAccessFunction(callback: Predicate): Predicate {
  return callback;
}

export function accessAtLeastManager(user: WithUserRoleObject): boolean {
  return (
    isDefined(user.role) && !isStandard(user.role) && !isViewer(user.role)
  );
}
