import { StandardHeader } from 'components/table/ColumnHeader';
import {
  createCellCreationDate,
  createCellExecutionDate,
  createCellModificationDate,
} from 'components/table/renderers/cell/dates';
import { SORT_TYPE } from 'shared/filter/sort/types';
import {
  accessorCreationDate,
  accessorExecutionDate,
  accessorModificationDate,
} from '../../accessors';
import { ColumnShapeSkeleton } from '../types';
import { ISO6391LanguageCode } from 'shared/types/locale';

const base = {
  initialWidth: 192,
  flexGrow: 0,
  flexShrink: 0,
  sortable: true,
  sortType: SORT_TYPE.NUMBERS,
  style: {
    justifyContent: 'flex-start',
  },
};

export function getCreatedAtColumnShapeSkeleton(
  timezone: string,
  locale: ISO6391LanguageCode
): ColumnShapeSkeleton {
  return {
    ...base,
    accessor: accessorCreationDate,
    cellRenderer: createCellCreationDate(timezone, locale),
    labelId: 'issue_tableView_header_creation_date',
    dataKey: 'createdAt',
  };
}

export function getInspectionExecutionDateColumnShapeSkeleton(
  timezone: string,
  locale: ISO6391LanguageCode
): ColumnShapeSkeleton {
  return {
    ...base,
    headerRenderer: StandardHeader,
    accessor: accessorExecutionDate,
    cellRenderer: createCellExecutionDate(timezone, locale),
    labelId: 'inspection_table_execution_date',
    dataKey: 'inspectionDate',
    // TODO PT-5126
    // unify styling with other date cells
    style: {
      justifyContent: 'flex-start',
    },
  };
}

export function getModifiedAtColumnShapeSkeleton(
  timezone: string,
  locale: ISO6391LanguageCode
): ColumnShapeSkeleton {
  return {
    ...base,
    accessor: accessorModificationDate,
    cellRenderer: createCellModificationDate(timezone, locale),
    labelId: 'issue_tableView_header_modifiedAt_date',
    dataKey: 'modifiedAt',
  };
}
