import { isGetByIds, isGetByQuery } from 'serviceWorker/api/typeGuards';
import {
  broadcastDocument,
  broadcastDocuments,
  broadcastDocumentUrls,
} from 'serviceWorker/broadcasts/documents';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import * as config from 'serviceWorker/db/config';
import { ConfigData } from 'serviceWorker/db/config';
import * as documents from 'serviceWorker/db/documents';
import { documentEntityToModel } from 'serviceWorker/repository/document/documentEntityToModel';
import { DocumentEntity } from 'serviceWorker/repository/document/entity';
import { DOCUMENT_ERROR_SOURCE } from 'shared/domain/document/documentError';
import {
  DocumentModel,
  DocumentParentBond,
} from 'shared/domain/document/documentModel';
import { DocumentUrlsModel } from 'shared/domain/document/documentUrlsModel';
import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import {
  CreateDocumentCustomEvent,
  CreateDrawingCustomEvent,
  DeleteDocumentCustomEvent,
  EditDocumentCustomEvent,
  GetDocumentCustomEvent,
  GetDocumentsCustomEvent,
  GetDocumentsCustomEventDetail,
} from 'shared/domain/messages/document/eventMessages';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { debugLog } from 'shared/logger/debugLog';
import { UploadStatus } from 'shared/types/uploadStatus';
import { getFetchConfig } from '../config';
import { pullSignedRequest } from '../reports';
import documentSaver from './addDocument';
import drawingSaver from './addDrawing';
import documentDeleter from './deleteDocument';
import documentEditor, { DocumentChanges } from './editDocument';
import { resolveDocumentRemoteSource } from './resolveDocumentUrl';
import { apiUrl as apiUrlFromGlobalObject } from 'shared/apiUrl';
import { logWithRethrow } from 'serviceWorker/db/helpers';
import { swLog } from 'serviceWorker/helpers/makeSwLogger';
import { LogLevel } from 'shared/types/logger';
import { broadcastSharedResourceSignedRequest } from 'serviceWorker/broadcasts/reports';

function getDocumentsListByIds(ids: number[]): Promise<DocumentModel[]> {
  return documents
    .getByIds(ids)
    .then((docs) => docs.map(documentEntityToModel));
}

function getDocumentsListByQuery(
  query: DocumentParentBond
): Promise<DocumentEntity[]> {
  return documents
    .getByQuery(query)
    .then((docs) => docs.map(documentEntityToModel));
}

function getDocuments(
  detail: GetDocumentsCustomEventDetail
): Promise<DocumentEntity[]> {
  if (isGetByIds(detail)) return getDocumentsListByIds(detail.ids);
  if (isGetByQuery(detail)) return getDocumentsListByQuery(detail.query);
  throw new Error('Function getDocuments recieved unknown type.');
}

export async function getDocument(id: number): Promise<DocumentModel> {
  const document = await documents.getOne(id);
  if (!document) {
    throw new Error('Cannot find document with id ' + id);
  }
  return documentEntityToModel(document);
}

export function updateDocument(
  id: number,
  changes: DocumentChanges,
  options?: { skipModifyTime: boolean }
): Promise<number> {
  return documents.updateOne(id, changes, options);
}

async function getDocumentUrls(
  document: DocumentEntity
): Promise<DocumentUrlsModel> {
  if (
    document._id &&
    document.data?.uploadStatus === UploadStatus.success &&
    document.syncStatus === SyncStatus.SUCCESS
  ) {
    const setup = await getFetchConfig();

    if (!setup) {
      return Promise.reject(new Error('Cannot get config setup.'));
    }

    if (
      document.data?.isDrawn &&
      (document.data?.mergedUploadStatus !== UploadStatus.success ||
        document.drawingSyncStatus !== SyncStatus.SUCCESS)
    ) {
      const signedRequest = await resolveDocumentRemoteSource(
        setup,
        document
      );

      if (!signedRequest.signedRequest && !document.data?.mergedSrc) {
        return Promise.reject(
          new Error('Document source data is undefined.')
        );
      }

      return Promise.resolve({
        signedRequest: signedRequest.signedRequest,
        thumbnailSignedRequest: signedRequest.thumbnailSignedRequest,
        mergedSignedRequest:
          document.data?.mergedSrc || signedRequest.mergedSignedRequest,
        mergedThumbnailSignedRequest:
          document.data?.thumbnailMergedSrc ||
          signedRequest.mergedThumbnailSignedRequest,
        drawingSignedRequest: signedRequest.drawingSignedRequest,
      });
    }

    return resolveDocumentRemoteSource(setup, document);
  } else {
    if (!document.localData) {
      return Promise.reject(
        new Error('Document source data is undefined.')
      );
    }

    if (document.data?.isDrawn) {
      if (!document.data.mergedSrc) {
        return Promise.reject(
          new Error('Document drawing source data is undefined.')
        );
      }

      return Promise.resolve({
        signedRequest: document.localData,
        mergedSignedRequest: document.data.mergedSrc,
        mergedThumbnailSignedRequest: document.data.thumbnailMergedSrc,
        drawingSignedRequest: document.data.drawingSrc,
      });
    } else {
      return Promise.resolve({
        signedRequest: document.localData,
      });
    }
  }
}

function getUrlForPullSignedRequestToSharedResource(
  setup: config.ConfigData | undefined,

  issueId: string,
  eventId: string | undefined,
  documentId: number,
  token: string
): string {
  const apiUrl = setup ? setup.api : apiUrlFromGlobalObject;

  if (eventId) {
    return `${apiUrl}/v2/shared/issue/${issueId}/event/${eventId}/document/${documentId}/download?token=${token}`;
  }

  return `${apiUrl}/v2/shared/issue/${issueId}/document/${documentId}/download?token=${token}`;
}

async function getSharedDocumentSignedRequestHandler(
  e: GetDocumentCustomEvent
): Promise<void> {
  const abortController = new AbortController();

  self.addEventListener(DomainMessagesTypes.logout, () => {
    abortController.abort();
  });
  debugLog('getDocumentSignedRequestHandler event', e);
  const { id: documentId, token, issueId, eventId } = e.detail;

  const setup: ConfigData | undefined = await config.get();

  if (!issueId || !documentId || !token) {
    throw new Error('Issue id, document id and token are required');
  }

  try {
    const url: string = getUrlForPullSignedRequestToSharedResource(
      setup,
      issueId,
      eventId,
      documentId,
      token
    );
    const response: Response = await pullSignedRequest(
      setup,
      abortController,
      url
    );

    //we can't use parseResponse here, because we care about the response contents, event if its status is not 200
    const parsedResponse = await response.json().catch((e) =>
      logWithRethrow({
        msg: 'Could not parse response',
        logLevel: LogLevel.ERROR,
        errorObj: e,
        additionalInfo: {
          response: response,
        },
      })
    );

    debugLog(parsedResponse);
    broadcastSharedResourceSignedRequest(
      documentId.toString(),
      parsedResponse,
      response.status,
      'document'
    );
    return parsedResponse;
  } catch (e) {
    swLog(
      'Problem occured when getting signedRequest',
      LogLevel.INFO,
      e,
      null
    );
  }
}

export const init = (): void => {
  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.getDocuments,
    async function onGetDocuments(
      e: GetDocumentsCustomEvent
    ): Promise<void> {
      try {
        const documents = await getDocuments(e.detail);
        broadcastDocuments(e, documents);
      } catch (error) {
        debugLog('documents error', error);
      }
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    DomainMessagesTypes.getDocument,
    async function onGetDocument(
      e: GetDocumentCustomEvent
    ): Promise<void> {
      debugLog('getDocument event', e);
      let document;
      if (e.detail.token) {
        return getSharedDocumentSignedRequestHandler(e);
      } else {
        document = await getDocument(e.detail.id);
        return broadcastDocument(document);
      }
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.getDocumentUrls,
    async function onGetDocumentUrls(
      e: GetDocumentCustomEvent
    ): Promise<void> {
      const document = await getDocument(e.detail.id);
      if (!document) {
        throw new Error('Cannot find document.');
      }
      const documentUrls = await getDocumentUrls(document).catch(() => {
        return {
          signedRequest: DOCUMENT_ERROR_SOURCE,
          mergedSignedRequest: DOCUMENT_ERROR_SOURCE,
          drawingSignedRequest: DOCUMENT_ERROR_SOURCE,
          mergedThumbnailSignedRequest: DOCUMENT_ERROR_SOURCE,
        };
      });

      broadcastDocumentUrls({
        localId: document.localId,
        documentUrls,
      });
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.addDocument,
    async function onCreateDocument(
      e: CreateDocumentCustomEvent
    ): Promise<void> {
      debugLog('addDocument event', e);
      documentSaver.execute(
        e.detail.baseUrl,
        e.detail.document,
        e.detail.uniqueId
      );
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.editDocument,
    async function onEditDocument(
      e: EditDocumentCustomEvent
    ): Promise<void> {
      debugLog('editDocument event', e);
      documentEditor.execute(e.detail.localId, e.detail);
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.deleteDocument,
    async function onDeleteDocument(
      e: DeleteDocumentCustomEvent
    ): Promise<void> {
      debugLog('deleteDocument event', e);
      documentDeleter.execute(e.detail.localId);
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.addDrawing,
    async function onAddDrawing(
      e: CreateDrawingCustomEvent
    ): Promise<void> {
      debugLog('addDrawing event', e);
      drawingSaver.execute(e.detail.drawing, e.detail.localId);
    }
  );
};
