import { useEffect, useReducer } from 'react';
import { LabelledEntity } from 'shared/types/commonView';
import {
  CompletedInspectionsByTemplateDependencies,
  IsDependenciesDefined,
} from '../types';
import { ProcessType } from 'shared/types/form';
import { SiteModel } from 'shared/domain/site/types/model';
import {
  CompletedInspectionsByTemplateFilters,
  FilterValues,
} from 'shared/types/analytics';
import { DefinedDependencies, Dependencies } from 'charts/shared/types';
import { InspectionTemplateModel } from 'shared/domain/template/inspectionTemplateModel';
import { dependenciesReducer } from 'charts/shared/dependenciesReducer';
import { useDependenciesActions } from 'charts/shared/dependenciesActions';

export function useDependencies(
  managers: LabelledEntity[],
  userProcesses: ProcessType[],
  sites: SiteModel[],
  projectId: string,
  timezone: string,
  filtersResponse: FilterValues | undefined,
  templates: InspectionTemplateModel[],
  templatesLoading: boolean
): {
  dependencies: Dependencies<
    CompletedInspectionsByTemplateDependencies,
    CompletedInspectionsByTemplateFilters
  >;
  isDependenciesDefined: IsDependenciesDefined;
} {
  const [dependencies, dispatchDependencies] = useReducer(
    dependenciesReducer,
    {}
  );

  const {
    setManagers,
    setFiltersResponse,
    setProcesses,
    setProjectId,
    setSites,
    setTimezone,
    setTemplates,
  } = useDependenciesActions(dispatchDependencies);

  useEffect(() => {
    setManagers(managers);
  }, [managers]);
  useEffect(() => {
    setProcesses(userProcesses);
  }, [userProcesses]);
  useEffect(() => {
    setSites(sites);
  }, [sites]);
  useEffect(() => {
    setProjectId(projectId);
  }, [projectId]);
  useEffect(() => {
    setTimezone(timezone);
  }, [timezone]);
  useEffect(() => {
    if (!filtersResponse) return;
    setFiltersResponse(filtersResponse);
  }, [filtersResponse]);
  useEffect(() => {
    if (templatesLoading) return;
    setTemplates(templates);
  }, [templates, templatesLoading]);

  return {
    dependencies,
    isDependenciesDefined,
  };
}

function isDependenciesDefined(
  dependencies: Dependencies<
    CompletedInspectionsByTemplateDependencies,
    CompletedInspectionsByTemplateFilters
  >
): dependencies is DefinedDependencies<
  CompletedInspectionsByTemplateDependencies,
  CompletedInspectionsByTemplateFilters
> {
  return (
    !!dependencies.filters &&
    !!dependencies.managers &&
    !!dependencies.processes &&
    !!dependencies.sites &&
    !!dependencies.projectId &&
    !!dependencies.timezone &&
    !!dependencies.templates
  );
}
