import { ImageConvertStatus } from 'shared/domain/imageConvertStatus/imageConvertStatus';

const SUPPORTED_MAP_IMAGE_EXTENSIONS = ['webp', 'jpg', 'jpeg', 'png'];

export function isSupportedAsMap(
  extension: string,
  imageConvertStatus: ImageConvertStatus | undefined
): boolean {
  return (
    SUPPORTED_MAP_IMAGE_EXTENSIONS.includes(extension?.toLowerCase()) ||
    (extension?.toLowerCase() === 'pdf' &&
      imageConvertStatus === ImageConvertStatus.SUCCESS)
  );
}
