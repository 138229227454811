import { GenericChartState } from 'charts/shared/types';
import { useProcessesWithField } from 'charts/shared/useProcessesWithField';
import { useCallback, useContext, useEffect, useState } from 'react';
import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import { ApexSeriesAndLabels, IssueChart } from 'shared/types/analytics';
import { ChartType } from 'shared/types/analytics';
import { ChartDetailsContext } from 'views/analytics/chartDetails/ChartDetailsProvider';
import { useGetChart } from '../shared/hooks';
import { listViewDonutChartOptions } from '../shared/options/donutChart';
import { useRootCauseFilters } from './filters';
import { detailedViewRootCauseChartOptions } from './options';
import { RootCauseChartContext } from './types';

export function useRootCause(): RootCauseChartContext {
  const processesWithRootCause = useProcessesWithField(
    IssueFieldNames.rootCauses
  );
  const access = useCallback(() => {
    return Boolean(processesWithRootCause.length);
  }, [processesWithRootCause]);
  const filters = useRootCauseFilters(processesWithRootCause);
  const { series, loading, load, reload, updatedAt } =
    useGetChart<ApexSeriesAndLabels>(IssueChart.rootCause, filters);

  const isLoading = !filters || loading;

  const isDetailedView = useContext(ChartDetailsContext);
  const options = isDetailedView
    ? detailedViewRootCauseChartOptions
    : listViewDonutChartOptions;
  const [state, setState] = useState<GenericChartState>({
    series: [],
    options,
  });

  useEffect(() => {
    setState((prev: GenericChartState): GenericChartState => {
      return {
        ...prev,
        series: series[0],
        options: {
          ...options,
          labels: series[1],
        },
      };
    });
  }, [series, options]);

  return {
    state,
    filters,
    titleId: 'root_cause_chart',
    chartType: ChartType.donut,
    availableTypes: [ChartType.donut],
    canAccess: access,
    loading: isLoading,
    load: load,
    forceUpdate: reload,
    updatedAt: (): number => updatedAt,
  };
}
