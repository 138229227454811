import { accessAtLeastManager } from 'charts/shared/access';
import { useGetChart } from 'charts/shared/hooks';
import { useContext, useEffect, useState } from 'react';
import {
  ChartType,
  InspectionChart,
  InspectionTripletFilters,
  TripletSeries,
  TripletSeriesWithLabels,
} from 'shared/types/analytics';
import { ChartDetailsContext } from 'views/analytics/chartDetails/ChartDetailsProvider';
import { TypedSimpleChartContext } from 'views/analytics/types';
import { useInspectionTripletFilters } from './filters';
import { detailedViewInspectionTripletChartOptions } from './options';
import { listViewInspectionsDonutChartOptions } from 'charts/shared/options/donutChart';
import { TypedChartState } from 'charts/shared/types';

export function useInspectionTriplet(): TypedSimpleChartContext<
  TripletSeries,
  InspectionTripletFilters
> {
  const filters = useInspectionTripletFilters();
  const isDetailedView = useContext(ChartDetailsContext);
  const options = isDetailedView
    ? detailedViewInspectionTripletChartOptions
    : listViewInspectionsDonutChartOptions;

  const [state, setState] = useState<TypedChartState<TripletSeries>>({
    series: [
      { value: undefined },
      { value: undefined },
      { value: undefined },
    ],
    options,
  });

  const { series, loading, load, reload, updatedAt } =
    useGetChart<TripletSeriesWithLabels>(
      InspectionChart.inspectionTriplet,
      filters,
      undefined,
      [
        [{ value: undefined }, { value: undefined }, { value: undefined }],
        ['', '', ''],
      ]
    );

  const isLoading = !filters || loading;

  useEffect(() => {
    setState(
      (
        prev: TypedChartState<TripletSeries>
      ): TypedChartState<TripletSeries> => {
        return {
          ...prev,
          series: series[0],
          options: {
            ...options,
            labels: series[1],
          },
        };
      }
    );
  }, [series, options]);

  return {
    state,
    filters,
    titleId: 'inspection_summary_chart',
    chartType: ChartType.triplet,
    availableTypes: [ChartType.triplet],
    canAccess: accessAtLeastManager,
    loading: isLoading,
    load: load,
    forceUpdate: reload,
    updatedAt: (): number => updatedAt,
  };
}
