import { ApexOptions } from 'apexcharts';
import { ReactElement } from 'react';
import { ChartType, TripletSeries } from 'shared/types/analytics';
import { TripletPartChart } from './TripletPartChart';
import useIsMobile from 'hooks/useIsMobile';
import { useIntl } from 'react-intl';
import {
  chartTextStyle,
  chartWrapperStyle,
  numberWrapperStyle,
  wrapperStyle,
} from './style';

const tripletStyle = (height: string | number): object => ({
  width: '100%',
  height: height,
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridTemplateRows: '2fr 1fr',
});

export function HorizontalTripletChart({
  series,
  options,
}: {
  series: TripletSeries;
  options: ApexOptions;
}): ReactElement {
  const isMobile = useIsMobile();
  const intl = useIntl();
  return (
    <div style={wrapperStyle}>
      <div id='chart' style={tripletStyle(350)}>
        <div style={numberWrapperStyle}>
          <span>
            {series[0].value}
            {options.labels?.[0]}
          </span>
        </div>
        <div style={chartWrapperStyle}>
          <TripletPartChart
            color='#FDB22B'
            chartType={ChartType.radialBar}
            index={1}
            isMobile={isMobile}
            options={options}
            series={series}
          />
        </div>
        <div style={chartWrapperStyle}>
          <TripletPartChart
            color='#288AE2'
            chartType={ChartType.radialBar}
            index={2}
            isMobile={isMobile}
            options={options}
            series={series}
          />
        </div>
        <div style={chartTextStyle}>
          {intl.formatMessage({ id: 'completed_inspections_chart' })}
        </div>
        <div style={chartTextStyle}>
          {intl.formatMessage({ id: 'failed_inspection_factor_chart' })}
        </div>
        <div style={chartTextStyle}>
          {intl.formatMessage({ id: 'average_inspection_result_chart' })}
        </div>
      </div>
    </div>
  );
}
