import { BroadcastChannel } from 'broadcast-channel';
import { useGetDocuments } from 'hooks/useGetDocuments';
import { useMountedRef } from 'hooks/useMountRef';
import React, {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { ChannelNames } from 'shared/domain/channelNames';
import { DocumentModel } from 'shared/domain/document/documentModel';
import {
  DomainMessagesTypes,
  Message,
} from 'shared/domain/messages/message';
import { debugLog } from 'shared/logger/debugLog';
import { isEqualQuery } from './model';

function hasListChanged(
  newItems: DocumentModel[],
  oldDocuments: DocumentModel[]
): boolean {
  // Sprawdź długość
  if (newItems.length !== oldDocuments.length) {
    return true;
  }

  const documentsMap = new Map(
    oldDocuments.map((doc) => [doc._id, doc.modifiedAt])
  );

  return newItems.some(
    (item) => documentsMap.get(item._id) !== item.modifiedAt
  );
}

type DocumentsContext = {
  documents: DocumentModel[];
  loading: boolean;
  setQuery: Dispatch<SetStateAction<any>>;
};
const WithDocumentsContext = React.createContext<
  DocumentsContext | undefined
>(undefined);

const WithDocuments: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const mountedRef = useMountedRef();
  const [documents, setDocuments] = useState<DocumentModel[]>([]);
  const [query, setQuery] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const { getDocuments } = useGetDocuments(query);

  useEffect(() => {
    const broadcast = new BroadcastChannel(ChannelNames.documentChannel);

    broadcast.onmessage = (
      event: Message<{
        items: DocumentModel[];
        query?: string;
        ids?: string[];
      }>
    ): void => {
      if (
        !mountedRef.current ||
        event.type !== DomainMessagesTypes.documents
      ) {
        return;
      }

      if (event.data && isEqualQuery(event.data.query, query)) {
        if (hasListChanged(event.data.items, documents)) {
          setDocuments(event.data.items);
          debugLog('WithDocuments documents', event.data.items);
        }
        setLoading(false);
      }

      if (event.data && isEqualQuery(event.data.ids, query)) {
        setDocuments(event.data.items);
        setLoading(false);
      }
    };

    getDocuments(setLoading);

    return (): void => {
      broadcast.close();
    };
  }, [query, getDocuments]);

  const ctx: DocumentsContext = {
    documents,
    loading,
    setQuery,
  };

  return (
    <WithDocumentsContext.Provider value={ctx}>
      {children}
    </WithDocumentsContext.Provider>
  );
};

function useDocuments(): DocumentsContext {
  const context = React.useContext(WithDocumentsContext);
  if (context === undefined) {
    throw new Error(
      'useDocuments must be used within an DocumentsContext'
    );
  }
  return context;
}

const withDocuments =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithDocuments>
      <Component {...props} />
    </WithDocuments>
  );

export { useDocuments, withDocuments };
