import { Nothing } from 'components/general/Nothing';
import { ColumnKey } from 'components/table/columnShapes/types';
import { toReactColumn } from 'components/table/skeleton';
import {
  sortTableEntities,
  useColumnSortHandler,
} from 'components/table/sort';
import { InspectionOnTableView } from 'presentation/inspection/inspectionOnView';
import React, { ReactElement, useMemo, useState } from 'react';
import BaseTable from 'react-base-table';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  checkProcessesEquality,
  toProcessesObject,
} from 'redux/selectors/processes';
import { projectDataSelector } from 'redux/selectors/project';
import { Sorting, SortingOrder } from 'shared/filter/sort/types';
import { Identificable } from 'shared/types/commonView';
import { useSavedTableColumnWidths } from '../../../hooks/table/useSavedTableColumnWidths';
import { TableWidthStorageKey } from '../../table/types';
import { InspectionColumnsFactory } from './columns';
import { useStyles } from './styles';
import { InspectionsTableProps } from './types';
import { ISO6391LanguageCode } from 'shared/types/locale';

function InspectionsTable({
  width,
  height,
  data,
}: InspectionsTableProps): ReactElement {
  const classes = useStyles();
  const history = useHistory();
  const { saveColumnWidth } =
    useSavedTableColumnWidths<InspectionOnTableView>(
      TableWidthStorageKey.inspection
    );
  const intl = useIntl();
  const { timezone, _id: projectId } = useSelector(projectDataSelector);

  const { columnWidths } = useSavedTableColumnWidths(
    TableWidthStorageKey.inspection
  );

  const processes = useSelector(toProcessesObject, checkProcessesEquality);

  const columns = React.useMemo(
    () =>
      new InspectionColumnsFactory(
        intl.formatMessage,
        columnWidths,
        processes,
        timezone,
        intl.locale as ISO6391LanguageCode
      ).create(),
    [intl.formatMessage, columnWidths, processes, timezone]
  );

  const [sortBy, setSortBy] = useState<Sorting>({
    key: ColumnKey.executionDate,
    order: SortingOrder.desc,
  });

  const sortedInspections = useMemo(
    () => sortTableEntities(data, columns, sortBy),
    [data, columns, sortBy]
  );

  const rowEventHandlers = React.useMemo(() => {
    return {
      onClick: ({ rowData }: { rowData: Identificable }): void => {
        history.push(`/project/${projectId}/inspection/${rowData._id}`);
      },
    };
  }, [history, projectId]);

  const reactColumns = columns.map(toReactColumn);
  return (
    <BaseTable
      fixed
      rowKey='_id'
      width={width}
      height={height}
      data={sortedInspections}
      sortBy={sortBy}
      onColumnSort={useColumnSortHandler(setSortBy)}
      className={classes.noOverflow}
      rowEventHandlers={rowEventHandlers}
      onColumnResizeEnd={saveColumnWidth}
      components={{
        SortIndicator: Nothing,
      }}
    >
      {reactColumns}
    </BaseTable>
  );
}

export const MemoInspectionsTable = React.memo(InspectionsTable);
