import { inDtoToDocumentInsertEntity } from 'serviceWorker/repository/document/documentInDtoToEntity';
import { IssueEntity } from 'serviceWorker/repository/issue/entity';
import { issueDtoToEntityToSave } from 'serviceWorker/repository/issue/issueDtoToEntity';
import { DocumentInDto } from 'shared/dtos/in/document';
import { IssueInDto } from 'shared/dtos/in/issue';
import { get as getCompanies } from './companies';
import { get as getContracts } from './contracts';
import { get as getCorrectiveActionTypes } from './correctiveActionTypes';
import {
  removeBatchByParentId as removeDocumentsBatch,
  removeBatchByParentType as removeDocumentsBatchByType,
  upsertBatch as upsertDocumentsBatch,
} from './documents';
import { entityDbClearFactory } from './entityDbClearFactory';
import { get as getEnvironmentalAspects } from './environmentalAspects';
import { get as getHazardCategories } from './hazardCategories';
import {
  getErrorMsg,
  getErrorTransactionMsg,
  logWithRethrow,
  Operation,
} from './helpers';
import db from './index';
import { clear as issuesServiceClear } from './issuesService';
import { get as getLevels } from './levels';
import { wrapQuery } from './queryWrapper';
import { get as getSites } from './sites';
import { get as getWorkTypes } from './worktypes';

import { get as getUsers } from 'serviceWorker/db/users';
import { delayCalculator } from 'shared/domain/issue/calculateDelayField';
import { filtering, searching } from 'shared/filter/filter';
import { paginate } from 'shared/filter/paginate';
import { sorting } from 'shared/filter/sort/sort';
import {
  PaginationData,
  RelationsMap,
  SingleFilter,
  SortData,
} from 'shared/filter/types';
import { genRelationsMap } from 'shared/utils/relationMap';
import {
  makeDefaultCount,
  makeDefaultGetMany,
  makeDefaultGetManyByIds,
  makeDefaultGetOne,
} from './defaultDaoFactories';

import { makeBroadcastClear } from 'serviceWorker/broadcasts/factories';
import { getIssueForm } from 'serviceWorker/services/issueForm/getIssueForm';
import { getAllCurrentVisibleFieldsPromise } from 'serviceWorker/services/visibleFields/getVisibleFieldsPromise';
import { ChannelNames } from 'shared/domain/channelNames';
import { amountPercentageCalculator } from 'shared/domain/issue/calculateAmountPecentageField';
import { VisibleFieldModel } from 'shared/domain/visibleField/visibleFieldModel';
import { toVisibleFieldModelMap } from 'shared/domain/visibleField/visibleFieldsMap';
import { debugLog } from 'shared/logger/debugLog';
import { Changes } from 'shared/types/commonEntities';
import { HashMap, LabelledEntity } from 'shared/types/commonView';
import { LogLevel } from 'shared/types/logger';
import { nowISO } from 'shared/utils/date/dates';
import { retry } from 'shared/utils/retry';

export const clearForPull = wrapQuery(db, (): Promise<void> => {
  return db
    .transaction(
      'rw',
      db.issues,
      db.documents,
      async function transactionClearIssues(): Promise<any> {
        debugLog('flushing table:', 'issues');

        await Promise.all([
          db.issues.clear().catch((e) =>
            logWithRethrow({
              logLevel: LogLevel.INFO,
              msg: getErrorMsg(Operation.clear, 'issues'),
              errorObj: e,
              additionalInfo: null,
            })
          ),
          removeDocumentsBatchByType('issueId'),
        ]);
      }
    )
    .catch((e) => {
      logWithRethrow({
        logLevel: LogLevel.INFO,
        msg: getErrorTransactionMsg(
          [Operation.clear],
          ['issues', 'documents']
        ),
        errorObj: e,
        additionalInfo: {},
      });
    });
});

export const clear = entityDbClearFactory(
  db,
  ['issues', 'issuesService', 'documents'],
  clearForPull,
  issuesServiceClear,
  makeBroadcastClear(ChannelNames.issueChannel)
);

export const quantity = makeDefaultCount<IssueEntity>(db, db.issues);

export const get = makeDefaultGetMany<IssueEntity>(db, db.issues, {
  deleted: 0,
});

export const getAll = makeDefaultGetMany<IssueEntity>(db, db.issues);

export const addBatch = wrapQuery(
  db,
  (data: IssueInDto[]): Promise<IssueEntity[]> => {
    return db
      .transaction('rw', db.issues, db.documents, function () {
        const documentsData = data.flatMap((issue: IssueInDto) => {
          const docs = [
            ...issue.primaryData.documents.map((document: DocumentInDto) =>
              inDtoToDocumentInsertEntity(document, {
                issueId: issue._id,
              })
            ),
            ...issue.events.flatMap((event) => {
              const eventDocs = event.documents.map(
                (document: DocumentInDto) =>
                  inDtoToDocumentInsertEntity(document, {
                    issueId: issue._id,
                    eventId: event._id,
                  })
              );
              return eventDocs;
            }),
            ...issue.incomingEmailMessages.flatMap(
              (incomingEmailMessage) => {
                const incomingEmailMessageDocs =
                  incomingEmailMessage.documents.map(
                    (document: DocumentInDto) =>
                      inDtoToDocumentInsertEntity(document, {
                        issueId: issue._id,
                        incomingEmailMessageId: incomingEmailMessage._id,
                      })
                  );
                return incomingEmailMessageDocs;
              }
            ),
          ];
          return docs;
        });

        return Promise.all([
          // @ts-ignore bulkPut expects localId but it's auto increment.
          db.issues.bulkPut(data.map(issueDtoToEntityToSave)),
          upsertDocumentsBatch(documentsData),
        ]);
      })
      .catch((e) => {
        logWithRethrow({
          logLevel: LogLevel.INFO,
          msg: getErrorTransactionMsg(
            [Operation.addBatch],
            ['issues', 'documents']
          ),
          errorObj: e,
          additionalInfo: {},
        });
      });
  }
);

export const updateBatch = addBatch;

export const removeBatch = wrapQuery(
  db,
  (ids: string[]): Promise<string[]> => {
    return db
      .transaction('rw', db.issues, db.documents, async function () {
        const deleteIssues = db.issues
          .where('_id')
          .anyOf(ids)
          .delete()
          .catch((e) =>
            logWithRethrow({
              logLevel: LogLevel.INFO,
              msg: getErrorMsg(Operation.removeBatch, 'issues'),
              errorObj: e,
              additionalInfo: { query: { ids } },
            })
          );
        const deleteDocuments = removeDocumentsBatch({ issueId: ids });

        await Promise.all([deleteIssues, deleteDocuments]);
        return ids;
      })
      .catch((e) =>
        logWithRethrow({
          logLevel: LogLevel.INFO,
          msg: getErrorTransactionMsg(
            [Operation.removeBatch],
            ['issues', 'documents']
          ),
          errorObj: e,
          additionalInfo: {
            query: {
              ids,
            },
          },
        })
      );
  }
);

export const updateOne = wrapQuery(
  db,
  async (
    issueId: string,
    changes: Changes<IssueEntity>,
    options?: { skipModifyTime: boolean }
  ): Promise<any> => {
    const modifiedAt = options?.skipModifyTime
      ? {}
      : { modifiedAt: nowISO() };
    return db.issues
      .update(issueId, { ...changes, ...modifiedAt })
      .catch((e) =>
        logWithRethrow({
          logLevel: LogLevel.INFO,
          msg: getErrorMsg(Operation.updateOne, 'issues'),
          errorObj: e,
          additionalInfo: { query: changes },
        })
      );
  }
);

export const getOne = makeDefaultGetOne<string, IssueEntity>(
  db,
  db.issues,
  '_id'
);

export const getByIds = makeDefaultGetManyByIds<string, IssueEntity>(
  db,
  db.issues,
  '_id'
);

// Creates hashmap of related items, allowing us to sort/search by them.
export const searchIssueRelationMap = async (): Promise<RelationsMap> => {
  const {
    getRootCauses,
    getImpacts,
    getProposedCorrectiveAction,
    getDecisionToImposeFine,
    getEffects,
    getCircumstances,
    getSpilledSubstances,
  } = await createIssueFormFieldValueRelations();

  const relations = [
    {
      getter: getSites,
      key: 'label',
      name: 'sites',
      matches: [{ path: 'primaryData.site', type: 'direct' }],
    },
    {
      getter: getLevels,
      key: 'label',
      name: 'level',
      matches: [{ path: 'primaryData.level', type: 'direct' }],
    },
    {
      getter: getUsers,
      key: 'label',
      name: 'users',
      matches: [
        { path: 'primaryData.assignee', type: 'direct' },
        { path: 'createdBy', type: 'direct' },
        { path: 'modifiedBy', type: 'direct' },
        {
          path: 'extendedData.subcontractorRepresentative',
          type: 'direct',
        },
      ],
    },
    {
      getter: getCompanies,
      key: 'longLabel',
      name: 'companies',
      matches: [
        {
          path: 'primaryData.subcontractors',
          type: 'oneOfDirect',
        },
      ],
    },
    {
      getter: getContracts,
      key: 'label',
      name: 'contractNumbers',
      matches: [
        {
          path: 'primaryData.contractNumbers',
          type: 'oneOfDirect',
        },
      ],
    },
    {
      getter: getWorkTypes,
      key: 'label',
      name: 'workTypes',
      matches: [
        {
          path: 'extendedData.workTypes',
          type: 'oneOfDirect',
        },
      ],
    },
    {
      getter: getHazardCategories,
      key: 'label',
      name: 'hazardCategories',
      matches: [
        {
          path: 'extendedData.hazardCategories',
          type: 'oneOfDirect',
        },
      ],
    },
    {
      getter: getCorrectiveActionTypes,
      key: 'label',
      name: 'correctiveActionType',
      matches: [
        {
          path: 'extendedData.proposedCorrectiveAction',
          type: 'direct',
        },
      ],
    },
    {
      getter: getEnvironmentalAspects,
      key: 'label',
      name: 'environmentalAspect',
      matches: [
        {
          path: 'extendedData.environmentalAspect',
          type: 'direct',
        },
      ],
    },
    {
      getter: getRootCauses,
      key: 'label',
      name: 'rootCauses',
      matches: [
        {
          path: 'extendedData.rootCauses',
          type: 'oneOfDirect',
        },
      ],
    },
    {
      getter: getImpacts,
      key: 'label',
      name: 'impact',
      matches: [
        {
          path: 'extendedData.impact',
          type: 'direct',
        },
      ],
    },
    {
      getter: getProposedCorrectiveAction,
      key: 'label',
      name: 'proposedCorrectiveAction',
      matches: [
        {
          path: 'extendedData.proposedCorrectiveAction',
          type: 'direct',
        },
      ],
    },
    {
      getter: getDecisionToImposeFine,
      key: 'label',
      name: 'decisionToImposeFine',
      matches: [
        {
          path: 'extendedData.decisionToImposeFine',
          type: 'direct',
        },
      ],
    },
    {
      getter: getEffects,
      key: 'label',
      name: 'effect',
      matches: [
        {
          path: 'extendedData.effect',
          type: 'direct',
        },
      ],
    },
    {
      getter: getCircumstances,
      key: 'label',
      name: 'circumstances',
      matches: [
        {
          path: 'extendedData.circumstances',
          type: 'direct',
        },
      ],
    },
    {
      getter: getSpilledSubstances,
      key: 'label',
      name: 'spilledSubstance',
      matches: [
        {
          path: 'extendedData.spilledSubstance',
          type: 'direct',
        },
      ],
    },
  ];

  const res = await genRelationsMap(relations);
  return res;
};

export const sortIssueRelationMap = async (
  sort: string | undefined
): Promise<RelationsMap> => {
  const {
    getImpacts,
    getProposedCorrectiveAction,
    getDecisionToImposeFine,
    getEffects,
    getCircumstances,
    getSpilledSubstances,
  } = await createIssueFormFieldValueRelations();
  const relations = [
    {
      getter: getSites,
      key: 'label',
      name: 'sites',
      matches: [{ path: 'primaryData.site', type: 'direct' }],
    },
    {
      getter: getLevels,
      key: 'label',
      name: 'level',
      matches: [{ path: 'primaryData.level', type: 'direct' }],
    },
    {
      getter: getUsers,
      key: 'label',
      name: 'users',
      matches: [
        { path: 'primaryData.assignee', type: 'direct' },
        { path: 'primaryData.executor', type: 'direct' },
        { path: 'createdBy', type: 'directId' },
        { path: 'modifiedBy', type: 'directId' },
        {
          path: 'extendedData.subcontractorRepresentative',
          type: 'direct',
        },
      ],
    },
    {
      getter: getCorrectiveActionTypes,
      key: 'label',
      name: 'correctiveActionType',
      matches: [
        {
          path: 'extendedData.proposedCorrectiveAction',
          type: 'direct',
        },
      ],
    },
    {
      getter: getEnvironmentalAspects,
      key: 'label',
      name: 'environmentalAspect',
      matches: [
        {
          path: 'extendedData.environmentalAspect',
          type: 'direct',
        },
      ],
    },

    {
      getter: getImpacts,
      key: 'label',
      name: 'impact',
      matches: [
        {
          path: 'extendedData.impact',
          type: 'direct',
        },
      ],
    },
    {
      getter: getProposedCorrectiveAction,
      key: 'label',
      name: 'proposedCorrectiveAction',
      matches: [
        {
          path: 'extendedData.proposedCorrectiveAction',
          type: 'direct',
        },
      ],
    },
    {
      getter: getDecisionToImposeFine,
      key: 'label',
      name: 'decisionToImposeFine',
      matches: [
        {
          path: 'extendedData.decisionToImposeFine',
          type: 'direct',
        },
      ],
    },
    {
      getter: getEffects,
      key: 'label',
      name: 'effect',
      matches: [
        {
          path: 'extendedData.effect',
          type: 'direct',
        },
      ],
    },
    {
      getter: getCircumstances,
      key: 'label',
      name: 'circumstances',
      matches: [
        {
          path: 'extendedData.circumstances',
          type: 'direct',
        },
      ],
    },
    {
      getter: getSpilledSubstances,
      key: 'label',
      name: 'spilledSubstance',
      matches: [
        {
          path: 'extendedData.spilledSubstance',
          type: 'direct',
        },
      ],
    },
  ];

  const sortMatch = relations.some((relation) => {
    return relation.matches.some((relationMatch) => {
      return relationMatch.path === sort;
    });
  });

  if (!sortMatch) {
    return Promise.resolve({} as RelationsMap);
  }

  const res = await genRelationsMap(relations);
  return res;
};

const hashMapByPath = (
  relations: RelationsMap,
  path: string
): null | { [key: string]: string } => {
  if (!path || !relations.matches) {
    return null;
  }
  const match = relations.matches.find((relation) => {
    const isMatch = relation.matches.find((match) => match.path === path);
    return isMatch;
  });

  if (!match) {
    return null;
  }

  const result = relations.hashMap[match.name];
  return result;
};

export const getFiltered = async (
  filters: SingleFilter[],
  search: SingleFilter[],
  sort: SortData,
  pagination: PaginationData,
  archived: boolean | any[],
  timezone: string
): Promise<{ items: IssueEntity[]; total: number }> => {
  const issues = Array.isArray(archived)
    ? await getAll()
    : await get({ deleted: archived ? 1 : 0 });
  const searchKey = search.find((elem) => elem.type === 'search')?.value;
  const relations = searchKey
    ? await searchIssueRelationMap()
    : await sortIssueRelationMap(sort?.path);

  const visibleFieldsMap = await createVisibleFieldsMap();

  const filtered = filtering({
    set: issues,
    filters,
    options: { skip: makeSkipNotVisibleFields(visibleFieldsMap) },
  });
  addVirtualFields(filtered, timezone);
  const searched = await searching({
    set: filtered,
    filters: search,
    relationsMap: relations,
  });
  const sortRelation = hashMapByPath(relations, sort?.path);
  const sorted = sorting(searched, sort, sortRelation);
  // TODO: store above result in some temporary cache, when user asks for the same filter/search string
  // but different page (should be common case) we could skip all logic and just paginate
  const paginated = paginate(sorted, pagination);
  return { items: paginated as IssueEntity[], total: searched.length };
};

function addVirtualFields(
  issues: {
    extendedData: {
      targetCompletionDate?: string;
      finalCompletionDate?: string;
      completionDateDelay?: number | null;
      startDateDelay?: number | null;
      targetStartDate?: string;
      finalStartDate?: string;
      targetAmount?: number;
      completedAmount?: number | null;
      amountPercentage?: number | null;
    };
    hashtag?: string | undefined;
    _id: string;
  }[],
  timezone: string
): void {
  issues.forEach((issue) => {
    const {
      targetCompletionDate,
      finalCompletionDate,
      targetStartDate,
      finalStartDate,
      targetAmount,
      completedAmount,
    } = issue.extendedData;
    const completionDelay = delayCalculator.execute(
      targetCompletionDate,
      finalCompletionDate,
      timezone
    );
    const startDelay = delayCalculator.execute(
      targetStartDate,
      finalStartDate,
      timezone
    );
    const amountPercentage = amountPercentageCalculator.execute(
      targetAmount,
      completedAmount
    );
    issue.extendedData.completionDateDelay = completionDelay;
    issue.extendedData.startDateDelay = startDelay;
    issue.extendedData.amountPercentage = amountPercentage;
    issue.hashtag = issue._id.slice(-4);
  });
}

export const getFilteredIdList = (
  filters: SingleFilter[],
  search: SingleFilter[],
  sort: SortData,
  pagination: PaginationData,
  archived: boolean | any[],
  timezone: string
): Promise<{ _id: string; site: string }[]> => {
  return getFiltered(
    filters,
    search,
    sort,
    pagination,
    archived,
    timezone
  ).then((issues) =>
    issues.items.map((i) => ({ _id: i._id, site: i.primaryData.site }))
  );
};

async function createIssueFormFieldValueRelations(): Promise<{
  getRootCauses: () => Promise<LabelledEntity[]>;
  getImpacts: () => Promise<LabelledEntity[]>;
  getProposedCorrectiveAction: () => Promise<LabelledEntity[]>;
  getDecisionToImposeFine: () => Promise<LabelledEntity[]>;
  getEffects: () => Promise<LabelledEntity[]>;
  getCircumstances: () => Promise<LabelledEntity[]>;
  getSpilledSubstances: () => Promise<LabelledEntity[]>;
}> {
  const issueForms = await retry(() => getIssueForm(), 20);

  const rootCausesField: { items?: any[] } = !issueForms
    ? { items: [] }
    : issueForms.find(
        (field: { name: string; items?: any[] }) =>
          field.name === 'rootCauses'
      )!;
  const impactField: { items?: any[] } = !issueForms
    ? { items: [] }
    : issueForms.find(
        (field: { name: string; items?: any[] }) => field.name === 'impact'
      )!;

  const effectField: { items?: any[] } = !issueForms
    ? { items: [] }
    : issueForms.find(
        (field: { name: string; items?: any[] }) => field.name === 'effect'
      )!;

  const proposedCorrectiveActionField: { items?: any[] } = !issueForms
    ? { items: [] }
    : issueForms.find(
        (field: { name: string; items?: any[] }) =>
          field.name === 'proposedCorrectiveAction'
      )!;

  const decisionToImposeFineField: { items?: any[] } = !issueForms
    ? { items: [] }
    : issueForms.find(
        (field: { name: string; items?: any[] }) =>
          field.name === 'decisionToImposeFine'
      )!;

  const circumstancesField: { items?: any[] } = !issueForms
    ? { items: [] }
    : issueForms.find(
        (field: { name: string; items?: any[] }) =>
          field.name === 'circumstances'
      )!;

  const spilledSubstanceField: { items?: any[] } = !issueForms
    ? { items: [] }
    : issueForms.find(
        (field: { name: string; items?: any[] }) =>
          field.name === 'spilledSubstance'
      )!;

  const getRootCauses = (): Promise<any[]> =>
    Promise.resolve(rootCausesField.items!);
  const getImpacts = (): Promise<any[]> =>
    Promise.resolve(impactField.items!);
  const getProposedCorrectiveAction = (): Promise<any[]> =>
    Promise.resolve(proposedCorrectiveActionField.items!);
  const getEffects = (): Promise<any[]> =>
    Promise.resolve(effectField.items!);
  const getDecisionToImposeFine = (): Promise<any[]> =>
    Promise.resolve(decisionToImposeFineField.items!);
  const getCircumstances = (): Promise<any[]> =>
    Promise.resolve(circumstancesField.items!);
  const getSpilledSubstances = (): Promise<any[]> =>
    Promise.resolve(spilledSubstanceField.items!);

  return {
    getRootCauses,
    getImpacts,
    getProposedCorrectiveAction,
    getDecisionToImposeFine,
    getEffects,
    getCircumstances,
    getSpilledSubstances,
  };
}

function makeSkipNotVisibleFields(
  visibleFields: HashMap<HashMap<boolean>>
): (issue: IssueEntity, filter: SingleFilter) => boolean {
  return function skipNotVisibleFields(
    issue: IssueEntity,
    filter: SingleFilter
  ): boolean {
    const visibleFieldsOnProcess = visibleFields[issue.process];
    const fieldName = extractFieldName(filter.path);
    return !visibleFieldsOnProcess[fieldName];
  };
}

function extractFieldName(path: string): string {
  const [fieldTypeOrName, fieldName] = path.split('.');
  return fieldName || fieldTypeOrName;
}

async function createVisibleFieldsMap(): Promise<
  HashMap<HashMap<boolean>>
> {
  const visibleFields = await getAllCurrentVisibleFieldsPromise();

  const mappedByProcess = visibleFields.reduce(
    (result, visibleField: VisibleFieldModel) => {
      if (!result[visibleField.processId]) {
        result[visibleField.processId] = [];
      }
      result[visibleField.processId].push(visibleField);
      return result;
    },
    {} as HashMap<VisibleFieldModel[]>
  );

  return addNonFieldFilters(toVisibleFieldModelMap(mappedByProcess));
}

function addNonFieldFilters(
  visibleFieldsMap: HashMap<HashMap<boolean>>
): HashMap<HashMap<boolean>> {
  Object.keys(visibleFieldsMap).forEach((key) => {
    const fieldsOnProcess = visibleFieldsMap[key];
    fieldsOnProcess.process = true;
    fieldsOnProcess.inspection = true;
  });

  return visibleFieldsMap;
}
