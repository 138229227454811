import { IssueLocationType } from 'shared/domain/issue/issueModel';
import { IssueInDto } from 'shared/dtos/in/issue';
import { IssueEntityToSave } from './entity';

export function issueDtoToEntityToSave(
  issue: IssueInDto
): IssueEntityToSave {
  return {
    primaryData: {
      assignee: issue.primaryData.assignee,
      contractNumbers: issue.primaryData.contractNumbers || [],
      description: issue.primaryData.description,
      detectionDate: issue.primaryData.detectionDate,
      level: issue.primaryData.level,
      positionOnMap: issue.primaryData.positionOnMap,
      site: issue.primaryData.site,
      subcontractors: issue.primaryData.subcontractors || [],
      title: issue.primaryData.title,
      executor: issue.primaryData.executor,
      selectedLocationType:
        issue.primaryData.selectedLocationType || IssueLocationType.pin,
      targetAreas: issue.primaryData.targetAreas || [],
      finalAreas: issue.primaryData.finalAreas || [],
    },
    extendedData: {
      circumstances: issue.extendedData.circumstances,
      completedAmount: issue.extendedData.completedAmount,
      contaminatedSoilScale: issue.extendedData.contaminatedSoilScale,
      costCode: issue.extendedData.costCode,
      daysOfInabilityToWork: issue.extendedData.daysOfInabilityToWork,
      decisionToImposeFine: issue.extendedData.decisionToImposeFine,
      effect: issue.extendedData.effect,
      environmentalAspect: issue.extendedData.environmentalAspect,
      estimatedCost: issue.extendedData.estimatedCost,
      executedByCompanies: issue.extendedData.executedByCompanies,
      expectedFine: issue.extendedData.expectedFine,
      finalCompletionDate: issue.extendedData.finalCompletionDate,
      finalCost: issue.extendedData.finalCost,
      finalStartDate: issue.extendedData.finalStartDate,
      hazardCategory: issue.extendedData.hazardCategory,
      impact: issue.extendedData.impact,
      imposedFine: issue.extendedData.imposedFine,
      personUnableToWork: issue.extendedData.personUnableToWork,
      proposedCorrectiveAction:
        issue.extendedData.proposedCorrectiveAction,
      rootCauses: issue.extendedData.rootCauses,
      soilLeakageScale: issue.extendedData.soilLeakageScale,
      solutionMethod: issue.extendedData.solutionMethod,
      solutionProposal: issue.extendedData.solutionProposal,
      spilledSubstance: issue.extendedData.spilledSubstance,
      subcontractorRepresentative:
        issue.extendedData.subcontractorRepresentative,
      targetAmount: issue.extendedData.targetAmount,
      targetCompletionDate: issue.extendedData.targetCompletionDate,
      targetStartDate: issue.extendedData.targetStartDate,
      waterLeakageScale: issue.extendedData.waterLeakageScale,
      workTypes: issue.extendedData.workTypes,
      numberOfEmployees: issue.extendedData.numberOfEmployees,
    },
    deleted: issue.deleted ? 1 : 0,
    inspection: issue.inspection,
    protocolItem: issue.protocolItem,
    process: issue.process,
    stage: issue.stage,
    userAccesses: issue.userAccesses,
    events: issue.events,
    incomingEmailMessages: issue.incomingEmailMessages,
    _id: issue._id,
    createdAt: issue.createdAt,
    modifiedAt: issue.modifiedAt,
    createdBy: issue.createdBy,
    modifiedBy: issue.modifiedBy,
    mainImage: issue.mainImage,
    emailAddress: issue.emailAddress,
  };
}
