import { DocumentCreateModel } from 'shared/domain/document/documentCreateModel';
import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import { nowISO } from 'shared/utils/date/dates';
import { DocumentInsertEntity } from './entity';

export function documentCreateModelToDocumentInsertEntity(
  documentCreateModel: DocumentCreateModel
): DocumentInsertEntity {
  return {
    issueId: documentCreateModel.issueId,
    inspectionId: documentCreateModel.inspectionId,
    checklistId: documentCreateModel.checklistId,
    eventId: documentCreateModel.eventId,
    incomingEmailMessageId: documentCreateModel.incomingEmailMessageId,
    protocolId: documentCreateModel.protocolId,
    templateId: documentCreateModel.templateId,
    title: documentCreateModel.title,
    type: documentCreateModel.type,
    data: documentCreateModel.data,
    description: documentCreateModel.description,
    deleted: documentCreateModel.deleted,
    localData: documentCreateModel.localData,
    modifiedAt: nowISO(),
    syncStatus: SyncStatus.PENDING,
    drawingSyncStatus: SyncStatus.PENDING,
  };
}
