import MIMETypes from 'mime-types';
import { HashMap } from 'shared/types/commonView';

export const selectIcon = (
  fileType: string,
  fileExtension?: string,
  fileIcons?: { blank: string; [key: string]: string }
): string | undefined => {
  if (!fileIcons) {
    return;
  }

  let extension: string | false =
    fileExtension || MIMETypes.extension(fileType);

  // TODO MimeTypes does not handle this legacy image format.
  if (!extension && fileType === 'image/pjpeg') {
    extension = 'jpeg';
  }
  if (extension !== false) {
    const iconFallbacks: HashMap<string> = {
      xlsx: 'xls',
      docx: 'doc',
      jpeg: 'jpg',
    };

    return (
      fileIcons[extension] ||
      fileIcons[iconFallbacks[extension]] ||
      fileIcons.blank
    );
  }

  return fileIcons.blank;
};
