import { formatMoney } from 'components/common/forms/fields/EstimatedCosts/helpers';
import { IssueOnSingleView } from 'components/dataProviders/withIssue/IssueOnSingleView';
import { VisibleFieldModel } from 'shared/domain/visibleField/visibleFieldModel';
import { isNullish } from 'helpers/misc';
import React, { PropsWithChildren } from 'react';
import { getAvailableFields } from 'shared/domain/visibleField/availableFields';
import { ColumnWidths } from 'setup/types/core';
import {
  FieldSkeleton,
  InputType,
  IssueForm,
  ProcessType,
} from 'shared/types/form';
import { TableColumnProp } from 'components/table/types';
import { FieldTypes } from 'shared/domain/issueForm/types/fieldTypes';
import { NOT_APPLICABLE_FIELD_INDICATOR } from 'shared/contants';
import { HashMap, Labelled } from 'shared/types/commonView';
import {
  createAccessor,
  createCostFieldAccessor,
  createDataGetter,
  formatters,
  getColumnIndex,
  getColumnMinWidth,
  getColumnSortType,
  getInitialWidth,
  getProcessesWithField,
  isCostField,
  shouldRenderNotApplicable,
} from '../tableView/helpers';
import { IssueData } from '../tableView/types';
import { ISO6391LanguageCode } from 'shared/types/locale';

export const NotApplicableCell = (): React.ReactElement => (
  <span className='notapplicable'>{NOT_APPLICABLE_FIELD_INDICATOR}</span>
);

const costValueCellStyles = {
  textAlign: 'right' as 'right',
  width: '100%',
};

export const CostValueCell = (
  props: PropsWithChildren<{}>
): React.ReactElement => {
  return <span style={costValueCellStyles}>{props.children}</span>;
};

export const makeColumns = (
  type: FieldTypes,
  fields: FieldSkeleton[],
  columnWidths: ColumnWidths | undefined,
  processes: ProcessType[],
  issueForm: IssueForm,
  timezone: string,
  visibleFields: HashMap<VisibleFieldModel[]>,
  locale: ISO6391LanguageCode
): TableColumnProp<IssueOnSingleView>[] => {
  const availableFieldsMap = getAvailableFields().reduce(
    (result, field) => {
      result[field.fieldName] = field;
      return result;
    },
    {}
  );
  return fields.map((field) => {
    const initialWidth = getInitialWidth(field);
    const minWidth = getColumnMinWidth(field);

    const processIds = getProcessesWithField(
      field.name,
      processes,
      visibleFields,
      availableFieldsMap
    );

    const memoizedWidth = columnWidths
      ? columnWidths[field.name]
      : initialWidth;

    const isFieldSortable = field.inputType !== InputType.multiSelect;

    return {
      _id: field.name,
      key: field.name,
      index: getColumnIndex(field.name),
      dataKey: field.name,
      label: field.label,
      inputType: field.inputType,
      expectedDataType: field.expectedDataType,
      sortable: isFieldSortable,
      resizable: true,
      processIds,
      minWidth,
      width: memoizedWidth ?? Math.max(initialWidth, minWidth),
      dataType: type,
      accessor: (issue) => createAccessor(issue[type], field),
      dataGetter: ({ rowData }) => createDataGetter(rowData[type], field),
      ...getColumnSortType(field),
      cellRenderer: (props): React.ReactElement | null => {
        const data = props.rowData[type];
        const costValue =
          isCostField(field.inputType) &&
          createCostFieldAccessor(formatMoney)(
            props.rowData.extendedData,
            field
          );
        const columnData: unknown =
          data[field.name as keyof IssueData] ?? null;

        if (
          shouldRenderNotApplicable(
            props.rowData,
            props.columns,
            field.name
          )
        ) {
          return <NotApplicableCell />;
        }

        if (costValue) {
          return <CostValueCell>{costValue}</CostValueCell>;
        }

        if (isNullish(columnData)) {
          return null;
        }

        if (Array.isArray(columnData)) {
          const trimmedLabel =
            columnData
              .map((a) => a.label)
              .join(', ')
              .slice(0, 50) ?? null;

          return <span>{trimmedLabel}</span>;
        }

        const formatter = formatters[field.inputType];
        const formattedValue =
          (formatter && formatter(props.cellData, timezone, locale)) ??
          props.cellData;

        return (
          <span>
            {formattedValue ??
              (columnData as Labelled)?.label?.slice(0, 50) ??
              null}
          </span>
        );
      },
    } as TableColumnProp<IssueOnSingleView>;
  });
};
