import { InspectionEntity } from 'serviceWorker/repository/inspection/entity';
import {
  ApexAxisChartSeries,
  DateRangeIntervalWithoutWeek,
  InspectionEvolutionFilters,
} from 'shared/types/analytics';
import {
  filterByCreatedBy,
  filterByDateRange,
  filterByProcess,
  filterInspectionBySite,
} from '../seriesCreation';
import { DateTime } from 'luxon';
import { ComplianceCheckResult } from 'shared/domain/inspection/inspectionModel';
import { DayMomentValidation } from 'shared/types/time';
import {
  DateFormatFromUnit,
  isoToProjectTimezonedDate,
} from 'shared/utils/date/dates';

export async function createSeriesAndLabelsFromInspectionsWithFilters(
  inspections: InspectionEntity[],
  templateProcessByIdRecord: Record<string, { process: string }>,
  filters: InspectionEvolutionFilters,
  timezone: string
): Promise<ApexAxisChartSeries> {
  const [dateInterval, dateRangeFilter] = filters.dataScope.filters;

  const amountGrouped = createDateRangeSplitByUnit(
    dateRangeFilter.value.from,
    dateRangeFilter.value.to,
    dateInterval.value,
    timezone
  );
  const averageInspectionResultGrouped = createDateRangeSplitByUnit(
    dateRangeFilter.value.from,
    dateRangeFilter.value.to,
    dateInterval.value,
    timezone
  );

  const validInspections = inspections.filter((inspection) => {
    return isInspectionValid(
      inspection,
      filters,
      timezone,
      templateProcessByIdRecord
    );
  });

  validInspections.forEach((inspection) => {
    const group = createGroup(inspection, dateInterval.value);

    addToGroup(
      amountGrouped,
      averageInspectionResultGrouped,
      group,
      inspection
    );
  });

  const sortedDates = Object.keys(amountGrouped).sort();
  const sortedAmount = sortedDates.map(
    (groupValue) => amountGrouped[groupValue]
  );

  const sortedAverage = sortedDates.map((groupValue) => {
    const passed =
      averageInspectionResultGrouped[groupValue]?.[
        ComplianceCheckResult.passed
      ] || 0;
    const notApplicable =
      averageInspectionResultGrouped[groupValue]?.[
        ComplianceCheckResult.notApplicable
      ] || 0;
    const result = (passed / (passed + notApplicable || 1)) * 100;
    return Math.round((result + Number.EPSILON) * 100) / 100;
  });

  return [
    {
      name: 'Completed',
      type: 'column',
      data: sortedAmount,
      labels: sortedDates,
    },
    {
      name: 'Score',
      type: 'line',
      data: sortedAverage,
    },
  ];
}

type AverageInspectionResult = {
  [ComplianceCheckResult.passed]: number;
  [ComplianceCheckResult.notApplicable]: number;
};
function addToGroup(
  amountGrouped: Record<string, number>,
  averageInspectionResultGrouped: {
    [key: string]: AverageInspectionResult | 0;
  },
  group: string,
  inspection: InspectionEntity
): void {
  if (!amountGrouped[group]) {
    amountGrouped[group] = 0;
  }
  amountGrouped[group] += 1;

  if (!averageInspectionResultGrouped[group]) {
    averageInspectionResultGrouped[group] = {
      [ComplianceCheckResult.passed]: 0,
      [ComplianceCheckResult.notApplicable]: 0,
    };
  }

  inspection.protocol.forEach((protocolElement) => {
    if (
      protocolElement.complianceCheck.result ===
        ComplianceCheckResult.unset ||
      protocolElement.complianceCheck.result ===
        ComplianceCheckResult.notPassed
    ) {
      return;
    }
    averageInspectionResultGrouped[group]![
      protocolElement.complianceCheck.result
    ] += 1;
  });
}

function createGroup(
  inspection: InspectionEntity,
  unit: DateRangeIntervalWithoutWeek
): string {
  return DateTime.fromISO(inspection.createdAt).toFormat(
    DateFormatFromUnit[unit]
  );
}

function createDateRangeSplitByUnit(
  startDate: string,
  endDate: string,
  unit: DateRangeIntervalWithoutWeek,
  projectTimezone: string
): { [key: string]: 0 } {
  const dateRange = {};

  let dateStart = isoToProjectTimezonedDate(
    startDate,
    projectTimezone,
    DayMomentValidation.start
  );
  const dateEnd = isoToProjectTimezonedDate(
    endDate,
    projectTimezone,
    DayMomentValidation.end
  );
  let isFirstIteration = true;
  let dateFormattedByUnit;
  while (dateEnd.diff(dateStart, unit).get(unit) >= 0) {
    dateFormattedByUnit = dateStart.toFormat(DateFormatFromUnit[unit]);
    dateRange[dateFormattedByUnit] = 0;
    dateStart = dateStart.plus({ [`${unit}`]: 1 });
    if (isFirstIteration === true) {
      dateStart = dateStart.startOf(unit);
    }
    isFirstIteration = false;
  }
  return dateRange;
}

function isInspectionValid(
  inspection: InspectionEntity,
  filters: InspectionEvolutionFilters,
  timezone: string,
  templateProcessByIdRecord: Record<string, { process: string }>
): boolean {
  const [_, dateRangeFilter, processFilter, siteFilter, createdByFilter] =
    filters.dataScope.filters;
  if (!inspection.isCompleted) return false;
  if (!templateProcessByIdRecord[inspection.template]) {
    throw new Error(
      `Template _id:${inspection.template}; missing for inspection _id:${inspection._id}`
    );
  }

  return (
    filterByDateRange(
      dateRangeFilter.value,
      inspection.inspectionDate || inspection.createdAt,
      timezone
    ) &&
    filterByProcess(
      processFilter.value,
      templateProcessByIdRecord[inspection.template]
    ) &&
    filterInspectionBySite(siteFilter.value, inspection) &&
    filterByCreatedBy(createdByFilter.value, inspection)
  );
}
