import { AxiosInstance, AxiosPromise } from 'axios';
import { apiUrl } from 'shared/apiUrl';
import { EventDescriptionPartModel } from 'shared/domain/issue/issueModel';
import {
  FailableUserDto,
  UserInDto,
  UserWithPermissionsInDto,
} from 'shared/domain/user/types/inDto';
import {
  IncomingEmailMessageContentInDto,
  IssueEventInDto,
  IssueInDto,
} from 'shared/dtos/in/issue';
import {
  ChecklistItemInDto,
  InspectionTemplateInDto,
} from 'shared/dtos/in/template';
import {
  ChecklistItemOutDto,
  CreateChecklistItemsOutDto,
  InspectionTemplateOutDto,
} from 'shared/dtos/out/template';
import { ProcessType } from 'shared/types/form';
import { apiInstance } from './redux/actions';
import {
  IconsProp,
  Issue,
  IssueDocument,
  IssuesInfo,
  SuperadminUserUpdateDto,
  UserPersonalDataUpdateDto,
  UserUpdateDto,
} from './setup/types/core';
import { Operation } from './views/issue/issueHistory/types';

export class API {
  axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = apiInstance;
  }

  fetchMe(): AxiosPromise<UserWithPermissionsInDto> {
    return this.axiosInstance(`${apiUrl}/v2/user/me`);
  }

  fetchUsers(projectId: string): AxiosPromise<UserInDto[]> {
    return this.axiosInstance(`${apiUrl}/v2/project/${projectId}/user`);
  }

  fetchProcesses(projectId: string): Promise<ProcessType[]> {
    return this.axiosInstance(
      `${apiUrl}/v2/project/${projectId}/process`
    ).then((response) => response.data);
  }

  fetchIcons(): AxiosPromise<IconsProp['icons']> {
    return this.axiosInstance(`${apiUrl}/icons`);
  }

  fetchUpdatedIssues(
    projectId: string,
    syncKey: string
  ): AxiosPromise<IssuesInfo> {
    return this.axiosInstance(
      `${
        apiUrl
      }/v2/project/${projectId}/issue?syncKey=${encodeURIComponent(
        syncKey
      )}`
    );
  }

  fetchIssuesWithOffset(params: {
    projectId: string;
    offset: number;
    size?: number;
    deleted: boolean;
    sort: string;
    direction: string;
  }): AxiosPromise<IssuesInfo> {
    return this.axiosInstance(
      `${apiUrl}/v2/project/${params.projectId}/issue?offset=${params.offset}&size=${params.size}&deleted=[${params.deleted}]&sort=${params.sort}:${params.direction}`
    );
  }

  removeIssue(issueId: string): Promise<Issue> {
    return this.axiosInstance.delete(`/issue/${issueId}`);
  }

  restoreIssue(issueId: string): AxiosPromise<IssueInDto> {
    return this.axiosInstance.get(`/issue/${issueId}/restore`);
  }

  fetchIssueChangeLog(issueId: string): Promise<Operation[]> {
    return this.axiosInstance(`/issue/${issueId}/changeLog`).then(
      (response) => response.data
    );
  }

  fetchIncomingEmailMessageContent(
    issueId: string,
    incomingEmailMessageContentId: string
  ): Promise<IncomingEmailMessageContentInDto> {
    return this.axiosInstance(
      `/issue/${issueId}/incomingEmailMessageContent/${incomingEmailMessageContentId}`
    ).then((response) => response.data);
  }

  updateDocument(
    issueId: string,
    eventId: string | undefined,
    documentId: string,
    body: any
  ): AxiosPromise<{
    document: IssueDocument;
    signedRequest: any;
  }> {
    const withEvent = eventId ? `event/${eventId}/` : '';

    return this.axiosInstance.put(
      `issue/${issueId}/${withEvent}document/${documentId}`,
      body
    );
  }

  createEvent(
    issueId: string,
    title: string,
    description: EventDescriptionPartModel[]
  ): AxiosPromise<IssueEventInDto> {
    return this.axiosInstance.post(`/issue/${issueId}/event`, {
      title: title,
      description: description,
    });
  }

  updateEvent(
    issueId: string,
    eventId: string,
    body: any
  ): AxiosPromise<IssueEventInDto> {
    return this.axiosInstance.put(
      `/issue/${issueId}/event/${eventId}`,
      body
    );
  }

  deleteEvent(issueId: string, eventId: string): AxiosPromise<void> {
    return this.axiosInstance.delete(`/issue/${issueId}/event/${eventId}`);
  }

  updateUser(body: UserUpdateDto): AxiosPromise<FailableUserDto> {
    return this.axiosInstance.put(`${apiUrl}/v2/user/me`, body);
  }

  updateUserPersonalData(
    body: UserPersonalDataUpdateDto,
    userId: string,
    organizationId: string
  ): AxiosPromise<FailableUserDto> {
    return this.axiosInstance.put(
      `${apiUrl}/v2/user/${userId}/personalData/client/${organizationId}`,
      body
    );
  }

  updateSuperadminUser(
    body: SuperadminUserUpdateDto
  ): AxiosPromise<FailableUserDto> {
    return this.axiosInstance.put(
      `${apiUrl}/v2/user/me/superadminLabel`,
      body
    );
  }

  createTemplate(
    body: InspectionTemplateOutDto
  ): AxiosPromise<InspectionTemplateInDto> {
    return this.axiosInstance.post('/inspectionTemplate', body);
  }

  fetchTemplate(
    templateId: string
  ): AxiosPromise<InspectionTemplateInDto> {
    return this.axiosInstance.get(`/inspectionTemplate/${templateId}`);
  }

  updateTemplate(
    templateId: string,
    body: Partial<InspectionTemplateOutDto>
  ): AxiosPromise<InspectionTemplateInDto> {
    return this.axiosInstance.put(
      `/inspectionTemplate/${templateId}/`,
      body
    );
  }

  removeTemplate(templateId: string): AxiosPromise<{
    templates: InspectionTemplateInDto[];
  }> {
    return this.axiosInstance.delete(`/inspectionTemplate/${templateId}`);
  }

  createChecklistItems(
    templateId: string,
    body: CreateChecklistItemsOutDto
  ): AxiosPromise<ChecklistItemInDto[]> {
    return this.axiosInstance.post(
      `/inspectionTemplate/${templateId}/checklistItem`,
      body
    );
  }

  updateChecklistItem(
    templateId: string,
    checklistItemId: string,
    body: ChecklistItemOutDto
  ): AxiosPromise<ChecklistItemInDto> {
    return this.axiosInstance.put(
      `/inspectionTemplate/${templateId}/checklistItem/${checklistItemId}`,
      body
    );
  }

  deleteChecklistItem(
    templateId: string,
    checklistItemId: string
  ): AxiosPromise<ChecklistItemInDto> {
    return this.axiosInstance.delete(
      `/inspectionTemplate/${templateId}/checklistItem/${checklistItemId}`
    );
  }

  rawGetFromUrl(url: string): AxiosPromise<any> {
    return this.axiosInstance.get(url);
  }
}
