import { ReactElement } from 'react';
import { TripletSeries } from 'shared/types/analytics';
import { ApexOptions } from 'apexcharts';
import { VerticalTripletChart } from './tripletParts/VerticalTripletChart';
import { HorizontalTripletChart } from './tripletParts/HorizontalTripletChart';
import useShouldShowVertical from './tripletParts/shouldShowVertical';

export function TripletChart({
  series,
  options,
}: {
  series: TripletSeries;
  options: ApexOptions;
}): ReactElement {
  const smallMobile = useShouldShowVertical();

  return smallMobile ? (
    <VerticalTripletChart series={series} options={options} />
  ) : (
    <HorizontalTripletChart series={series} options={options} />
  );
}
