import { withCorrectiveActionTypesChannelListener } from 'components/broadcastChannelListeners/channelListener/correctiveActionTypesChannelListener';
import { withEnvironmentalAspectChannelListener } from 'components/broadcastChannelListeners/channelListener/environmentalAspectChannelListener';
import { withHazardCategoryChannelListener } from 'components/broadcastChannelListeners/channelListener/hazardCategoryChannelListener';
import { withWorktypeChannelListener } from 'components/broadcastChannelListeners/channelListener/worktypeChannelListener';
import { withCompanyChannelListener } from 'components/broadcastChannelListeners/withCompanyChannelListener';
import { withContractChannelListener } from 'components/broadcastChannelListeners/withContractChannelListener';
import { withIssueFormChannelListener } from 'components/broadcastChannelListeners/withIssueFormChannelListener';
import { withLevelChannelListener } from 'components/broadcastChannelListeners/withLevelChannelListener';
import { withOrganizationChannelListener } from 'components/broadcastChannelListeners/withOrganizationChannelListener';
import { withSiteChannelListener } from 'components/broadcastChannelListeners/withSiteChannelListener';
import { withUserChannelListener } from 'components/broadcastChannelListeners/withUserChannelListener';
import { withVisibleFieldsChannelListener } from 'components/broadcastChannelListeners/withVisibleFieldsChannelListener';
import { withFetcherReady } from 'components/common/withFetcherReady';
import { withIssueForm } from 'components/common/withIssueForm';
import { withProcessesData } from 'components/common/withProcessesData';
import { withSelectProject } from 'components/common/withSelectProject';
import { Spinner } from 'components/core';
import {
  useCompanies,
  withCompanies,
} from 'components/dataProviders/withCompanies';
import { withLevels } from 'components/dataProviders/withLevels';
import { withOrganizations } from 'components/dataProviders/withOrganizations';
import { withProjects } from 'components/dataProviders/withProjects';
import { withSites } from 'components/dataProviders/withSites';
import { withUsers } from 'components/dataProviders/withUsers';
import { withFieldVisibility } from 'components/dataProviders/withVisibleFields';
import { withWorktypes } from 'components/dataProviders/withWorktypes';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MemoAnalytics } from 'views/analytics';
import { ChartsProvider } from 'views/analytics/ChartsProvider';
import { ChartDetailsProvider } from 'views/analytics/chartDetails/ChartDetailsProvider';
import { AnalyticsPrivateRoute } from '../PrivateRoute';
import { withInspectionTemplates } from 'components/dataProviders/withInspectionTemplates';

const AnalyticsRoute = ({
  match,
}: RouteComponentProps<{ id: string }>): React.ReactElement => {
  const { loading } = useCompanies();

  if (loading) {
    return <Spinner reason='AnalyticsRoute loading' />;
  }

  return (
    <ChartDetailsProvider isDetailsView={Boolean(match.params.id)}>
      <ChartsProvider>
        <AnalyticsPrivateRoute
          component={(): React.ReactElement => (
            <MemoAnalytics id={match.params.id} />
          )}
        />
      </ChartsProvider>
    </ChartDetailsProvider>
  );
};
export const MemoAnalyticsRoute = React.memo(
  withSelectProject(
    withFetcherReady(
      withProcessesData(
        withOrganizationChannelListener(
          withUserChannelListener(
            withSiteChannelListener(
              withLevelChannelListener(
                withIssueFormChannelListener(
                  withCompanyChannelListener(
                    withContractChannelListener(
                      withWorktypeChannelListener(
                        withHazardCategoryChannelListener(
                          withEnvironmentalAspectChannelListener(
                            withCorrectiveActionTypesChannelListener(
                              withVisibleFieldsChannelListener(
                                withOrganizations(
                                  withProjects(
                                    withUsers(
                                      withLevels(
                                        withSites(
                                          withIssueForm(
                                            withCompanies(
                                              withWorktypes(
                                                withFieldVisibility(
                                                  withInspectionTemplates(
                                                    AnalyticsRoute
                                                  )
                                                )
                                              )
                                            )
                                          )
                                        )
                                      )
                                    )
                                  )
                                )
                              )
                            )
                          )
                        )
                      )
                    )
                  )
                )
              )
            )
          )
        )
      )
    )
  )
);
