import { IssueEntity } from 'serviceWorker/repository/issue/entity';
import {
  IssueChart,
  FilterValues,
  InspectionChart,
  ApexSeriesAndLabels,
  ApexAxisChartSeries,
  TripletSeriesWithLabels,
} from 'shared/types/analytics';
import { createSeriesAndLabelsFromIssuesWithFilters as environmentalAspectCalculation } from './environmentalAspect';
import { createSeriesFromIssuesWithFilters as hsEffectCalculation } from './hsEffect';
import { createSeriesAndLabelsFromIssuesWithFilters as hsHazardTypeCalculation } from './hsHazardType';
import { createSeriesAndLabelsFromIssuesWithFilters as impactCalculation } from './impact';
import { createSeriesAndLabelsFromIssuesWithFilters as originatorCalculation } from './originator';
import { createSeriesAndLabelsFromIssuesWithFilters as rootCauseCalculation } from './rootCause';
import { createSeriesAndLabelsFromIssuesWithFilters as workTypeCalculation } from './workType';
import { createSeriesAndLabelsFromInspectionsWithFilters as completedInspectionsByTemplate } from './completedInspectionsByTemplate';
import { createSeriesAndLabelsFromInspectionsWithFilters as inspectionEvolution } from './inspectionEvolution';
import { createSeriesAndLabelsFromInspectionsWithFilters as inspectionTriplet } from './inspectionTriplet';
import { InspectionEntity } from 'serviceWorker/repository/inspection/entity';
import { InspectionTemplateEntity } from 'serviceWorker/repository/inspectionTemplate/entity';

const chartCalculation = {
  [IssueChart.originator]: originatorCalculation,
  [IssueChart.rootCause]: rootCauseCalculation,
  [IssueChart.impact]: impactCalculation,
  [IssueChart.workType]: workTypeCalculation,
  [IssueChart.environmentalAspect]: environmentalAspectCalculation,
  [IssueChart.hsEffect]: hsEffectCalculation,
  [IssueChart.hsHazardType]: hsHazardTypeCalculation,
};

export function calculate(
  chart: IssueChart,
  issues: IssueEntity[],
  filters: FilterValues,
  timezone: string,
  additionalParams: any | undefined
): Promise<any> {
  return chartCalculation[chart](
    issues,
    // @ts-ignore in calculation we only care filters are of type FilterValues. I don't know yet how to handle this
    filters,
    timezone,
    additionalParams
  );
}

const inspectionChartCalculation: Calculations = {
  [InspectionChart.completedInspectionsByTemplate]:
    completedInspectionsByTemplate,
  [InspectionChart.inspectionEvolution]: inspectionEvolution,
  [InspectionChart.inspectionTriplet]: inspectionTriplet,
};

type Calculations = {
  [key in InspectionChart]: CallableFunction;
};

export function calculateInspections(
  chart: InspectionChart,
  inspections: InspectionEntity[],
  templateProcessByIdRecord: Record<string, { process: string }>,
  filters: FilterValues,
  timezone: string,
  additionalParams: { templates: InspectionTemplateEntity[] } | undefined
): Promise<
  ApexAxisChartSeries | ApexSeriesAndLabels | TripletSeriesWithLabels
> {
  return inspectionChartCalculation[chart](
    inspections,
    templateProcessByIdRecord,
    // @ts-ignore in calculation we only care filters are of type FilterValues. I don't know yet how to handle this
    filters,
    timezone,
    additionalParams
  );
}
